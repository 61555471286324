import React from "react";
import Container from "@mui/material/Container";
import Card from "@mui/material/Card";
import Fab from "@mui/material/Fab";
import ShareIcon from "@mui/icons-material/Share";

const TestView6 = () => {
  const cards = Array.from({ length: 9 }).map((_, index) => (
    <Card
      key={index}
      style={{
        width: "100px",
        height: "100px",
        // borderRadius: 24,
        position: "relative",
        // margin: "10px",
        transition: "transform 0.5s",
      }}
    >
      <Fab
        variant="contained"
        color="primary"
        size="large"
        style={{
          position: "absolute",
          bottom: 8,
          right: 8,
        }}
      >
        <ShareIcon />
      </Fab>
    </Card>
  ));

  return (
    <Container
      style={{ display: "grid", gridTemplateColumns: "repeat(3, 1fr)" }}
    >
      {cards}
    </Container>
  );
};

export default TestView6;
