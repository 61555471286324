import {
  Dialog,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  Switch,
} from "@mui/material";

const FlowSettingsDialog = ({ isOpen, handleClose, flow }) => {
  const flowSettingFormLabels = [
    {
      name: "Allow Anyone to view results",
      value: "allowAnyoneToView",
      default: false,
    },
    {
      name: "Allow Other to Copy",
      value: "allowOtherToCopy",
      default: true,
    },
    {
      name: "Only Logined Users can responsed",
      value: "onlyLoginedUsersCanResponse",
      default: false,
    },
    {
      name: "Show my Profile to Participants",
      value: "showMyProfileToParticipants",
      default: true,
    },
  ];
  return (
    <Dialog
      sx={{
        "& .MuiDialog-paper": {
          width: "90%",
          maxHeight: 500,
          borderRadius: "24px",
          padding: "16px",
        },
      }}
      maxWidth="xs"
      open={isOpen}
    >
      <DialogTitle>Settings</DialogTitle>
      <DialogContent>
        {/* Toggle to set flow to active or inactive */}
        <FormGroup>
          {/* for each flowSettingFormLabels create */}
          {flowSettingFormLabels.map((flowSettingFormLabel) => (
            <FormControlLabel
              key={flowSettingFormLabel.value}
              value={flowSettingFormLabel.value}
              defaultValue={flowSettingFormLabel.default}
              control={<Switch color="primary" />}
              label={flowSettingFormLabel.name}
              labelPlacement="end"
              defaultChecked={flowSettingFormLabel.default}
            />
          ))}
        </FormGroup>
      </DialogContent>
    </Dialog>
  );
};

export default FlowSettingsDialog;
