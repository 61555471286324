import { useState } from "react";
import { API_URL } from "../../Config";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Card,
  CardContent,
  Container,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
function BlueprintCreateView() {
  const navigate = useNavigate();

  const [flow, setFlow] = useState({});
  const handleInput = (event) => {
    const { target } = event;
    const { id, value } = target;
    setFlow({ description: value });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    fetch(API_URL + "/flows", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("vqa_token"),
      },
      body: JSON.stringify({ flow: flow }),
    }).then((response) => {
      if (response.status === 200) {
        return response.json().then((data) => {
          let flow = { id: data.id, description: data.description };

          navigate("/blueprints/" + flow.id + "/edit");
        });
      }
      return response.json().then((error) => {
        console.error("Error creating flow:", error);
        // throw new Error(error.message);
      });
    });
  };

  return (
    <Card>
      <CardContent>
        <Container component="main" maxWidth="xs">
          <Typography component="h1" variant="h5">
            Create a Blueprint for a Flow
          </Typography>
          <form>
            <div>
              <Typography variant="body1">Name you Flow</Typography>
            </div>
            <TextField
              variant="outlined"
              fullWidth
              id="description"
              onChange={handleInput}
            />
            <Button
              type="submit"
              variant="contained"
              color="primary"
              onClick={handleSubmit}
              fullWidth
              style={{ marginTop: "1rem" }}
              size="large"
            >
              Start Creating the flow
            </Button>
          </form>
        </Container>
      </CardContent>
    </Card>
  );
}
export default BlueprintCreateView;
