import {
  Alert,
  Box,
  Card,
  IconButton,
  Modal,
  Snackbar,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import React, { useState, useRef, useEffect } from "react";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
import ReactCrop, {
  centerCrop,
  makeAspectCrop,
  convertToPixelCrop,
} from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import { Container, Slider } from "@mui/material";
import styled from "@emotion/styled";
import { API_URL } from "../Config";
import { resolvePath } from "react-router-dom";
import { COLORS_PALETTE } from "../Utils/static";
import { set } from "lodash";
import { Button } from "Components/CustomHTMLComponent";
const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});
export function useDebounceEffect(fn, waitTime, deps) {
  useEffect(() => {
    const t = setTimeout(() => {
      fn.apply(undefined, deps);
    }, waitTime);

    return () => {
      clearTimeout(t);
    };
  }, deps);
}

function centerAspectCrop(mediaWidth, mediaHeight, aspect) {
  return centerCrop(
    makeAspectCrop(
      {
        unit: "%",
        width: 90,
      },
      aspect,
      mediaWidth,
      mediaHeight
    ),
    mediaWidth,
    mediaHeight
  );
}

export async function canvasPreview(
  image,
  canvas,
  crop,
  scale = 1,
  rotate = 0
) {
  const ctx = canvas.getContext("2d");

  if (!ctx) {
    throw new Error("No 2d context");
  }

  const scaleX = image.naturalWidth / image.width;
  const scaleY = image.naturalHeight / image.height;
  const pixelRatio = window.devicePixelRatio;

  canvas.width = Math.floor(crop.width * scaleX * pixelRatio);
  canvas.height = Math.floor(crop.height * scaleY * pixelRatio);

  ctx.scale(pixelRatio, pixelRatio);
  ctx.imageSmoothingQuality = "high";

  const cropX = crop.x * scaleX;
  const cropY = crop.y * scaleY;

  const centerX = image.naturalWidth / 2;
  const centerY = image.naturalHeight / 2;

  ctx.save();

  ctx.translate(-cropX, -cropY);
  ctx.translate(centerX, centerY);
  ctx.scale(scale, scale);
  ctx.translate(-centerX, -centerY);
  ctx.drawImage(
    image,
    0,
    0,
    image.naturalWidth,
    image.naturalHeight,
    0,
    0,
    image.naturalWidth,
    image.naturalHeight
  );

  ctx.restore();
}
const UploadPhotoDialog = ({ isOpen, handleClose, imageToUpload }) => {
  const [imgSrc, setImgSrc] = useState("");
  const previewCanvasRef = useRef(null);
  const imgRef = useRef(null);
  const hiddenAnchorRef = useRef(null);
  const blobUrlRef = useRef("");
  const [crop, setCrop] = useState(centerAspectCrop(100, 100, 1 / 1));
  const [completedCrop, setCompletedCrop] = useState();
  const [scale, setScale] = useState(1);
  const [aspect, setAspect] = useState(1 / 1);
  const [showSnackBar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  // when the component become visable. click the hidden anchor to open the file dialog:
  useEffect(() => {
    if (isOpen) {
      setImgSrc(imageToUpload);
      setCrop(undefined);
    }
  }, [isOpen, imageToUpload]);
  // function onSelectFile(e) {
  //   console.log("onSelectFile");
  //   if (e.target.files && e.target.files.length > 0) {
  //     setCrop(undefined);
  //     const reader = new FileReader();
  //     reader.addEventListener("load", () => setImgSrc(reader.result || ""));
  //     reader.readAsDataURL(e.target.files[0]);
  //   }
  // }

  function onImageLoad(e) {
    if (aspect) {
      const { width, height } = e.currentTarget;
      setCrop(centerAspectCrop(width, height, aspect));
    }
  }

  function dataURItoBlob(dataURI) {
    const byteString = atob(dataURI.split(",")[1]);
    const mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: mimeString });
  }
  function uploadClickHandler() {
    if (!previewCanvasRef.current) {
      throw new Error("Crop canvas does not exist");
    }
    // if height and width less than 400px, alert user and return
    if (
      previewCanvasRef.current.width < 400 ||
      previewCanvasRef.current.height < 400
    ) {
      setShowSnackbar(true);
      // calculate how much increase til you reach at least 400px in percentage:
      const increaseHeight = (400 / previewCanvasRef.current.height) * 100;

      setSnackbarMessage(
        "Image too small, increase the size by at least " +
          increaseHeight.toFixed(0) +
          "% "
      );

      return;
    }

    // convert the canvas to base64 like this (data:image/jpeg;base64,/9j/4AAQSkZJ...
    const base64Image = previewCanvasRef.current.toDataURL("image/jpeg");
    // change the image size to 400x400 :
    // create an off-screen canvas
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    // set its dimension to target size
    canvas.width = 400;
    canvas.height = 400;
    // draw source image into the off-screen canvas:
    ctx.drawImage(previewCanvasRef.current, 0, 0, 400, 400);
    // encode image to data-uri with base64 version of compressed image( now no compression as it set to 1.0)
    const newBase64Image = canvas.toDataURL("image/jpeg", 1.0);

    // upload to server:
    // convert blob to file and append to form data:
    previewCanvasRef.current.toDataURL("image/png");
    const formData = new FormData();
    formData.append("image", dataURItoBlob(newBase64Image), "image.jpg");
    formData.append("question", "What is this?");
    fetch(API_URL + "/questions/image", {
      method: "POST",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("vqa_token"),
      },
      body: formData,
    })
      .then((res) => {
        console.log(res);
      })
      .catch((error) => {
        console.error(error);
      });
  }
  function uploadClickHandlerV2() {
    if (!previewCanvasRef.current) {
      throw new Error("Crop canvas does not exist");
    }
    // if height and width less than 400px, alert user and return
    if (
      previewCanvasRef.current.width < 400 ||
      previewCanvasRef.current.height < 400
    ) {
      setShowSnackbar(true);
      // calculate how much increase til you reach at least 400px in percentage:
      const increaseHeight = (400 / previewCanvasRef.current.height) * 100;

      setSnackbarMessage(
        "Image too small, increase the size by at least " +
          increaseHeight.toFixed(0) +
          "% "
      );

      return;
    }

    // convert the canvas to base64 like this (data:image/jpeg;base64,/9j/4AAQSkZJ...
    const base64Image = previewCanvasRef.current.toDataURL("image/jpeg");
    // change the image size to 400x400 :
    // create an off-screen canvas
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    // set its dimension to target size
    canvas.width = 400;
    canvas.height = 400;
    // draw source image into the off-screen canvas:
    ctx.drawImage(previewCanvasRef.current, 0, 0, 400, 400);
    // encode image to data-uri with base64 version of compressed image( now no compression as it set to 1.0)
    const newBase64Image = canvas.toDataURL("image/jpeg", 1.0);

    // upload to server:
    // convert blob to file and append to form data:
    // previewCanvasRef.current.toDataURL("image/png");
    // const formData = new FormData();
    // formData.append("image", dataURItoBlob(newBase64Image), "image.jpg");
    // formData.append("question", "What is this?");
    handleClose({ image: dataURItoBlob(newBase64Image) });
  }
  useDebounceEffect(
    async () => {
      if (
        completedCrop?.width &&
        completedCrop?.height &&
        imgRef.current &&
        previewCanvasRef.current
      ) {
        canvasPreview(
          imgRef.current,
          previewCanvasRef.current,
          completedCrop,
          scale
        );
      }
    },
    100,
    [completedCrop, scale]
  );

  function handleToggleAspectClick() {
    if (aspect) {
      setAspect(undefined);
    } else {
      setAspect(1 / 1);

      if (imgRef.current) {
        const { width, height } = imgRef.current;
        const newCrop = centerAspectCrop(width, height, 1 / 1);
        setCrop(newCrop);
        setCompletedCrop(convertToPixelCrop(newCrop, width, height));
      }
    }
  }

  return (
    <Modal
      open={isOpen}
      onClose={() => {
        handleClose(null);
      }}
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        overflowY: "scroll",
      }}
    >
      <Box sx={{ width: 400, p: 2 }}>
        <Card style={{ maxHeight: "100vh" }}>
          {!!imgSrc && (
            <ReactCrop
              crop={crop}
              onChange={(_, percentCrop) => setCrop(percentCrop)}
              onComplete={(c) => setCompletedCrop(c)}
              aspect={aspect}
            >
              <img
                alt="to be cropped"
                src={imgSrc}
                ref={imgRef}
                style={{ transform: `scale(${scale})` }}
                onLoad={onImageLoad}
              />
            </ReactCrop>
          )}
          {!!completedCrop && (
            <>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginBottom: 16,
                }}
              >
                <canvas
                  ref={previewCanvasRef}
                  style={{
                    border: "1px solid black",
                    objectFit: "contain",
                    display: "none", // TODO: FIX need to show user another confirmation mode ?currently we just hide the preview canvas so user don't need to scroll
                    width: "80%",
                  }}
                />
              </div>
              <div
                style={{
                  // make everything flex end :
                  display: "flex",
                  justifyContent: "flex-end",
                  marginBottom: 8,
                  marginRight: 16,
                }}
              >
                <Button
                  onClick={() => {
                    handleClose(null);
                  }}
                  variant="outlined"
                  color="error"
                  style={{ marginRight: 8 }}
                  pill
                >
                  Dismiss
                </Button>
                <Button
                  variant="contained"
                  color="success"
                  onClick={uploadClickHandlerV2}
                  pill
                >
                  Set
                </Button>
              </div>
            </>
          )}
        </Card>
        <Snackbar
          open={showSnackBar}
          autoHideDuration={5000}
          onClose={() => setShowSnackbar(false)}
        >
          <Alert
            severity="error"
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setShowSnackbar(false);
                }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
          >
            {snackbarMessage}
          </Alert>
        </Snackbar>
      </Box>
    </Modal>
  );
};

export default UploadPhotoDialog;
