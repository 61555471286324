import {
  EditNote,
  ExpandMore,
  FormatListBulleted,
  PlayArrow,
  QuestionAnswer,
} from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Card,
  CardContent,
  Chip,
  Container,
  Fab,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import {
  GetFlowByShortenKey,
  postCloneFlowByFlowId,
  saveFlowMetadata,
  uploadFlowQuestionPhoto,
} from "../Utils/fetchers";
import {
  COLORS_PALETTE,
  PAPER_CARD_STYLE_4,
  QUESTION_TYPE_ENUM,
} from "../Utils/static";
import "./Question.css";
import QuestionEditDialog from "Dialogs/QuestionEditDialog";
import { ViewTitle } from "Components/ViewTitle";

import HelpIcon from "@mui/icons-material/Help";

function QuestionPreviewView() {
  const [isLoading, setIsLoading] = useState(true);
  const [perQuestionStatus, setPerQuestionStatus] = useState(null);
  const [questionsArray, setQuestionsArray] = useState([]);
  const [blueprint, setBlueprint] = useState({});
  const [flow, setFlow] = useState(null);
  const navigate = useNavigate();
  let { flowShortenKey } = useParams();

  const [searchParams, setSearchParams] = useSearchParams();
  const isViewOnly = searchParams.get("viewOnly") === "true";
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [edittableQuestionId, setEdittableQuestionId] = useState(null);
  const [questionToProcessed, setQuestionToProcessed] = useState({});
  useEffect(() => {
    fetchFlow();
  }, []);
  function fetchFlow() {
    return GetFlowByShortenKey(flowShortenKey)
      .then((response) => response.json())
      .then((data) => {
        if (data.blueprint?.metadata?.questions_order?.length >= 0) {
          // sort data.questions by the order in metadata :
          const orderedQuestions = [];
          data.blueprint.metadata.questions_order.forEach((qId) => {
            const q = data.blueprint.questions.find((q) => q.id === qId);
            orderedQuestions.push(q);
          });
          setQuestionsArray(orderedQuestions);
        } else {
          setQuestionsArray(data.blueprint.questions);
        }

        setBlueprint(data.blueprint);
        setIsLoading(false);
      });

    // .catch((error) => console.error("Error fetching question:", error));
  }
  function handleEditQuestionModelClose(questionOverride) {
    setEditModalOpen(false);
    if (questionOverride) {
      const newQuestionsArray = [...questionsArray];
      if (newQuestionsArray[edittableQuestionId] !== questionOverride) {
        questionToProcessed[questionOverride.id] = questionOverride;
      }
      newQuestionsArray[edittableQuestionId] = questionOverride;
      setQuestionsArray(newQuestionsArray);
    }
  }

  const saveQuestionPhotos = (questionsToUpdateMap, shorten_url) => {
    const promises = [];
    console.log("question.metadata.images.forEach");
    Object.keys(questionsToUpdateMap).forEach((questionID) => {
      const question = questionsToUpdateMap[questionID];
      question.metadata.images?.forEach((image, idx) => {
        console.log("question.metadata.images.forEach()", image, idx);
        if (image.blob) {
          console.log("image.blog", image);
          const formData = new FormData();
          formData.append("image", image.blob);
          formData.append("questionId", question.id);
          formData.append("blueprintId", blueprint.id);
          formData.append("flowShortenURL", shorten_url);
          formData.append("imageId", idx);
          promises.push(
            // we are using promise to make sure that every image is uploaded:
            // am doing nested /wrapper promise so that we don't return the promise until we set the url on the image

            new Promise((resolve, reject) => {
              uploadFlowQuestionPhoto(formData)
                .then((response) => {
                  response
                    .json()
                    .then((data) => {
                      image.url = data;
                      delete image.blob;
                      resolve();
                    })
                    .catch((error) => {
                      reject(error);
                    });
                })
                .catch((error) => {
                  reject(error);
                });
            })
          );
        }
      });
    });
    return Promise.all(promises);
  };
  if (isLoading) return "Loading...";

  return (
    <Container maxWidth="sm" style={{ marginTop: 20 }}>
      {!isViewOnly && (
        <Card
          sx={{
            backgroundColor: COLORS_PALETTE.cardLightColor,
            borderRadius: 3,
            color: COLORS_PALETTE.paletteText5,
            marginBottom: "16px",
            fontSize: 16,
          }}
        >
          <CardContent
            style={{
              display: "flex",
              alignItems: "center",
              padding: "16px",
              // paddingBottom: "0px",
              ...PAPER_CARD_STYLE_4,
            }}
          >
            <HelpIcon color="info" scale={8} />
            <Typography
              variant="body"
              style={{ marginLeft: "10px" }}
              gutterBottom
            >
              Each question can be change and customized to your needs. You can
              expand the question to see the details.
            </Typography>
          </CardContent>
        </Card>
      )}

      <ViewTitle>{blueprint.description}</ViewTitle>

      {/* <Typography variant="h5" gutterBottom>
            {blueprint.description}
          </Typography> */}
      {/* for each question show this grid: */}
      {questionsArray.map((question, questionIndex) => {
        return (
          <Accordion
            style={{
              ...PAPER_CARD_STYLE_4,
              padding: 8,
              width: "100%",
              marginBottom: 18,
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMore />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Grid container>
                <Grid item xs={12}>
                  <Typography
                    variant="h6"
                    style={{
                      // marginBottom: 50,
                      // minHeight: 100,
                      // background: "#ffffff",
                      borderRadius: 8,
                    }}
                  >
                    Q{questionIndex + 1}: {question.text}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Chip
                    style={{ marginRight: 8 }}
                    label={QUESTION_TYPE_ENUM[question.type]}
                    icon={<QuestionAnswer />}
                  />
                  <Chip
                    style={{ marginRight: 8 }}
                    label={
                      question.metadata.choices?.length
                        ? question.metadata.choices?.length + " choices"
                        : question.metadata.images?.length + " images"
                    }
                    icon={<FormatListBulleted />}
                  />
                </Grid>
              </Grid>
            </AccordionSummary>

            <AccordionDetails style={{ background: "#FFF", borderRadius: 8 }}>
              {/* {JSON.stringify(question, null, 2)} */}

              <Typography variant="body1">Choices:</Typography>
              {question?.metadata?.choices.map((choice, index) => {
                return (
                  <Chip
                    label={choice}
                    style={{
                      marginLeft: 5,
                      marginBottom: 5,
                      background: COLORS_PALETTE.paletteTwistSpot1,
                      color: "#FFF",
                    }}
                  ></Chip>
                );
              })}
              {question?.metadata?.images?.length >= 0 &&
                question?.metadata?.images.map((image, index) => {
                  return (
                    <img
                      src={
                        image.url ? image.url : URL.createObjectURL(image.blob)
                      }
                      style={{
                        width: 50,
                        height: 50,
                        marginRight: 5,
                      }}
                      alt="preview"
                    ></img>
                  );
                })}

              {/* {blueprint?.metadata?.editable_questions?.includes(
                    question.id
                  ) && ( */}
              <IconButton
                style={{
                  backgroundColor: "#f5f5f5",
                  position: "absolute",
                  bottom: 12,
                  right: 12,
                }}
                onClick={() => {
                  setEdittableQuestionId(questionIndex);
                  setEditModalOpen(true);
                }}
              >
                <EditNote />
              </IconButton>
              {/* )} */}
            </AccordionDetails>
          </Accordion>
        );
      })}

      {/* when share button click generate image from this html and open share view on phone: */}
      {/* {!isViewOnly && (
        <Fab
          variant="extended"
          color="primary"
          title="sdfsaf"
          fullWidth
          size="large"
          style={{
            position: "fixed",
            bottom: "20px",
            right: "20px",
          }}
          onClick={() => {
            postCloneFlowByFlowId(blueprint.id).then((response) => {
              response.json().then((data) => {
                if (response.status === 200) {
                  navigate("/f/" + data.shorten_url + "?isOwner=true");
                } else {
                  navigate("/flows");
                }
              });
            });

            // generate image from this html and open share view on phone:
          }}
        >
          <PlayArrow />
          Take the Challenge
        </Fab>
      )} */}
      {!isViewOnly && (
        <Fab
          variant="extended"
          color="primary"
          title="sdfsaf"
          fullWidth
          size="large"
          style={{
            position: "fixed",
            bottom: "20px",
            left: "20px",
          }}
          onClick={() => {
            postCloneFlowByFlowId(blueprint.id).then((response) => {
              response.json().then((data) => {
                saveQuestionPhotos(questionToProcessed, data.shorten_url).then(
                  () => {
                    saveFlowMetadata(
                      data.shorten_url,
                      questionToProcessed
                    ).then((metadataRes) => {
                      navigate("/f/" + data.shorten_url + "?isOwner=true");
                    });
                  }
                );
              });
            });

            // generate image from this html and open share view on phone:
          }}
        >
          <PlayArrow />
          Save and Take the Challenge
        </Fab>
      )}
      {editModalOpen && (
        <QuestionEditDialog
          isOpen={editModalOpen}
          question={questionsArray[edittableQuestionId]}
          handleClose={handleEditQuestionModelClose}
          // modelMetadata={questionModelMetadata}
        ></QuestionEditDialog>
      )}
    </Container>
  );
}

export default QuestionPreviewView;
