import { Box, Button } from "@mui/material";
import { GRADIENTS } from "../constants";

export default function BetaAccessButton() {
  return (
    <Button
      sx={{
        background: GRADIENTS[3],
        fontSize: "clamp(20px, 3.1vw, 24px)",
        lineHeight: "clamp(28px, 4.1vw, 32px)",
        padding: "2px",
        border: "none",
        borderRadius: "6px",
        ":hover": {
          color: "#fff",
          border: "none",
        },
      }}
      variant="contained"
      size="large"
      href="/home"
    >
      <Box
        sx={{
          background: "#fff",
          borderRadius: "4px",
          transition: "all 0.2s ease-in-out",
          ":hover": {
            background: "transparent",
          },
        }}
      >
        <Box
          sx={{
            background: GRADIENTS[3],
            WebkitBackgroundClip: "text",
            WebkitTextFillColor: "transparent",
            fontSize: "clamp(20px, 3.1vw, 24px)",
            padding: "6px 12px",

            borderRadius: "4px",
            height: "100%",
            width: "100%",
            lineHeight: "clamp(28px, 4.1vw, 32px)",
            border: "none",
            transition: "all 0.2s ease-in-out",
            ":hover": {
              background: "transparent",
              WebkitBackgroundClip: "unset",
              WebkitTextFillColor: "unset",
              color: "#fff",
            },
          }}
        >
          Get Beta Access
        </Box>
      </Box>
    </Button>
  );
}
