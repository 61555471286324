import logo_asset from "Static/logo_9.png";
import { Box } from "@mui/material";

export default function Logo({ onClick = () => {}, size = "24px" }) {
  return (
    <Box onClick={onClick}>
      <Box
        component="img"
        src={logo_asset}
        alt="WK"
        height={size}
        borderRadius="0"
        style={{ cursor: "pointer", margin: 0 }}
      />
    </Box>
  );
}
