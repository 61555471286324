import { de } from "date-fns/locale";
import { v4 as uuidv4 } from "uuid";

export default function getUniqueID() {
  // TODO: Move this into a utility function after validating this is the best way

  const storedUniqueId = localStorage.getItem("uniqueId");
  if (storedUniqueId) {
    return storedUniqueId;
  } else {
    // If no identifier exists, generate a new one using uuid and store it in localStorage
    const newUniqueId = uuidv4();
    localStorage.setItem("uniqueId", newUniqueId);
    return newUniqueId;
  }
}

export function formatNumbers(value) {
  const abbreviations = {
    K: 1000,
    M: 1000000,
    B: 1000000000,
  };
  const num = parseFloat(value);
  for (const abbreviation in abbreviations) {
    if (num >= abbreviations[abbreviation]) {
      return (num / abbreviations[abbreviation]).toFixed(1) + abbreviation;
    }
  }
  return value;
}

export function copyToClipboard(textToCopy) {
  const textField = document.createElement("textarea");
  textField.innerText = textToCopy;
  document.body.appendChild(textField);
  textField.select();
  document.execCommand("copy");
  textField.remove();
}
