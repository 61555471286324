import { Typography } from "@mui/material";
import { COLORS_PALETTE } from "Utils/static";

export const ViewTitle = ({ children }) => {
  return (
    <Typography
      variant="h5"
      gutterBottom
      style={{
        marginTop: 10,
        marginLeft: 10,
        color: "#FFFFFF",
        fontWeight: 700,
      }}
    >
      {children}
    </Typography>
  );
};
