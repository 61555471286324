import { Box, Typography } from "@mui/material";
import QuestionTypeCard from "../Components/QuestionTypeCard";
import BetaAccessButton from "../Components/BetaAccessButton";
import AutoplayCarousel from "../Components/AutoplayCarousel";

export default function Third() {
  return (
    <Box
      sx={{
        minHeight: "100vh",
        display: "grid",
        placeItems: "start center",
        // borderTop: "1px solid red",
        padding: "8px",
        background: "#F4EFF4",
      }}
    >
      <Box
        sx={{
          padding: "72px",
          background: "#FFF",
          borderRadius: "28px",
          paddingTop: "48px",
          color: "#000",
          width: "100%",
        }}
      >
        <Typography
          variant="h2"
          sx={{
            fontWeight: "700",
            fontSize: "clamp(28px, 4.1vw, 36px)",
            textAlign: "center",
            marginBottom: "16px",
          }}
        >
          Experience Engaging Interactions Like Never Before
        </Typography>
        <Typography
          variant="body1"
          sx={{
            fontWeight: "300",
            fontSize: "clamp(16px, 2.1vw, 20px)",
            textAlign: "center",
            marginBottom: "16px",
          }}
        >
          just to name few ways:
        </Typography>
        <Box
          sx={{
            display: "grid",
            gridGap: "16px",
            gridTemplateColumns: "repeat(auto-fit, minmax(200px, 1fr))",
          }}
        >
          <QuestionTypeCard
            icon="fa-ranking-star"
            title="Ranking"
            description="Let users order items or photos based on their preferences."
          />

          <QuestionTypeCard
            icon="fa-face-kiss-wink-heart"
            title="Emojis"
            description="Express sentiments visually and vibrantly."
            gradientIndex={1}
          />

          <QuestionTypeCard
            icon="fa-dice-one"
            title="One Word"
            description="A challenge to define thoughts in just a single word."
            gradientIndex={2}
          />

          <QuestionTypeCard
            icon="fa-toggle-on"
            title="Yes/No"
            gradientIndex={3}
            description="Direct feedback with simple responses."
          />
        </Box>
      </Box>
      <Box padding="32px 0">
        <BetaAccessButton />
      </Box>
      <Box>
        <AutoplayCarousel
          cardDetails={{
            0: {
              imgUrl: "https://picsum.photos/id/237/200/300",
              title: "Text 1",
            },

            1: {
              imgUrl: "https://picsum.photos/id/238/200/300",
              title: "Text 2",
            },
          }}
        />
      </Box>
    </Box>
  );
}
