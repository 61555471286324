import React, { useContext, useEffect, useState } from "react";
import {
  Avatar,
  Card,
  CardContent,
  Container,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
  Divider,
  makeStyles,
  Box,
  TextField,
  Button,
  Snackbar,
  MenuItem,
  FormControlLabel,
  Checkbox,
} from "@mui/material";

import { HEADERS, PostIssue, getUserActivities } from "../../Utils/fetchers";
import { formatDistanceToNow } from "date-fns";
import { Navigate, useNavigate } from "react-router-dom";
import { UserContext } from "Providers/UserContext";
import { API_URL } from "Config";
import { ViewTitle } from "Components/ViewTitle";
import { set } from "lodash";
import { CheckBox } from "@mui/icons-material";
import Footer from "Views/Landing/Components/Footer";
const ReportIssueView = () => {
  const navigate = useNavigate();
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState("Sending...");
  const [issueDescription, setIssueDescription] = useState("");
  const [includeMetadata, setIncludeMetadata] = useState(true);
  return (
    <Container maxWidth="xs">
      <ViewTitle>Report Issue</ViewTitle>

      <Card variant="outlined" style={{ borderRadius: "24px" }}>
        <CardContent>
          <Box
            sx={{
              display: "grid",
              placeItems: "center",
              gap: 2,
              padding: "16px",
            }}
          >
            <Typography variant="body1" align="left">
              Please describe the issue you are facing below. We will get back
              to you as soon as possible.
            </Typography>
            <TextField
              multiline
              label="Issue description"
              variant="outlined"
              fullWidth
              onChange={(e) => {
                e.preventDefault();
                setIssueDescription(e.target.value);
              }}
              value={issueDescription}
              inputProps={{ maxLength: 220 }}
              helperText={`${issueDescription.length}/220 character limit`}
            />

            {/* Add a checkbox the checked by default : */}
            <FormControlLabel
              value="end"
              control={
                <Checkbox
                  defaultChecked
                  checked={includeMetadata}
                  onChange={(e) => setIncludeMetadata(e.target.checked)}
                />
              }
              label="Include the URL of the page you are reporting an issue about. with information about the device you are using.( Screen size, browser, etc.)"
              labelPlacement="end"
            />
            <Button
              variant="outlined"
              color="success"
              fullWidth
              size="large"
              sx={{ height: "56px" }}
              onClick={() => {
                setSnackBarMessage("Sending...");
                setShowSnackbar(true);
                const body = {};
                if (includeMetadata) {
                  body.metadata = JSON.parse(
                    localStorage.getItem("reportIssueData")
                  );
                }
                body.description = issueDescription;
                body.type = 1;
                PostIssue(body).then((res) => {
                  console.log(res);
                });

                setSnackBarMessage("Sent!");
                setTimeout(() => {
                  // navigate("/home");
                }, 300);
              }}
            >
              Submit
            </Button>
          </Box>
        </CardContent>
      </Card>
      <Snackbar
        open={showSnackbar}
        autoHideDuration={3000}
        onClose={() => setShowSnackbar(false)}
        message={snackBarMessage}
      />
      <Typography
        variant="body2"
        color="textSecondary"
        style={{ textAlign: "right" }}
      >
        V{process.env.REACT_APP_BUILD_VERSION}
      </Typography>
    </Container>
  );
};

export default ReportIssueView;
