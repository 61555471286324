import { Box, CircularProgress, Typography } from "@mui/material";

const ProgressWithLabel = ({ value, label, size, marginTop, className }) => {
  return (
    <Box
      position="relative"
      display="inline-flex"
      marginTop={marginTop}
      className={className}
    >
      <CircularProgress
        variant="determinate"
        value={value}
        size={size}
        color="primary"
      />
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Typography
          variant="caption"
          component="div"
          color="textSecondary"
          fontSize={25}
        >
          {label}
        </Typography>
      </Box>
    </Box>
  );
};

export default ProgressWithLabel;
