import "./App.css";

import NavigationBar from "Components/NavigationBar/NavigationBar";
import { UserContext } from "Providers/UserContext";
import { AuthenticationProvider } from "Service/AuthenticationService";
import { FONTS } from "Utils/static";
import ActivitiesView from "Views/Activities/ActivitiesView";
import AnswerStatsView from "Views/AnswerStatsView";
import AnswerStatsViewChallenge from "Views/AnswerStatsViewChallenge";
import BlueprintCreateView from "Views/Blueprints/BlueprintCreateView";
import BlueprintEditView from "Views/Blueprints/BlueprintEditView";
import BlueprintList from "Views/Blueprints/BlueprintsListView";
import DashboardView from "Views/Dashboard/DashboardView";
import FlowSettingsView from "Views/Flows/FlowSettingsView";
import FlowViewList from "Views/Flows/FlowViewList";
import LandingView from "Views/Landing/LandingView";
import LoginView from "Views/LoginView";
import MainView from "Views/MainView/MainView";
import QuestionPreviewView from "Views/QuestionPreviewView";
import QuestionView from "Views/QuestionView";
import SignupView from "Views/SignupView/SignupView";
import PrivacyView from "Views/Static/PrivacyView";
import TermsView from "Views/Static/TermsView";
import LandingPage from "Views/Tests/TestView";
import TestView2 from "Views/Tests/TestView2";
import Signup from "Views/Tests/TestView3";
import TestView4 from "Views/Tests/TestView4";
import TestView5 from "Views/Tests/TestView5";
import React, { useEffect, useState } from "react";
import {
  BrowserRouter,
  Route,
  Routes,
  unstable_HistoryRouter,
  useLocation,
} from "react-router-dom";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { GetUserProfile, isTokenValid } from "Utils/fetchers";
import ProfileView from "Views/ProfileView/ProfileView";
import ReportIssueView from "Views/ReportIssue/ReportIssueView";
import TestView6 from "Views/Tests/TestView6";
import { Snackbar } from "@mui/material";
import { Button } from "Components/CustomHTMLComponent";
// import { useServiceWorker } from "useServiceWorker";
import VerificationComponent from "Views/SignupView/VerifyViewHandler";
import EmailVerificationHandler from "Views/SignupView/EmailVerificationHandler";
import PasswordUpdateView from "Views/LoginView/PasswordUpdateView";
import ResetPasswordView from "Views/LoginView/ResetPasswordView";

import * as serviceWorker from "./serviceWorkerRegistration";
import { set } from "lodash";
function App() {
  const [showUpdateSnackbar, setShowUpdateSnackbar] = useState(false);
  const [userProfile, setUserProfile] = useState(null);
  const [newVersionAvailable, setNewVersionAvailable] = useState(false);
  const [waitingWorker, setWaitingWorker] = useState(false);
  const [reloadServiceFn, setReloadServiceFn] = useState(null);

  const onServiceWorkerUpdate = (registration) => {
    setNewVersionAvailable(true);
    setWaitingWorker(registration && registration.waiting);
    localStorage.setItem("vqa_sw_update", true);
  };
  // whenever the component changes, check if we have update if so reload:

  useEffect(() => {
    if (isTokenValid()) {
      if (!userProfile) getUserProfile();
    }
  }, []);

  useEffect(() => {
    serviceWorker.register({ onUpdate: onServiceWorkerUpdate });
    if (newVersionAvailable) {
      setShowUpdateSnackbar(true);
    }
    console.log("WK: ", process.env.REACT_APP_BUILD_VERSION);
  }, [serviceWorker, newVersionAvailable]);

  useEffect(() => {
    navigator.serviceWorker.register("/service-worker.js").then((reg) => {
      // console.log("REGISTRATION", reg);
      // every minute check for update
      navigator.serviceWorker.addEventListener("controllerchange", () => {
        window.location.reload();
      });

      setInterval(() => {
        reg.update();
        // console.debug("Checked for update...");
        localStorage.setItem("lastUpdateCheck", new Date());
        // every 30 minutes check for update
      }, 1000 * 60 * 30);
      reg.addEventListener("updatefound", () => {
        const installingWorker = reg.installing;

        if (!installingWorker) {
          return;
        }

        installingWorker.addEventListener("statechange", () => {
          if (
            installingWorker.state !== "installed" ||
            !navigator.serviceWorker.controller
          ) {
            return;
          }
          setNewVersionAvailable(true);
          setReloadServiceFn(() => {
            return () => {
              installingWorker.postMessage({ type: "SKIP_WAITING" });
              setShowUpdateSnackbar(false);
              window.location.reload();
            };
          });
        });
      });
    });
  }, []);

  const getUserProfile = () => {
    GetUserProfile()
      .then((res) => {
        res.json().then((data) => {
          setUserProfile(data.user);
        });
      })
      .catch((error) => {});
  };
  const theme = createTheme({
    typography: {
      fontFamily: FONTS.join(","),
      button: {
        textTransform: "none",
      },
    },
    palette: {
      primary: {
        light: "#4a4ae0",
        main: "#6a6ae0",
        dark: "#8787e6",
        contrastText: "#fff",
      },
      secondary: {
        light: "#ff784e",
        main: "#ff5722",
        dark: "#b23c17",
        contrastText: "#fff",
      },
      text: {
        primary: "#6a6ae0",
        secondary: "#4b4453",
        // secondary: styles.tt,
        // disabled: styles.ttt,
        // hint: styles.tttt,
      },
    },
    shape: { pillRadius: 24 },
  });
  const updateServiceWorker = () => {
    // if (waitingWorker) {
    //   waitingWorker.addEventListener("statechange", (event) => {
    //     if (event.target.state === "activated") {
    // setNewVersionAvailable(false);
    // setShowUpdateSnackbar(false);
    // window.location.reload();
    //   }
    // });
    reloadServiceFn();
    localStorage.setItem("vqa_sw_update", false);
    waitingWorker.postMessage({ type: "SKIP_WAITING" });
    // }
  };
  return (
    <BrowserRouter>
      <AuthenticationProvider />
      <UserContext.Provider value={{ userProfile, setUserProfile }}>
        <ThemeProvider theme={theme}>
          <NavigationBar />
          <Routes>
            {/* <Route path="/" element={<TestView2 />} /> */}
            <Route path="/" element={<LandingView />} />
            <Route path="/privacy" element={<PrivacyView />} />
            <Route path="/terms" element={<TermsView />} />
            <Route path="/test" element={<LandingPage />} />
            <Route path="/home" element={<MainView />} />
            <Route path="/dashboard" element={<DashboardView />} />
            <Route path="/login" element={<LoginView />} />

            <Route path="/signup" element={<SignupView />} />
            <Route
              path="/signup/emailSent"
              element={<EmailVerificationHandler />}
            />
            <Route path="/password/update" element={<PasswordUpdateView />} />
            <Route path="/password/reset" element={<ResetPasswordView />} />
            <Route path="/verify" element={<VerificationComponent />} />
            <Route path="/flows" element={<FlowViewList />} />
            <Route path="/testsettings" element={<FlowSettingsView />} />
            <Route path="/blueprints" element={<BlueprintList />} />

            <Route path="/test3" element={<Signup />} />

            <Route path="/test2" element={<TestView2 />} />
            <Route path="/test4" element={<TestView4 />} />
            <Route path="/test5" element={<TestView5 />} />
            <Route path="/test6" element={<TestView6 />} />

            <Route
              path="/blueprints/:flowId/start"
              element={<QuestionView />}
            />
            <Route path="/me" element={<ProfileView />} />
            <Route path="/report" element={<ReportIssueView />} />
            <Route path="f/:flowShortenKey" element={<QuestionView />} />
            <Route
              path="stats/flows/:flowShortenKey/"
              element={<AnswerStatsView />}
            />
            <Route path="s/f/:flowShortenKey/" element={<AnswerStatsView />} />
            <Route
              path="/results/f/:flowShortenKey/"
              element={<AnswerStatsViewChallenge />}
            />
            <Route
              path="/preview/:flowShortenKey"
              element={<QuestionPreviewView />}
            />
            <Route path="/activities" element={<ActivitiesView />} />

            <Route path="/landing" element={<LandingView />} />
            <Route
              path="/blueprints/:blueprintId/edit"
              element={<BlueprintEditView />}
            />

            <Route
              path="/blueprints/create"
              element={<BlueprintCreateView />}
            />

            {/* <Route index element={<Home />} />
          <Route path="blogs" element={<Blogs />} />
          <Route path="contact" element={<Contact />} />
          <Route path="*" element={<NoPage />} /> */}
          </Routes>
        </ThemeProvider>
      </UserContext.Provider>
      <Snackbar
        open={showUpdateSnackbar}
        message="A new version is available, Please refresh to get the latest!"
        action={
          <Button
            color="secondary"
            size="small"
            onClick={() => {
              updateServiceWorker();
            }}
          >
            Refresh
          </Button>
        }
      />
      {/* <pre
        onClick={() => {
          console.log("UPDATING SERVICE WORKER");
          navigator.serviceWorker
            .getRegistrations()
            .then((registrationsArray) => {
              console.log("REGISTRATION ARRAY", registrationsArray);
              registrationsArray[0].update();
            });
        }}
      >
        {JSON.stringify(newVersionAvailable, null, 2)}
      </pre> */}
    </BrowserRouter>
  );
}

export default App;
