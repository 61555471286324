import PropTypes from "prop-types";
import * as React from "react";
import { Typography } from "@mui/material";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Button } from "Components/CustomHTMLComponent";

export default function ConfirmationDialog(props) {
  const { onClose, value: valueProp, open, message, ...other } = props;
  const radioGroupRef = React.useRef(null);

  React.useEffect(() => {}, [open]);

  const handleEntering = () => {
    if (radioGroupRef.current != null) {
      radioGroupRef.current.focus();
    }
  };

  const handleCancel = () => {
    onClose();
  };

  const handleOk = () => {
    onClose(true, message?.type);
  };

  return (
    <Dialog
      sx={{ "& .MuiDialog-paper": { width: "80%", maxHeight: 500 } }}
      maxWidth="xs"
      TransitionProps={{ onEntering: handleEntering }}
      open={open}
      {...other}
    >
      <DialogTitle>
        {message?.title ? message.title : "Confirmation"}
      </DialogTitle>
      <DialogContent dividers>
        <Typography gutterBottom>
          {message?.body ? message.body : "are you sure you wanyt to proceed"}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button
          autoFocus
          onClick={handleCancel}
          size="large"
          color="primary"
          variant="outlined"
          pill
        >
          {message?.cancel ? message.cancel : "Cancel"}
        </Button>
        <Button
          onClick={handleOk}
          size="large"
          color="primary"
          variant="contained"
          pill
        >
          {message?.confirm ? message.confirm : "Confirm"}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

ConfirmationDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  message: PropTypes.object,
};
