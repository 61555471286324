import SplitButton from "Components/SplitButtonComponent";
import { API_URL } from "Config";
import { UserContext } from "Providers/UserContext";
import GettingStartedIcon from "Static/pending_payment/campaign.png";
import QuickQuestionIcon from "Static/pending_payment/decision-making.png";
import QuestionsSet from "Static/pending_payment/decision-tree.png";
import PhotoQuestionSet from "Static/pending_payment/gallery.png";
import ChallengeIcon from "Static/pending_payment/goal.png";
import TrendyTopicsIcon from "Static/pending_payment/trending-topic.png";
import InsightIcon from "Static/pending_payment/pie-chart-1.png";
import { getRecommendedFlows, isTokenValid, vfetch } from "Utils/fetchers";
import { COLORS_PALETTE } from "Utils/static";
import React, { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Typography,
} from "@mui/material";
import CreateNewButtonDropdown from "./Components/CreateNewButtonDropdown";
import FeaturedFlowCard from "./Components/FeaturedFlowCard";

const MainView = () => {
  const { userProfile } = useContext(UserContext);
  const [recommendedFlows, setRecommendedFlows] = React.useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    fetchRecommendedFlows();

    if (isTokenValid()) {
      fetchRecommendedFlows();
    } else {
      navigate("/login");
    }
  }, []);
  const handleCreateBlueprint = (description, type = 1, initRouteWith) => {
    vfetch(API_URL + "/flows", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("vqa_token"),
      },
      body: JSON.stringify({
        flow: { description: description, type: type },
      }),
    }).then((response) => {
      if (response.status === 200) {
        return response.json().then((data) => {
          let flow = { id: data.id, description: data.description };

          navigate(
            "/blueprints/" +
              flow.id +
              "/edit" +
              "?initRouteWith=" +
              initRouteWith
          );
        });
      }
      return response.json().then((error) => {
        console.error("Error creating flow:", error);
        // throw new Error(error.message);
      });
    });
  };
  // use effect and create a fetch recommended flows:
  const fetchRecommendedFlows = () => {
    getRecommendedFlows()
      .then((res) => res.json())
      .then((data) => {
        setRecommendedFlows(data);
      })
      .catch((err) => console.log(err));
  };
  if (userProfile === null) {
    return;
  }

  const { display_name, username } = userProfile;
  const name = display_name ? display_name : username;

  return (
    <Container
      maxWidth="xs"
      sx={{ marginTop: "8px", display: "grid", gap: "16px" }}
    >
      <Box
        sx={{
          display: "grid",
          padding: "32px 0",
          placeItems: "center",
          gap: 2,
        }}
      >
        <Avatar
          alt="User Avatar"
          src={userProfile?.image}
          sx={{ height: 80, width: 80 }}
        />
        <Typography
          variant="h5"
          style={{ marginLeft: "10px", fontWeight: 700 }}
        >
          Hi, {name[0].toUpperCase() + name.slice(1)}!
        </Typography>
      </Box>

      <Card
        sx={{
          backgroundColor: COLORS_PALETTE.cardLightColor,
          borderRadius: 3,
          color: COLORS_PALETTE.paletteText5,
          marginBottom: "16px",
        }}
      >
        <CardContent
          style={{
            display: "flex",
            alignItems: "center",
            padding: "16px",
            paddingBottom: "0px",
          }}
        >
          <img
            alt="User Avatar"
            src={GettingStartedIcon}
            style={{ height: 55, width: 55 }}
          />
          <Typography variant="h5" style={{ marginLeft: "10px" }}>
            Getting started
          </Typography>
        </CardContent>
        <CardContent>
          <Typography
            variant="body1"
            style={{ marginLeft: "10px" }}
            gutterBottom
          >
            {/* write a welcome message and mention this is beta access! */}
            Create a set of questions, a quick inquiry, or an exciting challenge
            to get the conversation flowing
          </Typography>
          <Box sx={{ paddingTop: "16px" }}>
            <CreateNewButtonDropdown />
          </Box>
        </CardContent>
      </Card>

      <Card
        sx={{
          backgroundColor: COLORS_PALETTE.cardLightColor,
          borderRadius: 3,
          color: COLORS_PALETTE.paletteText5,
          marginBottom: "16px",
        }}
      >
        <CardContent
          style={{
            display: "flex",
            alignItems: "center",
            padding: "16px",
            paddingBottom: "0px",
          }}
        >
          <img
            alt="Insights"
            src={InsightIcon}
            style={{ height: 55, width: 55 }}
          />
          <Typography variant="h5" style={{ marginLeft: "10px" }}>
            Inquiries and Insights
          </Typography>
        </CardContent>
        <CardContent>
          <Typography
            variant="body1"
            style={{ marginLeft: "10px" }}
            gutterBottom
          >
            See all your active and past Inquiries and manage them here
          </Typography>

          <div style={{ display: "flex" }}>
            <Button
              variant="outlined"
              color="primary"
              style={{
                backgroundColor: COLORS_PALETTE.primaryColor,
                color: COLORS_PALETTE.paletteText1,
                margin: 8,
                padding: 8,
                paddingLeft: 24,
                paddingRight: 24,
                borderRadius: 26,
              }}
              disableElevation
              size="large"
              onClick={() => {
                navigate("/flows");
              }}
            >
              Inquiries
            </Button>
            <Button
              variant="outlined"
              color="primary"
              style={{
                color: COLORS_PALETTE.primaryColor,
                borderWidth: 3,
                margin: 8,
                padding: 8,
                paddingLeft: 24,
                paddingRight: 24,
                borderRadius: 26,
              }}
              disableElevation
              size="large"
              onClick={() => {
                navigate("/blueprints");
              }}
            >
              My Draft
            </Button>
          </div>
        </CardContent>
      </Card>

      {/* <Box display="grid" gap={2} paddingBottom={2}> */}
      <Card
        style={{
          borderRadius: 16,
          color: COLORS_PALETTE.paletteText5,
          marginBottom: "16px",
        }}
      >
        <CardContent>
          <Typography
            variant="h5"
            style={{
              display: "flex",
              alignItems: "center",
              color: COLORS_PALETTE.paletteText5,
            }}
          >
            {/* add  <TrendyTopicsIcon /> image before the text:  */}
            <img
              src={TrendyTopicsIcon}
              style={{ height: 55, width: 55, marginRight: "10px" }}
            />
            Featured:
          </Typography>

          {recommendedFlows?.map((props) => (
            <FeaturedFlowCard key={props.id} {...props} />
          ))}
        </CardContent>
      </Card>
      {/* </Box> */}

      {/* Add more trendy items here */}  
    </Container>
  );
};

export default MainView;
