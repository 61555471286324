import React, { useEffect, useRef, useState } from "react";
import { useSprings, animated } from "@react-spring/web";
import { useDrag } from "react-use-gesture";
import { clamp } from "lodash";
import swap from "lodash-move";

const fn =
  (itemSize) =>
  (order, active = false, originalIndex = 0, curIndex = 0, x = 0) =>
  (index) =>
    active && index === originalIndex
      ? {
          x: curIndex * itemSize + x,
          scale: 1.1,
          zIndex: 1,
          shadow: 15,
          immediate: (key) => key === "x" || key === "zIndex",
        }
      : {
          x: order.indexOf(index) * itemSize,
          scale: 1,
          zIndex: 0,
          shadow: 1,
          immediate: false,
        };

const items = ["1", "2", "3", "4", "5"];

function TestView4() {
  const answerCallback = (order) => {
    console.log(order);
  };
  // calculate the maxi
  // const itemSize = Math.min(100, (window.innerWidth * 0.7) / items.length); // Adjust as needed
  const itemSize = 100; // Adjust as needed

  const [currentOrder, setCurrentOrder] = useState(items);
  const order = useRef(items.map((_, index) => index));

  const [springs, api] = useSprings(items.length, fn(itemSize)(order.current));

  const bind = useDrag(({ args: [originalIndex], active, movement: [x] }) => {
    const curIndex = order.current.indexOf(originalIndex);
    const curCol = clamp(
      Math.round((curIndex * itemSize + x) / itemSize),
      0,
      items.length - 1
    );
    const newOrder = swap(order.current, curIndex, curCol);

    api.start(fn(itemSize)(newOrder, active, originalIndex, curIndex, x));

    if (!active) {
      order.current = newOrder;
      setCurrentOrder(order.current);
      if (order.current) answerCallback(order.current);
    }
  });

  useEffect(() => {
    order.current = items.map((_, index) => index);
    api.start(fn(itemSize)(order.current));
  }, [items, api, itemSize]);

  return (
    <div style={{ width: "100%", position: "relative" }}>
      {springs.map(({ zIndex, shadow, x, scale }, i) => (
        <animated.div
          {...bind(i)}
          key={i}
          style={{
            position: "absolute",
            width: `${itemSize}px`,
            height: `${itemSize}px`,
            transformOrigin: "50% 50% 0px",
            borderRadius: "50%",
            color: "white",
            lineHeight: `${itemSize / 2}px`,
            textTransform: "uppercase",
            letterSpacing: "2px",
            touchAction: "none",

            zIndex,
            boxShadow: shadow.to(
              (s) => `rgba(0, 0, 0, 0.15) ${s}px 0px ${2 * s}px 0px`
            ),
            x,
            scale,
          }}
          children={items[i]}
        >
          <img
            src="https://wkassets.s3.wasabisys.com/test/68/132_1.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=BBHBZTL2NABYCHRAOTGN%2F20230923%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Date=20230923T195803Z&X-Amz-Expires=86400&X-Amz-SignedHeaders=host&X-Amz-Signature=2596198dc6aa25cc14531960a2c744b59fe51a3407c9d010407bb5a5338d7f66"
            alt="alt"
            style={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
              borderRadius: "50%",
            }}
          />
        </animated.div>
      ))}
    </div>
  );
}

export default TestView4;
