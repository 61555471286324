import { Label } from "@mui/icons-material";
import {
  Card,
  CardContent,
  FormControlLabel,
  FormGroup,
  Switch,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import { useEffect } from "react";

function FlowSettingsView() {
  useEffect(() => {}, []);

  return (
    <Card style={{ margin: 15, background: "#efeefc" }}>
      <CardContent>
        <Typography variant="h6" gutterBottom>
          Settings
        </Typography>
        <FormGroup>
          <FormControlLabel
            control={<Switch defaultChecked />}
            label="Show your name to participants"
          />
          <FormControlLabel
            control={<Switch defaultChecked />}
            label="Show results to participants"
          />
          <FormControlLabel
            control={<Switch defaultChecked />}
            label="Allow participants to see each other's answers"
          />

          <Typography variant="subtitle1" gutterBottom>
            Select the theme color:
          </Typography>
          <ToggleButtonGroup
            // value={ }
            exclusive
            onChange={(e, value) => console.log(value)}
          >
            {[
              { background: "#6a6ae0", name: "Purple" },
              { background: "#d65db1", name: "Pink" },
              // { background: "#ff9671", name: "Orange" },
              // { background: "#008f7a", name: "Green" },
              { background: "#2c73d2", name: "Blue" },
              { background: "#4b4453", name: "Black" },
            ].map((c) => (
              <ToggleButton
                value="purple"
                style={{ backgroundColor: c.background, color: "white" }}
              >
                {c.name}
              </ToggleButton>
            ))}
          </ToggleButtonGroup>
        </FormGroup>
      </CardContent>
    </Card>
  );
}

export default FlowSettingsView;
