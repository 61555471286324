import React from "react";

const PrivacyView = () => {
  return (
    <div style={{ color: "black", background: "#fff" }}>
      <h2>Privacy Policy</h2>
      <p>Effective Date: August 23, 2023</p>
      <p>
        This Privacy Policy explains how Wannaknow ("we", "us", or "our")
        collects, uses, and protects the personal information you provide
        through our website Wannaknow.link (the "Website"). By using our
        Website, you consent to the practices described in this Privacy Policy.
      </p>
      <h3>Information We Collect</h3>
      <ul>
        <li>
          <strong>Personal Information:</strong> We may collect personal
          information, such as your username, name, email address, and other
          contact details, when you interact with our Website or subscribe to
          our services.
        </li>
        <li>
          <strong>Usage Data:</strong> We may collect non-personal information,
          such as your IP address, browser type, operating system, and usage
          patterns, to analyze and improve our Website's performance.
        </li>
      </ul>
      <h3>How We Use Your Information</h3>
      <ul>
        <li>
          We may use the personal information you provide to send you updates,
          respond to inquiries, process orders, and provide customer support.
        </li>
        <li>
          We may use usage data to analyze trends, administer the Website, and
          gather demographic information for aggregate use.
        </li>
      </ul>
      <h3>Cookies and Tracking Technologies</h3>
      <p>
        We may use cookies, web beacons, and similar tracking technologies to
        enhance your experience on our Website. These technologies allow us to
        collect information about your interactions with our Website, such as
        the pages you visit and the links you click.
      </p>
    </div>
  );
};
export default PrivacyView;
