import Logo from "Components/Logo";
import { UserContext } from "Providers/UserContext";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { MenuRounded } from "@mui/icons-material";
import { AppBar, Avatar, IconButton, Toolbar } from "@mui/material";

function AvatarButton() {
  const navigate = useNavigate();
  const { userProfile } = useContext(UserContext);
  const { image } = userProfile || {};
  return (
    <IconButton
      aria-label="menu"
      onClick={() => navigate("/me")}
      style={{ border: 0, boxShadow: "none" }}
    >
      {image ? (
        <Avatar sx={{ height: "32px", width: "32px" }} src={image} />
      ) : (
        <Avatar sx={{ height: "32px", width: "32px" }} />
      )}
    </IconButton>
  );
}

export default function HeaderBar({ onClick, isLoggedIn }) {
  return (
    <AppBar
      position="static"
      elevation={0}
      sx={{
        backgroundColor: "transparent",
        // backgroundColor: "white",
        padding: "0px",
        justifyContent: "center",
      }}
    >
      <Toolbar
        variant="dense"
        sx={{
          display: "grid",
          placeItems: "center",
          gridTemplateColumns: "40px 1fr 40px",
        }}
      >
        <IconButton
          variant="outlined"
          aria-label="menu"
          onClick={onClick}
          elevation={0}
          style={{ border: 0, boxShadow: "none" }}
        >
          <MenuRounded />
        </IconButton>

        <Logo />

        {isLoggedIn ? <AvatarButton /> : <div />}
      </Toolbar>
    </AppBar>
  );
}
