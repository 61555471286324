import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import AnswerComponent from "../Question/AnswerComponent";

import "./QuestionView.css";

import {
  GetBlueprintByBlueprintId,
  GetFlowByShortenKey,
  postAnswerforQuestion,
  postEvent,
} from "../Utils/fetchers";

import {
  Alert,
  Container,
  Grid,
  IconButton,
  Snackbar,
  Typography,
  Zoom,
} from "@mui/material";
import ProgressWithLabel from "../Components/ProgressionBarWithLabel";
import ConfirmationDialog from "../Dialogs/ConfirmationDialog";
import getUniqueID from "../Utils/helper";
import { DIALOG_CONFIRMATION_MESSAGES } from "../Utils/languages";
import FlowCompletedView from "./Flows/FlowCompletedView";
import { COLORS_PALETTE } from "../Utils/static";
import { CheckCircleOutline } from "@mui/icons-material";
import { set } from "lodash";
import VerticalMenuButtonComponent from "Components/VerticalMenuButtonComponent";
import ReportViolationDialog from "Dialogs/ReportViolationDialog";

function QuestionView() {
  const [questions, setQuestions] = useState([]);
  const [questionIndex, setQuestionIndex] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [uniqueId, setUniqueId] = useState("");
  const [submitAnimation, setSubmitAnimation] = useState(false);
  const [currentFlowId, setCurrentFlowId] = useState(0);
  const [blueprintId, setBlueprintId] = useState(0);
  const [blueprint, setBlueprint] = useState({});
  const [showTestFlowCompletedDialog, setShowTestFlowCompletedDialog] =
    useState(false);
  const navigate = useNavigate();
  let { flowShortenKey, flowId } = useParams();
  const [answered, setAnswered] = useState([]);

  const [timeStarted, setTimeStarted] = useState(null);
  const [timeLastAnswered, setTimeLastAnswered] = useState(null);
  const [timePerQuestion, setTimePerQuestion] = useState([]);
  const [isFlowCompleted, setIsFlowCompleted] = useState(false);

  const [searchParams, setSearchParams] = useSearchParams();
  const [showQuestionAnswersSnackbar, setShowQuestionAnswersSnackbar] =
    useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [reportDialogOpen, setReportDialogOpen] = useState(false);
  useEffect(() => {
    setUniqueId(getUniqueID());
    fetchQuestion();
  }, []);
  // const flowShortenKey = searchParams.get("flowShortenKey");
  const isTestEnabled = searchParams.get("test") === "true";
  const isOwner = searchParams.get("isOwner") === "true";
  const [pendingAnswer, setPendingAnswer] = useState({
    type: "none",
    value: null,
  });
  const saveQuestionInCookie = (answerRequest) => {
    Cookies.set(`flow#${flowShortenKey}`, questionIndex + 1);
    const rawAnswerCookie = Cookies.get(`flow#${flowShortenKey}#answers`);
    var answersCookie;
    if (rawAnswerCookie) {
      answersCookie = JSON.parse(rawAnswerCookie);
    } else {
      answersCookie = [];
    }
    answersCookie.push({
      text: answerRequest.text,
      questionId: answerRequest.questionId,
    });
    Cookies.set(
      `flow#${flowShortenKey}#answers`,
      JSON.stringify(answersCookie),
      {
        expires: 1,
      }
    );
  };
  const selectAnswer = (answer) => () => {
    const currentQuestion = questions[questionIndex];

    const answerRequest = {
      questionId: questions[questionIndex].id,
      blueprintId: blueprintId,
      flowId: currentFlowId,
      text: answer,
    };
    if (
      !(
        (typeof answer === "string" && answer.length > 0) ||
        (typeof answer === "number" && answer >= 0)
      )
    ) {
      setSnackbar("Error: No answer has been selected");
      return;
    }
    if (!isTestEnabled) {
      // if answer not a list make sure it's string with lenght or a valid number:

      postAnswerforQuestion(answerRequest, uniqueId)
        .then((res) => {
          // Unfortunately, fetch doesn't send (404 error) into the cache itself
          // You have to send it, as I have done below
          if (res.status >= 400) {
            throw new Error("Question has been already answered");
          }
          return res.json();
        })
        // .then((response) => response.json())

        .then((data) => {
          // next question
          saveQuestionInCookie(answerRequest);
          setTimeSpendPerQuestion();

          if (questionIndex === 0) {
            postEvent("flowStarted", {
              shorten_url: flowShortenKey,
              uuid: uniqueId,
              flowId: currentFlowId,
            });
          }
          if (questionIndex < questions.length - 1) {
            setQuestionIndex(questionIndex + 1);
          } else {
            postEvent("flowCompleted", {
              shorten_url: flowShortenKey,
              uuid: uniqueId,
              flowId: currentFlowId,
            });
            Cookies.set(`flow#${flowShortenKey}`, questions.length);

            // alert("No more questions");
            // setQuestionIndex(0);
            setIsFlowCompleted(true);
            // navigate("/s/f/" + flowShortenKey);
          }
        })
        .catch((error) => {
          // console.log(error);
          setSnackbar(
            "Error: unable to submitting answer. please try again later"
          );
          // next question if there is any:
          if (questionIndex < questions.length - 1) {
            setQuestionIndex(questionIndex + 1);
          } else {
            setIsFlowCompleted(true);
          }
        });
    } else {
      console.log({
        answer: answerRequest,
      });
      if (questionIndex < questions.length - 1) {
        setQuestionIndex(questionIndex + 1);
      } else {
        setShowTestFlowCompletedDialog(true);
      }
    }

    setSubmitAnimation(true);
    // create delay and set 0.3 seconds then call setSubmitAnimation to False:
    setTimeout(() => {
      setSubmitAnimation(false);
      // each animation go for 0.45 s so adding 100ms buffer
    }, 1000);

    answered[questionIndex] = true;
  };

  const fetchQuestion = () => {
    if (flowId) {
      GetBlueprintByBlueprintId(flowId)
        .then((response) => response.json())
        .then((data) => {
          if (data.metadata?.questions_order?.length >= 0) {
            // sort data.questions by the order in metadata :
            const orderedQuestions = [];
            data.metadata.questions_order.forEach((qId) => {
              const q = data.questions.find((q) => q.id === qId);
              orderedQuestions.push(q);
            });
            setQuestions(orderedQuestions);
          } else {
            setQuestions(data.questions);
          }
          setAnswered(Array(data.questions.length).fill(false));
          setCurrentFlowId(0);
          setBlueprintId(data.id);
          setBlueprint(data.blueprint);
          setIsLoading(false);
          // console.log(data.questions);
        })
        .catch((error) => console.error("Error fetching question:", error));
    } else {
      GetFlowByShortenKey(flowShortenKey)
        .then((response) => response.json())
        .then((data) => {
          if (data.blueprint.metadata?.questions_order?.length >= 0) {
            // sort data.questions by the order in metadata :
            const orderedQuestions = [];
            data.blueprint.metadata.questions_order.forEach((qId) => {
              const q = data.blueprint.questions.find((q) => q.id === qId);
              orderedQuestions.push(q);
            });
            setQuestions(orderedQuestions);
          } else {
            setQuestions(data.blueprint.questions);
          }

          setCurrentFlowId(data.flow.id);
          setBlueprintId(data.blueprint.id);
          setBlueprint(data.blueprint);
          setIsLoading(false);
          // if we start we need to read the cookies and see if there is a value:
          let last_answered_question = Cookies.get(`flow#${flowShortenKey}`);
          if (!isTestEnabled && last_answered_question) {
            last_answered_question = Number(last_answered_question);
            if (last_answered_question < data.blueprint.questions.length) {
              setQuestionIndex(last_answered_question);
            } else {
              setIsFlowCompleted(true);
            }
          }
          // console.log(data.questions);
        })
        .catch((error) => console.error("Error fetching question:", error));
    }
    setTimeStarted(new Date());
  };
  const setSnackbar = (message) => {
    setSnackbarMessage(message);
    setShowQuestionAnswersSnackbar(true);
  };
  const setTimeSpendPerQuestion = () => {
    // TODO: add time spend per question to the database and show it in the stats page

    if (timePerQuestion.length === 0) {
      let now = new Date();
      const escaped = now - timeStarted;
      setTimePerQuestion([escaped]);
      setTimeLastAnswered(now);
    } else {
      let now = new Date();
      const escaped = now - timeLastAnswered;
      setTimeLastAnswered(now);
      setTimePerQuestion([...timePerQuestion, escaped]);
    }
  };
  if (isLoading) return "Loading...";
  if (showTestFlowCompletedDialog) {
    return (
      <ConfirmationDialog
        message={DIALOG_CONFIRMATION_MESSAGES.en.test_flow_completed}
        onClose={(v) => {
          if (v) {
            setShowTestFlowCompletedDialog(false);
            setQuestionIndex(0);
          } else {
            setShowTestFlowCompletedDialog(false);
            navigate(-1);
          }
        }}
        open={showTestFlowCompletedDialog}
      />
    );
  }
  if (isFlowCompleted) {
    const currentURL = window.location.href + "";
    return (
      <FlowCompletedView
        flowLink={currentURL}
        blueprintId={blueprintId}
        flowShortenKey={flowShortenKey}
        isOwner={isOwner}
        stats={{ timePerQuestion }}
        blueprintType={blueprint.type}
      />
    );
  }
  if (!questions || questions.length === 0) return "No questions available";

  return (
    <Container maxWidth="xs" className="fallInwithBounceAnimation">
      <div
        className={
          submitAnimation
            ? "container flow-view-card animate-fade-in-top"
            : "container flow-view-card"
        }
        style={{
          marginTop: "20px",
          height: "calc(100vh - 40px)",
          overflow: "auto",
          borderRadius: "24px",
          marginLeft: "8px",
          marginRight: "8px",
        }}
        // Because of the notch on the iPhone and other devices
      >
        <ProgressWithLabel
          value={((questionIndex + 1) / questions.length) * 100}
          label={`${questionIndex + 1}/${questions.length}`}
          size={80}
          className={"fallInwithBounceAnimation1"}
        />
        <VerticalMenuButtonComponent
          style={{ position: "absolute", top: "24px", right: "8px" }}
          items={[
            {
              label: "Report Violation",
              callback: () => {
                setReportDialogOpen(true);
              },
            },
          ]}
        />

        {/* {!selectedPhoto && ( */}
        <section
          className={`
          fallInwithBounceAnimation2
          question-section 
          ${pendingAnswer.type === "image" && "fadeOutFromRotation"}`}
          style={{ marginTop: 10 }}
        >
          <Typography className="question" variant="h5">
            {questions[questionIndex].text}
          </Typography>
        </section>
        {pendingAnswer.type === "image" && (
          <img
            src={
              questions[questionIndex].metadata.images[pendingAnswer["value"]]
                .url
            }
            className={`
          ${pendingAnswer.type === "image" && "fadeInFromRotation"}`}
            alt="question"
            style={{ marginTop: 10, height: "400px", borderRadius: "10px" }}
          />
        )}

        {/* )} */}

        {/* {questions[questionIndex].metadata.images && (
        <div className="question-image-container">
          <img
            className="question-image"
            src={questions[questionIndex].metadata.images[0].url}
            alt="question"
            style={{ height: "200px", borderRadius: "10px" }}
          />
          <div style={{ position: "relative" }}>
            <div style={{ position: "absolute", bottom: 0, width: "100%" }}>
              <div
                style={{
                  position: "absolute",
                  bottom: 0,
                  marginBottom: "8px",
                  // width: "100%",
                  padding: "8px",
                  color: "white",
                  fontSize: "1.5rem",
                  // make background black in bottom and transparent in top:
                  background:
                    "linear-gradient( rgba(0,0,0,0.01),rgba(0,0,0,1))",
                  borderRadius: "8px",
                }}
              >
                {questions[questionIndex].text}
              </div>
            </div>
          </div>
        </div>
      )} */}

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-end",
            height: "100%",
            width: "100%",

            padding: 0,

            alignItems: "center",
          }}
        >
          <AnswerComponent
            question={questions[questionIndex]}
            selectAnswer={selectAnswer}
            pendingAnswer={setPendingAnswer}
            snackbarSetter={setSnackbar}
            style={{ marginTop: "auto" }}
          />
        </div>
        <ReportViolationDialog
          open={reportDialogOpen}
          onClose={() => {
            setReportDialogOpen(false);
          }}
          data={{
            blueprintId: blueprint?.id,
            questionId: questions[questionIndex].id,
            flow_id: currentFlowId,
          }}
        />
        <Snackbar
          open={showQuestionAnswersSnackbar}
          autoHideDuration={3000}
          onClose={() => setShowQuestionAnswersSnackbar(false)}
          // message={snackbarMessage}
        >
          <Alert severity="error">{snackbarMessage}</Alert>
        </Snackbar>
      </div>
    </Container>
  );
}

export default QuestionView;
