import React, { useEffect, useState } from "react";
import { Box, Container, Fab, Grid, Modal } from "@mui/material";
import Zoom from "@mui/material/Zoom";
import ShareIcon from "@mui/icons-material/Share";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { COLORS_PALETTE } from "Utils/static";
import { AnimatedCard } from "Views/Landing/Slides/Second";
import SortablePhotoAnswer from "Components/SortablePhotoAnswer";

const TestView5 = () => {
  const [currentTextIndex, setCurrentTextIndex] = useState(0);

  return (
    <Container>
      <Card
        style={{
          width: 350,
          height: 350,
          borderRadius: 24,
          position: "relative",
          alignContent: "center",
          justifyContent: "center",
          display: "flex",
        }}
      >
        <AnimatedCard2
          strings={[
            "Rank my fall photoshoots from best to worst!",
            "If you life can be one Emoji which would it would be? ",
            "Would you rather be a famous singer or a famous actor?",
            "Rank the following in order of importance: Money, Love, Career, Family, Friends",
            "What is your favorite season ?",
          ]}
        />
      </Card>
    </Container>
  );
};

export const AnimatedCard2 = ({ strings, changeInterval }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const rotations = strings.map(
    (str, index) => (index % 2 ? 1 : -1) * (Math.random() * 3 + 3)
  );
  useEffect(() => {
    // create rotation for each string: ( % 2 ? 1 : -1) * (Math.random() * 3 + 1)

    const interval = setInterval(
      () => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % strings.length);
      },
      changeInterval ? changeInterval : 3000
    );

    return () => clearInterval(interval);
  }, [strings]);

  return (
    <>
      <Container
        sx={{ display: "flex", justifyContent: "center", marginBottom: 20 }}
      >
        {strings.map((str, index) => (
          <Card
            key={index}
            style={{
              width: "70%",
              maxWidth: "500px",
              padding: "16px",
              height: "400px",

              position: "absolute",
              transition: "transform 0.5s",
              rotate: `${rotations[index]}deg`,
              transform: `
              translateZ(${currentIndex === index ? 0 : -50}px)
              translateY(${
                currentIndex === index ? 0 : index % 2 === 0 ? -10 : 10
              }px)
              scale(${currentIndex === index ? 1.1 : 1})
              rotate(${currentIndex !== index ? 0 : -rotations[index]}deg)
            `,
              zIndex: currentIndex === index ? 1 : 0,
              opacity: currentIndex === index ? 1 : 0.8, // add opacity based on currentIndex
              // backgroundColor:
              // currentIndex === index ? "white" : "rgba(0, 0, 0, 0.5)", // darken the card based on currentIndex
            }}
          >
            <section className="question-section" style={{ height: "100px" }}>
              <Typography
                variant="body"
                style={{
                  textAlign: "center",
                  color: COLORS_PALETTE.primary,
                  fontWeight: "bold",
                  fontSize: "0.7rem",
                }}
              >
                {str}
              </Typography>
              <div className="answer-section">
                <SortablePhotoAnswer
                  items={[
                    {
                      url: "https://images.pexels.com/photos/268533/pexels-photo-268533.jpeg?cs=srgb&dl=pexels-pixabay-268533.jpg&fm=jpg",
                    },
                    {
                      url: "https://images.pexels.com/photos/268533/pexels-photo-268533.jpeg?cs=srgb&dl=pexels-pixabay-268533.jpg&fm=jpg",
                    },
                    {
                      url: "https://images.pexels.com/photos/268533/pexels-photo-268533.jpeg?cs=srgb&dl=pexels-pixabay-268533.jpg&fm=jpg",
                    },
                  ]}
                  // answerCallback={setSortablePhotosAnswer}
                  // selectedPhoto={pendingAnswer}
                />
              </div>
            </section>
          </Card>
        ))}
      </Container>
    </>
  );
};
export default TestView5;
