import { UserContext } from "Providers/UserContext";
import AuthCardContainer from "UI/AuthCardContainer";
import PurplePinkButton from "UI/PurplePinkButton";
import { GetUserProfile } from "Utils/fetchers";
import Cookies from "js-cookie";
import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { LoginSocialGoogle } from "reactjs-social-login";
import GoogleIcon from "@mui/icons-material/Google";
import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Snackbar from "@mui/material/Snackbar";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/system";
import { API_URL, REDIRECT_URL } from "../../Config";
// import { LogoutButton, LoginButton } from "./LoginView2";

const data = {
  email: "",
  password: "",
};

const GOOGLE_CLIENT_ID =
  "629235666420-d4i3k0cv3kfjte2fdrrqdacb4nchsbva.apps.googleusercontent.com";

const LoginView = () => {
  const { setUserProfile } = useContext(UserContext);
  const [showLoginSnackbar, setShowLoginSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const loggedWithProvider = function (provider, data) {
    return fetch(API_URL + `/auth/${provider}/callback`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        provider: provider,
        token:
          data["access_token"] || // data["access_token"] is for Google
          data["accessToken"], // data["accessToken"] is for Facebook
        data: data,
      }),
    }).then((response) => {
      if (response.status === 200) {
        return response.json().then((data) => {
          localStorage.setItem("vqa_token", data.token);
          localStorage.setItem("vqa_expiration", data.expire);
          // setIsLoggedIn(true);
          getUserProfileAndNavigateToHome();
        });
      }
      return response.json().then((error) => {
        // setLoginError(error);
        console.log(error);
      });
    });
  };

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");

  const navigate = useNavigate();

  const getUserProfileAndNavigateToHome = () => {
    GetUserProfile().then((data) => {
      data.json().then((data) => {
        setUserProfile(data.user);
        // check if redirect url is set in cookie:
        const redirectUrl = Cookies.get("wk_redirect");
        if (redirectUrl) {
          Cookies.remove("wk_redirect");
          navigate(redirectUrl);
        } else {
          navigate("/home");
        }
      });
    });
  };

  const handleSubmit = (event) => {
    if (event) event.preventDefault();
    data.email = email;
    data.password = password;
    return fetch(API_URL + "/users/login", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ user: data }),
    }).then((response) => {
      if (response.status === 200) {
        return response.json().then((data) => {
          localStorage.setItem("vqa_token", data.token);
          localStorage.setItem("vqa_expiration", data.expire);
          getUserProfileAndNavigateToHome();
        });
      }
      if (response.status === 401) {
        setSnackbarMessage("Invalid username or password");
        setShowLoginSnackbar(true);
      }
      if (response.status > 401) {
        setSnackbarMessage("Unable to Login.. try again later");
        setShowLoginSnackbar(true);
      }
      return response.json().then((error) => {
        // setLoginError(error);
        // throw new Error(error.message);
        console.log(error);
      });
    });
  };
  const handleUsernameChange = (e) => {
    setUsername(e.target.value);
    setEmail(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  return (
    <AuthCardContainer>
      <form component="form" onSubmit={handleSubmit}>
        <Box
          sx={{
            display: "grid",
            gap: 2,
          }}
        >
          <TextField
            label="Email"
            variant="outlined"
            fullWidth
            value={username}
            onChange={handleUsernameChange}
          />
          <TextField
            label="Password"
            type="password"
            variant="outlined"
            fullWidth
            value={password}
            onChange={handlePasswordChange}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                handleSubmit();
              }
            }}
          />
          <PurplePinkButton
            fullWidth
            size="large"
            sx={{
              height: "56px",
            }}
            onClick={handleSubmit}
          >
            Login
          </PurplePinkButton>
          <Typography
            variant="body1"
            sx={{ textAlign: "center", color: "#6b6b6b", paddingTop: 1 }}
          >
            Forgot your password? <a href="password/reset">Reset it</a>
          </Typography>
          <Divider style={{ color: "#6b6b6b", opacity: 0.7 }}>or</Divider>
          <LoginSocialGoogle
            client_id={process.env.REACT_APP_GG_APP_ID || GOOGLE_CLIENT_ID}
            redirect_uri={REDIRECT_URL}
            scope="openid profile email"
            discoveryDocs="claims_supported"
            access_type="offline"
            isOnlyGetToken={true}
            onResolve={({ provider, data }) => {
              loggedWithProvider(provider, data);
            }}
            onReject={(err) => {
              console.log(err);
            }}
          >
            <Button
              variant="outlined"
              color="info"
              fullWidth
              size="large"
              sx={{ height: "56px" }}
              startIcon={<GoogleIcon />}
            >
              Continue with Google
            </Button>
          </LoginSocialGoogle>

          {/* <Divider /> */}
          <Typography
            variant="body1"
            sx={{ textAlign: "center", color: "#6b6b6b", paddingTop: 1 }}
          >
            Don't have an account?
          </Typography>
          <Button
            variant="text"
            // color="success"
            fullWidth
            size="large"
            type="submit"
            sx={{ height: "56px", textDecoration: "underline" }}
            href="/signup"
          >
            Create new account
          </Button>
        </Box>
      </form>

      {/* <LoginSocialFacebook
            isOnlyGetToken
            onLoginStart={onLoginStart}
            appId="320660320404058"
            onResolve={({ provider, data }) => {
              loggedWithProvider(provider, data);

              setProvider(provider);
              setProfile(data);
            }}
            onReject={(err) => {
              console.log(err);
            }}
          >
            <Button
              variant="contained"
              color="info"
              fullWidth
              size="large"
              style={{ marginTop: "10px" }}
              startIcon={<FacebookIcon />}
            >
              Login with Facebook
            </Button>
          </LoginSocialFacebook> */}
      {/* <LoginSocialInstagram
            client_id="320660320404058"
            client_secret="085deb0713c1789d0a3fb59f9952a0e9"
            redirect_uri="https://wanaknow.link"
          >
            <Button
              variant="contained"
              fullWidth
              size="large"
              style={{ marginTop: "10px", backgroundColor: "#690ee0" }}
              startIcon={<InstagramIcon />}
            >
              Pending Instagram
            </Button>
          </LoginSocialInstagram> */}
      <Snackbar
        open={showLoginSnackbar}
        autoHideDuration={5000}
        onClose={() => setShowLoginSnackbar(false)}
      >
        <Alert severity="error">{snackbarMessage}</Alert>
      </Snackbar>
    </AuthCardContainer>
  );
};

export default LoginView;
