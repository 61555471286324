// generate component that take country input and return stats

import {
  Collapse,
  Grid,
  LinearProgress,
  ListItemText,
  Typography,
} from "@mui/material";
import { round } from "lodash";
import { useState } from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
function CountryStatsComponent({ eventGeos }) {
  const [expandedCountry, setExpandedCountry] = useState(null);
  const sortedCountries = Object.keys(eventGeos["countries"]).sort(
    (a, b) => eventGeos["countries"][b]["*"] - eventGeos["countries"][a]["*"]
  );

  const getFlagEmoji = (countryCode) =>
    String.fromCodePoint(
      ...[...countryCode.toUpperCase()].map((x) => 0x1f1a5 + x.charCodeAt())
    );
  return (
    <Grid container spacing={1} sx={{ marginTop: "-8px" }}>
      {sortedCountries.map((country, index) => (
        <Grid item xs={12} key={index}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              cursor: "pointer",
              backgroundColor: expandedCountry === country ? "#efeefc" : "",
              borderRadius: "10px",
              paddingLeft: 5,
              paddingRight: 5,
            }}
            onClick={() => {
              setExpandedCountry(expandedCountry === country ? null : country);
            }}
          >
            <span style={{ marginRight: "8px", fontSize: "1.5em" }}>
              {getFlagEmoji(country)}
            </span>
            <ListItemText
              // if we don't have country name, display "--"
              primary={country ? country : "--"}
              style={{ margin: 0, padding: 0 }}
            />
            <LinearProgress
              variant="determinate"
              value={round(
                (100 * eventGeos["countries"][country]["*"]) /
                  eventGeos["start_flow_count"],
                0
              )}
              style={{
                width: "50%",
                marginLeft: "10px",
                height: 10,
                borderRadius: 5,
              }}
            />
            <Typography variant="caption" sx={{ marginLeft: "4px" }}>
              {round(
                (100 * eventGeos["countries"][country]["*"]) /
                  eventGeos["start_flow_count"],
                0
              ) + "%"}
            </Typography>
            {expandedCountry === country ? (
              <KeyboardArrowUpIcon />
            ) : (
              <KeyboardArrowDownIcon />
            )}
          </div>
          <Collapse in={expandedCountry === country}>
            {Object.entries(eventGeos["countries"][country]).map(
              ([city, percentage], cityIndex) =>
                city !== "*" &&
                (cityIndex < 5 ? (
                  <div
                    key={cityIndex}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginLeft: "40px",
                      marginTop: "4px",
                    }}
                  >
                    <span
                      style={{
                        marginRight: "8px",
                        fontSize: "1.5em",
                        visibility: "hidden",
                      }}
                    >
                      {/* Hidden flag */}
                      {getFlagEmoji(country)}
                    </span>
                    <ListItemText
                      primary={city}
                      style={{ margin: 0, padding: 0 }}
                    />
                    <LinearProgress
                      variant="determinate"
                      value={round(
                        (100 * percentage) /
                          eventGeos["countries"][country]["*"],
                        0
                      )}
                      style={{
                        width: "50%",
                        marginLeft: "10px",
                        height: 10,
                        borderRadius: 5,
                      }}
                    />
                    <Typography variant="caption" sx={{ marginLeft: "4px" }}>
                      {round(
                        (100 * percentage) /
                          eventGeos["countries"][country]["*"],
                        0
                      ) + "%"}
                    </Typography>
                  </div>
                ) : cityIndex === 5 ? (
                  <div
                    key={cityIndex}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginLeft: "40px",
                      marginTop: "4px",
                    }}
                  >
                    {/* Display total number of other cities */}
                    <ListItemText
                      primary={`+${
                        Object.keys(eventGeos["countries"][country]).length - 5
                      } others`}
                      style={{ margin: 0, padding: 0 }}
                    />
                  </div>
                ) : null)
            )}
          </Collapse>
        </Grid>
      ))}
    </Grid>
  );
}

export default CountryStatsComponent;
