import React, { useEffect, useState } from "react";
import SortableAnswer from "../Components/SortableAnswer";
import SortablePhotoAnswer from "../Components/SortablePhotoAnswer";
// import { set } from "lodash";
import CircleSwiper from "../Components/YesNoAnswer";
import RangeComponent from "../Components/RangeComponent";
// import { useDrag } from "react-use-gesture";

import {
  // Container,
  FormHelperText,
  Grid,
  // Input,
  // Paper,
  // Slider,
  // SliderThumb,
  TextField,
} from "@mui/material";
// import { type } from "@testing-library/user-event/dist/type";
import { COLORS_PALETTE, EMOJI_ARRAY } from "../Utils/static";
import MultiChoicePhotoAnswer from "../Components/MultichoicePhotoAnswer";
const ONE_EMOJI_REGEX =
  /^(\u00a9|\u00ae|[\u2000-\u3300]|\ud83c[\ud000-\udfff]|\ud83d[\ud000-\udfff]|\ud83e[\ud000-\udfff]|\u2764\uFE0F)$/;
// /^[\x{1F600}-\x{1F64F}\x{1F300}-\x{1F5FF}\x{1F680}-\x{1F6FF}\x{1F700}-\x{1F77F}\x{1F780}-\x{1F7FF}\x{1F800}-\x{1F8FF}\x{1F900}-\x{1F9FF}\x{1FA00}-\x{1FA6F}\x{1FA70}-\x{1FAFF}\x{1F004}-\x{1F0CF}\x{1F170}-\x{1F251}]$/;
function AnswerComponent({ question, selectAnswer, pendingAnswer }) {
  const [answer, setAnswer] = useState([]);
  const [selectedAnswers, setSelectedAnswes] = useState([]); // for yes/no question
  const [fieldError, setFieldError] = useState(null);
  useEffect(() => {
    if (question?.metadata?.images) {
      question.metadata.rawImages = question?.metadata?.images.map(
        (image) => image.url
      );
    }
  }, [question]);
  const setSortableAnswer = function (answerIndexArray) {
    setAnswer(answerIndexToChoices(answerIndexArray));
  };
  const setSortablePhotosAnswer = function (answerIndexArray) {
    setAnswer(answerIndexArray.join(","));
  };
  const setMultiplePhotosAnswer = function (mutliphotoAnswer) {
    // check if array or number:
    if (Array.isArray(mutliphotoAnswer)) {
      setAnswer(mutliphotoAnswer.join(","));
    } else {
      setAnswer(String(mutliphotoAnswer));
    }
  };
  const setEmojiAnswer = function (answer) {
    if (ONE_EMOJI_REGEX.test(answer)) {
      setAnswer(answer);
      answer = null;
      setFieldError(null);
    } else {
      setFieldError("Please enter only one emoji");
    }
  };

  const setYesNoAnswer = function (answer) {
    selectAnswer(answer)();
    answer = null;

    // setAnswer(null);
  };

  const setOneOfManyAnswer = function (answer) {
    selectAnswer(answer)();
    answer = null;
  };
  const setManyOfManyAnswer = function (answer) {
    selectAnswer(answer)();
    answer = null;
  };
  const setRangeAnswer = function (answer) {
    setAnswer(String(answer));
    answer = null;
  };
  const setTextAnswer = function (answer) {
    setAnswer(String(answer));
    answer = null;
  };
  const setOneWord = function (answer) {
    //remove all white space and numbers
    answer = answer.replace(/\s/g, "");
    answer = answer.replace(/[0-9]/g, "");
    //convert to lower case
    answer = answer.toLowerCase();
    if (answer.length <= 10) {
      setAnswer(String(answer));
      // answer = null;
    } else {
      setFieldError("Please enter only one word");
    }
    console.log(answer);
  };

  const answerIndexToChoices = (order) => {
    const answer = order.map((index) => {
      return question.metadata.choices[index];
    });
    return answer;
  };
  if (
    question.metadata?.images?.length > 0 &&
    question.type === "one_of_many"
  ) {
    return (
      <div className="answer-section">
        <MultiChoicePhotoAnswer
          items={question.metadata.images}
          answerCallback={setMultiplePhotosAnswer}
          selectedPhoto={pendingAnswer}
          multiple={false}
        />
        <button
          className="submit-btn"
          onClick={() => {
            selectAnswer(answer)();
            setAnswer(null);
            pendingAnswer({ type: null, value: null });
          }}
        >
          Send
        </button>
      </div>
    );
  } else if (
    question.metadata?.images?.length > 0 &&
    question.type === "many_of_many"
  ) {
    return (
      <div className="answer-section">
        <MultiChoicePhotoAnswer
          items={question.metadata.images}
          answerCallback={setMultiplePhotosAnswer}
          selectedPhoto={pendingAnswer}
          multiple={true}
        />
        <button
          className="submit-btn"
          onClick={() => {
            selectAnswer(answer)();
            setAnswer(null);
            pendingAnswer({ type: null, value: null });
          }}
        >
          Send
        </button>
      </div>
    );
  } else if (
    question.metadata?.images?.length > 0 &&
    question.type === "sortable"
  ) {
    return (
      <div className="answer-section">
        <SortablePhotoAnswer
          items={question.metadata.images}
          answerCallback={setSortablePhotosAnswer}
          selectedPhoto={pendingAnswer}
        />

        <button
          className="submit-btn"
          onClick={() => {
            selectAnswer(answer)();
            setAnswer(null);
            pendingAnswer({ type: null, value: null });
          }}
        >
          Send
        </button>
      </div>
    );
  } else if (question.type === "one_of_many") {
    return (
      <section className="answer-section">
        {question?.metadata?.choices.map((answer, index) => (
          <label
            key={index}
            onClick={() => setOneOfManyAnswer(answer)}
            className="option"
          >
            {answer}
          </label>
        ))}
      </section>
    );
  } else if (question.type === "many_of_many") {
    return (
      <div className="answer-section">
        <section className="answer-section">
          {question?.metadata?.choices.map((answer, index) => (
            <label
              key={index}
              style={{
                backgroundColor: selectedAnswers.includes(index)
                  ? COLORS_PALETTE.paletteMatching5
                  : null,
              }}
              onClick={() => {
                if (!selectedAnswers.includes(index)) {
                  setSelectedAnswes([...selectedAnswers, index]);
                } else {
                  setSelectedAnswes(
                    selectedAnswers.filter((item) => item !== index)
                  );
                }
              }}
              className="option"
            >
              {answer}
            </label>
          ))}
        </section>

        <button
          className="submit-btn"
          onClick={() => {
            setManyOfManyAnswer(selectedAnswers.join(","));
          }}
        >
          Send
        </button>
      </div>
    );
  } else if (question.type === "yes_no") {
    return (
      <div class="fallInwithBounceAnimation">
        <div
          style={{
            display: "flex",
            alignItems: "center",
            height: "100%",
            width: "200px",
            justifyContent: "center",
          }}
        >
          <div
            onClick={() => {
              setYesNoAnswer("No");
            }}
          >
            No
          </div>
          <CircleSwiper answerCallback={setYesNoAnswer}>
            <svg height="100" width="100">
              <circle
                cx="50"
                cy="50"
                r="40"
                // stroke="black"
                // stroke-width="1"
                fill="var(--primary-color)"
              />
            </svg>
          </CircleSwiper>
          <div
            onClick={() => {
              setYesNoAnswer("Yes");
            }}
          >
            Yes
          </div>
        </div>
        <div>
          {/* <button
            className="btn btn-primary"
            onClick={setYesNoAnswer()}
          >
            Send✅
          </button> */}
        </div>
      </div>
    );
  } else if (question.type === "sortable") {
    // setAnswer(question?.metadata?.choices.join(","));

    return (
      <div className="answer-section">
        <div style={{ width: "100%" }}>
          <SortableAnswer
            items={question?.metadata?.choices}
            answerCallback={setSortableAnswer}
          />
        </div>
        <div style={{}}>
          <button
            className="submit-btn"
            onClick={() => {
              selectAnswer(
                answer
                  ? answer.join(",")
                  : question?.metadata?.choices?.join(",")
              )();
              setAnswer(null);
            }}
          >
            Send
          </button>
        </div>
      </div>
    );
  } else if (question.type === "one_word") {
    return (
      <div className="answer-section">
        <TextField
          id="standard-basic"
          label="Type your answer here"
          className="text-input"
          onChange={(e) => {
            setOneWord(e.target.value);
          }}
          value={answer}
        />
        <FormHelperText>{fieldError}</FormHelperText>
        <button
          className="submit-btn"
          onClick={() => {
            if (answer && answer.length > 0) {
              selectAnswer(answer)();
              setAnswer("");
            }
          }}
        >
          Send
        </button>
        <br />
      </div>
    );
  } else if (question.type === "text") {
    return (
      <div className="answer-section">
        <TextField
          id="standard-basic"
          label="Type your answer here"
          className="text-input"
          onChange={(e) => {
            setTextAnswer(e.target.value);
          }}
          value={answer}
        />
        <button
          className="submit-btn"
          onClick={() => {
            if (answer && answer.length > 0) {
              selectAnswer(answer)();
              setAnswer("");
            }
          }}
        >
          Send
        </button>
        <br />
      </div>
    );
  } else if (question.type === "emoji") {
    return (
      <div className="answer-section">
        {/* display the selected emoji */}
        <div
          style={{
            fontSize: "1.75em",
            cursor: "pointer",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "60px",
            width: "60px",
            borderRadius: "50%",
            border: "3px dashed var(--primary-color) ",
          }}
        >
          {answer}
        </div>
        {/* provide few emojis to select from : */}
        <Grid
          container
          spacing={0}
          style={{
            height: 150,
            overflowY: "scroll",
            overflowX: "clip",
            marginTop: "5px",
            margin: "5px",
          }}
        >
          {EMOJI_ARRAY.map((emoji, index) => (
            <Grid item key={index} xs={2}>
              <div
                style={{
                  textAlign: "center",
                  padding: "5px",
                  cursor: "pointer",
                  fontSize: "1.5em",
                }}
                onClick={() => {
                  setEmojiAnswer(emoji);
                }}
              >
                {emoji}
              </div>
            </Grid>
          ))}
        </Grid>

        <button
          className="submit-btn"
          onClick={() => {
            if (answer && answer.length > 0) {
              selectAnswer(answer)();
              setAnswer("");
            }
          }}
        >
          Send
        </button>
        <br />
      </div>
    );
  }
  // setAnswer(0);

  return (
    // slider
    <section className="answer-section">
      <RangeComponent
        min={
          question.metadata?.choices[0]
            ? Number(question.metadata?.choices[0])
            : 0
        }
        max={
          question.metadata?.choices[1]
            ? Number(question.metadata?.choices[1])
            : 100
        }
        value={question.answer}
        onChange={(value) => {
          setRangeAnswer(value);
        }}
      />
      {/* <Slider
        aria-label="Default"
        valueLabelDisplay="auto"
        onChange={(value) => {
          setRangeAnswer(value);
        }}
        value={answer}
        min={
          question.metadata?.choices[0]
            ? Number(question.metadata?.choices[0])
            : 0
        }
        max={
          question.metadata?.choices[1]
            ? Number(question.metadata?.choices[1])
            : 100
        }
      /> */}
      <br />
      <button
        className="submit-btn"
        onClick={() => {
          selectAnswer(answer)();
          setAnswer(null);
        }}
      >
        Send
      </button>
    </section>
  );
}

export default AnswerComponent;
