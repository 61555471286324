import React, { useEffect } from "react";
import {
  Card,
  CardContent,
  Typography,
  Grid,
  Snackbar,
  ButtonGroup,
  Container,
} from "@mui/material";
import ShareIcon from "@mui/icons-material/Share";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import {
  getRecommendedFlows,
  postCloneFlowByFlowId,
} from "../../Utils/fetchers";
import { useNavigate } from "react-router-dom";
import { copyToClipboard } from "../../Utils/helper";

import AdsComponent from "../../Components/AdsComponent.js";
import {
  COLORS_PALETTE,
  PAPER_CARD_STYLE_2,
  PAPER_CARD_STYLE_5,
} from "../../Utils/static";
import WannaKnowLogo from "../../Static/logo_current.png";
import { Visibility } from "@mui/icons-material";
import { Button } from "Components/CustomHTMLComponent";

const FlowCompletedView = ({
  flowLink,
  blueprintId,
  recommendedFlow,
  flowShortenKey,
  stats,
  isOwner,
  blueprintType,
}) => {
  const navigate = useNavigate();
  const [showCopiedSnackbar, setShowCopiedSnackbar] = React.useState(false);
  const [recommendedFlows, setRecommendedFlows] = React.useState([]);
  useEffect(() => {
    fetchRecommendedFlows();
  }, []);
  const handleCloneFlow = () => {
    postCloneFlowByFlowId(blueprintId).then((res) => {
      navigate("/flows");
    });
  };
  const handleViewStats = () => {
    if (isOwner === true) {
      // s/f/1udxSzPkYC2N9n1BX5QK4ObA4RN5l098/c
      navigate("/results/f/" + flowShortenKey + "?isOwner=" + isOwner);
    } else {
      navigate("/results/f/" + flowShortenKey + "");
    }
  };

  const fetchRecommendedFlows = () => {
    getRecommendedFlows()
      .then((res) => res.json())
      .then((data) => {
        if (data && data.length > 0) {
          setRecommendedFlows(data);
        }
      })
      .catch((err) => console.log(err));
  };

  return (
    <Container maxWidth="xs" style={{ marginTop: "8px" }}>
      <div style={{ marginTop: 10, marginLeft: "1rem", marginRight: "1rem" }}>
        <Grid container spacing={2}>
          <Grid item xs={12} className="appear-with-flip">
            <Card
              elevation={3}
              sx={{
                padding: "48px 24px 24px",
                borderRadius: "24px",
                justifyContent: "center",
                display: "flex",
              }}
            >
              <CardContent>
                <div style={{ marginBottom: 32 }}>
                  <img
                    src={WannaKnowLogo}
                    style={{
                      display: "block",
                      margin: "0 auto",
                      width: "50%",
                      maxWidth: "200px",
                      marginBottom: 24,
                    }}
                    alt="WannaKnow Logo"
                    className="rotate-360-degree"
                  />
                  {blueprintType === 2 ? (
                    <Typography variant="h5" textAlign={"center"} gutterBottom>
                      Challenge Completed!
                    </Typography>
                  ) : (
                    <Typography variant="h5" textAlign={"center"} gutterBottom>
                      Completed!
                    </Typography>
                  )}
                </div>

                {blueprintType === 2 ? (
                  <>
                    <Typography variant="body1" gutterBottom>
                      You can copy the challenge, take it and share it with you
                      using the<b> Copy</b>
                    </Typography>
                    <Typography gutterBottom style={{ marginBottom: 24 }}>
                      or click
                      <b> Result </b>
                      to see your answers and the results of the challenge!
                    </Typography>
                  </>
                ) : (
                  <>
                    <Typography variant="body1" gutterBottom>
                      You can now share this with others. using the{" "}
                      <b> Share</b>
                    </Typography>
                    <Typography gutterBottom style={{ marginBottom: 24 }}>
                      or if you are logged in you can also click
                      <b> Clone </b>
                      and clone it to share it with your friends / followers!
                    </Typography>
                  </>
                )}
                <Grid container spacing={0}>
                  {!isOwner && (
                    <Grid item xs={6}>
                      <Button
                        variant="contained"
                        color="primary"
                        startIcon={<FileCopyIcon />}
                        onClick={handleCloneFlow}
                        size="large"
                      >
                        Copy
                      </Button>
                    </Grid>
                  )}
                  {isOwner && blueprintType === 2 && (
                    <Grid item xs={6}>
                      <Button
                        variant="outlined"
                        startIcon={<ShareIcon />}
                        onClick={() => {
                          // remove query params:
                          const url = flowLink.split("?")[0];
                          copyToClipboard(url);
                          setShowCopiedSnackbar(true);
                        }}
                        size="large"
                      >
                        Share
                      </Button>
                    </Grid>
                  )}

                  {blueprintType === 2 ? (
                    <Grid item xs={6}>
                      <Button
                        variant="contained"
                        color="primary"
                        startIcon={<FileCopyIcon />}
                        onClick={handleViewStats}
                        size="large"
                      >
                        Results
                      </Button>
                    </Grid>
                  ) : (
                    <Grid item xs={6}>
                      <Button
                        variant="outlined"
                        startIcon={<ShareIcon />}
                        onClick={() => {
                          copyToClipboard(flowLink);
                          setShowCopiedSnackbar(true);
                        }}
                        size="large"
                      >
                        Share
                      </Button>
                    </Grid>
                  )}
                </Grid>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h6">Discover Challenges:</Typography>
            {recommendedFlows?.map((flow) => {
              return (
                <Grid
                  item
                  xs={12}
                  style={{
                    marginTop: "8px",
                    paddingLeft: 0,
                    paddingRight: 0,
                    paddingBottom: 5,
                  }}
                >
                  <Card
                    style={PAPER_CARD_STYLE_5}
                    variant="elevation"
                    elevation={4}
                  >
                    <CardContent>
                      <Typography
                        variant="h8"
                        style={{ color: COLORS_PALETTE.paletteText5 }}
                      >
                        {flow.description}
                      </Typography>
                      {/* <TrendingUpIcon /> */}

                      <div
                        style={{
                          marginTop: "1rem",
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-start",
                          }}
                        >
                          {/* <Button
                          style={{
                            backgroundColor: "var(--palette-color-0)",
                            borderRadius: "20px",
                            color: "var(--palette-text-1)",
                          }}
                          size="large"
                          startIcon={<CopyAllIcon />}
                          onClick={() => {
                            postFlowUserByFlowId(flow.blueprint_id).then(
                              (response) => {
                                response.json().then((data) => {
                                  if (response.status === 200) {
                                    console.log("response.data", data);
                                    navigate(
                                      "/f/" + data.shorten_url + "?isOwner=true"
                                    );

                                    navigate("/flows");
                                  }
                                });
                              }
                            );
                          }}
                        >
                          Clone
                        </Button> */}
                        </div>
                      </div>
                    </CardContent>
                    <ButtonGroup
                      variant="text"
                      aria-label="large button group"
                      disableElevation
                      fullWidth
                      style={{ bottom: 0 }}
                      ar
                    >
                      <Button
                        variant="outlined"
                        color="primary"
                        style={{
                          backgroundColor: COLORS_PALETTE.primaryColor,
                          color: COLORS_PALETTE.paletteText1,
                          margin: 8,
                          marginLeft: 16,
                          marginRight: 16,
                          padding: 8,
                        }}
                        disableElevation
                        size="large"
                        startIcon={<Visibility />}
                        // style={BUTTON_CARD_STYLE_1}
                        onClick={() => {
                          navigate("/preview/" + flow.shorten_url);
                        }}
                        fullWidth
                      >
                        View
                      </Button>
                    </ButtonGroup>
                  </Card>
                </Grid>
              );
            })}
          </Grid>
        </Grid>
        <AdsComponent />
        <Snackbar
          open={showCopiedSnackbar}
          autoHideDuration={5000}
          onClose={() => setShowCopiedSnackbar(false)}
          message={
            (blueprintType === 2 ? "Challenge link" : "Link") +
            " copied to clipboard"
          }
        />
      </div>
    </Container>
  );
};

export default FlowCompletedView;
