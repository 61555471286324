const { Typography, Container } = require("@mui/material");
const { Link } = require("react-router-dom");

const Footer = () => {
  return (
    <footer style={{ background: "#F4EFF4" }}>
      <Container style={{ display: "flex", justifyContent: "space-between" }}>
        <div style={{ textAlign: "left" }}>
          <Typography variant="body2" color="textSecondary">
            &copy; {new Date().getFullYear()} MNHT LLC. All Rights Reserved. dba{" "}
            <Link
              href="https://www.wannaknow.link"
              target="_blank"
              rel="noopener noreferrer"
            >
              Wanna Know
            </Link>
          </Typography>
        </div>
        <Typography
          variant="body2"
          color="textSecondary"
          style={{ textAlign: "right" }}
        >
          V{process.env.REACT_APP_BUILD_VERSION}
        </Typography>
      </Container>
    </footer>
  );
};

export default Footer;
