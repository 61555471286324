import React from "react";

const TermsView = () => {
  return (
    <div style={{ color: "black", background: "#fff" }}>
      <h2>Terms of Use</h2>
      <p>Effective Date: August 23rd, 2023</p>
      <p>
        Welcome to Wannaknow ("we", "us", or "our"). By accessing or using our
        website wannaknow.link (the "Website"), you agree to abide by these
        Terms of Use. Please read these terms carefully before using our
        Website.
      </p>

      <h3>Acceptance of Terms</h3>
      <p>
        By accessing and using the Website, you agree to be bound by these Terms
        of Use and all applicable laws and regulations.
      </p>

      <h3>Intellectual Property</h3>
      <p>
        The content, features, and functionality of the Website are owned by
        Wannaknow and are protected by intellectual property laws.
      </p>

      <h3>Prohibited Activities</h3>
      <p>
        You agree not to engage in any unauthorized use of the Website,
        including but not limited to hacking, transmitting viruses, or violating
        applicable laws.
      </p>

      <h3>Disclaimer</h3>
      <p>
        The Website is provided "as is" without warranties of any kind, either
        express or implied, including, but not limited to, implied warranties of
        merchantability or fitness for a particular purpose.
      </p>

      <h3>Limitation of Liability</h3>
      <p>
        Wannaknow shall not be liable for any direct, indirect, incidental,
        special, or consequential damages arising out of the use or inability to
        use the Website.
      </p>

      <h3>Indemnification</h3>
      <p>
        You agree to indemnify and hold Wannaknow harmless from any claims,
        losses, liabilities, and expenses arising from your use of the Website.
      </p>

      <h3>Changes to Terms</h3>
      <p>
        We reserve the right to update or modify these Terms of Use at any time
        without notice. Your continued use of the Website after changes are
        posted constitutes your acceptance of the modified terms.
      </p>

      <h3>Contact Us</h3>
      <p>
        If you have any questions about these Terms of Use, please contact us at
        Wannaknow.
      </p>
    </div>
  );
};

export default TermsView;
