import React, { useEffect, useRef, useState } from "react";
import { useSprings, animated } from "@react-spring/web";
import { useDrag } from "react-use-gesture";
import { clamp } from "lodash";
import swap from "lodash-move";
import { CheckCircleOutline } from "@mui/icons-material";
import { COLORS_PALETTE } from "../Utils/static";

const fn =
  (itemSize) =>
  (order, active = false, originalIndex = 0, curIndex = 0, x = 0) =>
  (index) =>
    active && index === originalIndex
      ? {
          x: curIndex * itemSize + x,
          scale: 1.1,
          zIndex: 1,
          shadow: 15,
          immediate: (key) => key === "x" || key === "zIndex",
        }
      : {
          x: order.indexOf(index) * itemSize,
          scale: 1,
          zIndex: 0,
          shadow: 1,
          immediate: false,
        };

function SortablePhotoAnswer({ items, answerCallback, selectedPhoto }) {
  // console.log("items", items);
  // calculate the maxi

  // const itemSize = Math.min(100, (window.innerWidth * 0.7) / items.length); // Adjust as needed
  const itemSize = 60; // Adjust as needed

  const [currentOrder, setCurrentOrder] = useState(items);
  const order = useRef(items.map((_, index) => index));

  const [springs, api] = useSprings(items.length, fn(itemSize)(order.current));

  const bind = useDrag(({ args: [originalIndex], active, movement: [x] }) => {
    const curIndex = order.current.indexOf(originalIndex);
    const curCol = clamp(
      Math.round((curIndex * itemSize + x) / itemSize),
      0,
      items.length - 1
    );
    const newOrder = swap(order.current, curIndex, curCol);

    api.start(fn(itemSize)(newOrder, active, originalIndex, curIndex, x));

    if (!active) {
      selectedPhoto({ type: "image", value: originalIndex });
      order.current = newOrder;
      setCurrentOrder(order.current);
      if (order.current) answerCallback(order.current);
    }
  });

  useEffect(() => {
    order.current = items.map((_, index) => index);
    api.start(fn(itemSize)(order.current));
    // initialze the currentl order
    setCurrentOrder(order.current);
  }, [items, api, itemSize]);
  if (!items) return null;
  return (
    <div style={{ width: itemSize * items.length, height: itemSize }}>
      {springs.map(({ zIndex, shadow, x, scale }, i) => (
        <animated.div
          {...bind(i)}
          key={i}
          style={{
            alignContent: "center",
            justifyContent: "center",
            position: "absolute",
            width: `${itemSize}px`,
            height: `${itemSize}px`,
            // transformOrigin: "50% 50% 0px",
            borderRadius: "50%",
            color: "white",
            // lineHeight: `${itemSize / 2}px`,
            textTransform: "uppercase",
            letterSpacing: "2px",
            touchAction: "none",

            zIndex,
            boxShadow: shadow.to(
              (s) => `rgba(0, 0, 0, 0.15) ${s}px 0px ${2 * s}px 0px`
            ),
            x,
            scale,
          }}
          // children={items[i]}
        >
          <img
            src={items[i]?.url ? items[i].url : items[i]}
            alt="alt"
            style={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
              borderRadius: "50%",
            }}
          />
          <div
            style={{
              position: "absolute",
              bottom: 0,
              right: 0,
              borderRadius: "50%",
              background: COLORS_PALETTE.primaryColor,

              // padding: "4px",
            }}
          >
            <div
              style={{
                // position: "absolute",
                bottom: 0,
                right: 0,
                width: "16px",
                height: "16px",
                // make content center:
                display: "flex",
                justifyContent: "center",
                alignItems: "center",

                // padding: 4,
                background:
                  currentOrder.indexOf(i) == 0
                    ? COLORS_PALETTE.paletteColor4
                    : COLORS_PALETTE.primaryColor,
                borderRadius: "50%",
                color: COLORS_PALETTE.cardLightColor,
                fontSize: "10px",
                fontWeight: "bold",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {currentOrder.indexOf(i) + 1}
            </div>
          </div>
        </animated.div>
      ))}
    </div>
  );
}

export default SortablePhotoAnswer;
