import { Button as MuiButton } from "@mui/material";
import { GRADIENTS } from "../Views/Landing/constants";

export default function PurplePinkButton({ children, sx, ...props }) {
  return (
    <MuiButton
      sx={{
        height: "56px",
        color: "#fff",
        background: GRADIENTS[0],
        ...sx,
      }}
      {...props}
    >
      {children}
    </MuiButton>
  );
}
