import Logo from "Components/Logo";
import { UserContext } from "Providers/UserContext";
import { GetUserProfile, isTokenValid } from "Utils/fetchers";
import { useContext, useEffect } from "react";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  DrawOutlined,
  ExitToAppRounded as ExitToAppIcon,
  FavoriteRounded as FavoriteIcon,
  HomeRounded as HomeIcon,
  PersonRounded as PersonIcon,
  ReportProblem,
  WindowRounded as WindowIcon,
} from "@mui/icons-material";
import {
  Box,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import HeaderBar from "./Components/HeaderBar";
import { appUpdateChecker } from "Utils/updater";

export default function NavigationBar() {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const { setUserProfile } = useContext(UserContext);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  useEffect(() => {
    appUpdateChecker();
  }, [location]);

  const handleDrawerToggle = () => {
    if (!isDrawerOpen) {
      if (isTokenValid()) {
        setIsLoggedIn(true);
      } else {
        setIsLoggedIn(false);
      }
    }
    setIsDrawerOpen(!isDrawerOpen);
  };
  const getUserProfile = () => {
    navigate("/me");
    setIsDrawerOpen(false);
  };
  const handleLogout = () => {
    localStorage.removeItem("vqa_token");
    localStorage.removeItem("vqa_expiration");
    setIsLoggedIn(false);
    setUserProfile(null);
    navigate("/login");
    setIsDrawerOpen(false);
  };

  const handleLogin = () => {
    navigate("/login");
    setIsDrawerOpen(false);
  };

  const handleBlueprints = () => {
    navigate("/blueprints");
    setIsDrawerOpen(false);
  };

  const handleFlows = () => {
    navigate("/flows");
    setIsDrawerOpen(false);
  };
  const handleHome = () => {
    navigate("/home");
    setIsDrawerOpen(false);
  };

  const handleActivity = () => {
    // Handle activity action
    navigate("/activities");
    setIsDrawerOpen(false);
  };

  const drawerItems = [
    { text: "Home", icon: <HomeIcon />, onClick: handleHome, topMenu: true },
    {
      text: "My Inquiries",
      icon: <WindowIcon />,
      onClick: handleFlows,
      isHidden: !isLoggedIn,
      topMenu: true,
    },
    {
      text: "Drafts",
      icon: <DrawOutlined />,
      onClick: handleBlueprints,
      isHidden: !isLoggedIn,
      topMenu: true,
    },
    {
      text: "Activity",
      icon: <FavoriteIcon />,
      onClick: handleActivity,
      isHidden: !isLoggedIn,
      topMenu: true,
    },
    {
      text: "Profile",
      icon: <PersonIcon />,
      onClick: getUserProfile,
      isHidden: !isLoggedIn,
      topMenu: true,
    },
    {
      text: "Report Issue",
      icon: <ReportProblem />,
      onClick: () => {
        localStorage.setItem(
          "reportIssueData",
          JSON.stringify({
            ua: navigator.userAgent,
            url: window.location.href,
            w: window.screen.width,
            h: window.screen.height,
          })
        );
        navigate("/report");
        setIsDrawerOpen(false);
      },
    },
    {
      text: isLoggedIn ? "Logout" : "Login",
      icon: <ExitToAppIcon />,
      onClick: isLoggedIn ? handleLogout : handleLogin,
      topMenu: false,
    },
  ];

  // Hide NavigationBar when URL path is /f/hRyESnJu5I054IbAGplApmzL2CVcUETT

  if (location.pathname.startsWith("/f/")) {
    return null;
  }
  //http://localhost:3000/blueprints/57/start?test=true
  if (
    location.pathname.endsWith("start") ||
    location.pathname.endsWith("start2")
  ) {
    return null;
  }
  // Remove it from home page
  if (["/", "/signup", "/login"].includes(location.pathname)) {
    return null;
  }

  return (
    <>
      <HeaderBar onClick={handleDrawerToggle} isLoggedIn={isLoggedIn} />
      <Drawer anchor="left" open={isDrawerOpen} onClose={handleDrawerToggle}>
        <List sx={{ minWidth: "250px" }}>
          <Box sx={{ display: "flex", justifyContent: "center", padding: 2 }}>
            <Logo />
          </Box>
          {drawerItems
            .filter((item) => !item.isHidden && item.topMenu)
            .map((item, index) => (
              <ListItem button key={index} onClick={item.onClick}>
                <ListItemIcon>{item.icon}</ListItemIcon>
                <ListItemText primary={item.text} />
              </ListItem>
            ))}
        </List>
        <List sx={{ minWidth: "250px", position: "fixed", bottom: 0 }}>
          {drawerItems
            .filter((item) => !item.isHidden && !item.topMenu)
            .map((item, index) => (
              <ListItem button key={index} onClick={item.onClick}>
                <ListItemIcon>{item.icon}</ListItemIcon>
                <ListItemText primary={item.text} />
              </ListItem>
            ))}
        </List>
      </Drawer>
    </>
  );
}
