import "../../Loading/LoadingScreen.css";

export default function TestView2() {
  setTimeout(() => {
    window.location.href = "/f/HT991zjjpSxjWINzYecPu6VHKBefW8Of?test=true";
  }, 2000);
  return (
    <div className="loading-screen">
      <div
        className="loading-text"
        style={{ fontFamily: "ZITGRAFF, sans-serif", fontSize: "10rem" }}
      >
        WK
      </div>
    </div>
  );
}
