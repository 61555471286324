const appUpdateChecker = () => {
  // store last update in local storage. if it been more than 1 hour, check for updates
  const lastUpdate = localStorage.getItem("lastUpdateCheck");
  if (lastUpdate) {
    const lastUpdateDate = new Date(lastUpdate);
    const now = new Date();
    const diff = now - lastUpdateDate;
    if (diff > 1000 * 60 * 60) {
      navigator.serviceWorker.getRegistrations().then((registrationsArray) => {
        registrationsArray[0].update();
      });
    }
  }
  localStorage.setItem("lastUpdateCheck", new Date());
};

export { appUpdateChecker };
