import React, { useEffect, useRef, useState } from "react";
import { useSprings, animated } from "@react-spring/web";
import { useDrag } from "react-use-gesture";
import { clamp } from "lodash";
import swap from "lodash-move";
import styles from "./styles.module.css";

const fn =
  (order, active = false, originalIndex = 0, curIndex = 0, y = 0) =>
  (index) =>
    active && index === originalIndex
      ? {
          y: curIndex * 65 + y,
          scale: 1.1,
          zIndex: 1,
          shadow: 15,
          immediate: (key) => key === "y" || key === "zIndex",
        }
      : {
          y: order.indexOf(index) * 65,
          scale: 1,
          zIndex: 0,
          shadow: 1,
          immediate: false,
        };

function SortableAnswer({ items, answerCallback }) {
  const [currentOrder, setCurrentOrder] = useState(items);
  const order = useRef(items.map((_, index) => index));

  const [springs, api] = useSprings(items.length, fn(order.current));

  const bind = useDrag(({ args: [originalIndex], active, movement: [, y] }) => {
    const curIndex = order.current.indexOf(originalIndex);
    const curRow = clamp(
      Math.round((curIndex * 100 + y) / 100),
      0,
      items.length - 1
    );
    const newOrder = swap(order.current, curIndex, curRow);

    api.start(fn(newOrder, active, originalIndex, curIndex, y));

    if (!active) {
      order.current = newOrder;
      setCurrentOrder(order.current);
      console.log(answerCallback);
      if (order.current) answerCallback(order.current);
    }
  });

  useEffect(() => {
    order.current = items.map((_, index) => index);
    api.start(fn(order.current));
  }, [items, api]);

  return (
    <div className={styles.content} style={{ height: items.length * 70 }}>
      {springs.map(({ zIndex, shadow, y, scale }, i) => (
        <animated.div
          {...bind(i)}
          key={i}
          style={{
            zIndex,
            boxShadow: shadow.to(
              (s) => `rgba(0, 0, 0, 0.15) 0px ${s}px ${2 * s}px 0px`
            ),
            y,
            scale,
          }}
          children={items[i]}
        />
      ))}
    </div>
  );
}

export default SortableAnswer;
