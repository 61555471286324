import {
  Avatar,
  Box,
  Card,
  CardContent,
  Container,
  Fab,
  Grid,
  Skeleton,
  Typography,
} from "@mui/material";
import { GRADIENTS } from "../constants";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CanceCirclelIcon from "@mui/icons-material/Cancel";
import { useEffect, useRef, useState } from "react";
import {
  COLORS_PALETTE,
  COLOR_PALLETTE_2,
  PAPER_CARD_STYLE_2,
} from "Utils/static";
import ShareIcon from "@mui/icons-material/Share";
export default function Second() {
  return (
    <Box
      sx={{
        minHeight: "100vh",
        display: "grid",
        placeItems: "start center",
        // borderTop: "1px solid red",
        padding: "16px",
        background: "#F4EFF4",
      }}
    >
      <AnimatedCard
        strings={[
          "Rank my fall photoshoots from best to worst!",
          "If you life can be one Emoji which would it would be? ",
          "Would you rather be a famous singer or a famous actor?",
          "Rank the following in order of importance: Money, Love, Career, Family, Friends",
          "What is your favorite season ?",
        ]}
      />
      <Box
        sx={{
          display: "grid",
          gap: "16px",
          placeItems: "center",
          marginBottom: 12,
        }}
      >
        <Box
          sx={{
            height: "48px",
            width: "2px",
            borderRadius: "2px",
            opacity: "0.2",
            background: "#000",
          }}
        />
        <Box
          sx={{
            height: "36px",
            width: "36px",
            borderRadius: "25%",
            background: GRADIENTS[0],
            display: "grid",
            // fontWeight: "600",
            placeItems: "center",
          }}
        >
          1
        </Box>
        <Typography
          sx={{
            // gradient text
            background: GRADIENTS[0],
            WebkitBackgroundClip: "text",
            fontWeight: "700",
            fontSize: "clamp(24px, 4.1vw, 36px)",
            WebkitTextFillColor: "transparent",
          }}
        >
          Ask Anything
        </Typography>
        <Typography
          sx={{
            fontSize: "clamp(16px, 2.1vw, 24px)",
            fontWeight: "500",
            lineHeight: "clamp(24px, 2.1vw, 32px)",
            textAlign: "center",
            opacity: "0.6",
            color: "#000",
          }}
        >
          From casial to deep-diving questions, discover the world's reactions
          to your thoughts.
        </Typography>
      </Box>
      <Box
        sx={{
          display: "grid",
          gap: "16px",
          placeItems: "center",
          marginBottom: 12,
        }}
      >
        <Box>
          <ShareMock
            intervalChange={2000}
            strings={["FAMILY", "FRIENDS", "FOLLOWERS", "COMMUNITY"]}
          />
        </Box>
        <Box
          sx={{
            height: "48px",
            width: "2px",
            borderRadius: "2px",
            opacity: "0.2",
            background: "#000",
          }}
        />

        <Box
          sx={{
            height: "36px",
            width: "36px",
            borderRadius: "25%",
            background: GRADIENTS[1],
            display: "grid",
            // fontWeight: "600",
            placeItems: "center",
          }}
        >
          2
        </Box>
        <Typography
          sx={{
            // gradient text
            background: GRADIENTS[1],
            WebkitBackgroundClip: "text",
            fontWeight: "700",
            fontSize: "clamp(24px, 4.1vw, 36px)",
            WebkitTextFillColor: "transparent",
          }}
        >
          Share Effortlessly
        </Typography>
        <Typography
          sx={{
            fontSize: "clamp(16px, 2.1vw, 24px)",
            fontWeight: "500",
            lineHeight: "clamp(24px, 2.1vw, 32px)",
            textAlign: "center",
            opacity: "0.6",
            color: "#000",
          }}
        >
          Spread your questions across family, friends, or a wider community.
        </Typography>
      </Box>
      <Box
        sx={{
          display: "grid",
          gap: "16px",
          placeItems: "center",
          marginBottom: 12,
        }}
      >
        <Box marginBottom={10}>
          {/* <InsightsMock /> */}
          <AnimatedCard2
            cards={[
              <div>
                {[100, 230, 140, 40, 70].map((width, index) => (
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <div
                      style={{
                        marginTop: "8px",

                        borderRadius: 24,
                        marginRight: 8,
                        height: 30,
                        width: width,
                        transition: "height 0.5s ease-out",
                        backgroundColor: "#bd6be1",
                        position: "relative",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "left",
                      }}
                    >
                      <Avatar sx={{ width: 30, height: 30 }} />
                    </div>
                  </div>
                ))}
              </div>,

              <div>
                <CheckCircleIcon
                  style={{
                    height: 40,
                    width: 40,
                    borderRadius: "50%",
                    color: COLOR_PALLETTE_2.green,
                    position: "absolute",
                    top: 40,
                    right: 18,
                  }}
                />
                <CanceCirclelIcon
                  style={{
                    height: 40,
                    width: 40,
                    borderRadius: "50%",
                    color: COLOR_PALLETTE_2.red,

                    position: "absolute",
                    top: 40,
                    left: 8,
                  }}
                />

                <div
                  style={{ display: "flex", alignItems: "end", marginLeft: 8 }}
                >
                  {[100, 230, 140, 40, 70].map((height, index) => (
                    <div
                      style={{
                        padding: 8,
                        borderRadius: 24,
                        marginRight: 8,
                        height: height * 0.9,
                        width: 25,
                        backgroundColor: "#bd6be1",
                        position: "relative",
                      }}
                      // className="appear-with-flip"
                    ></div>
                  ))}
                </div>
              </div>,
              <div>
                {["🇺🇸", "🇬🇧", "🇨🇦", "🇷🇺", "🇨🇳", "🇸🇦"].map((emoji, index) => (
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <div
                      style={{
                        marginTop: "8px",

                        borderRadius: 24,
                        marginRight: 8,
                        height: 30,
                        // width is radnom numbe between 30 and 100:
                        width: Math.floor(Math.random() * 70) + 30,
                        transition: "height 0.5s ease-out",
                        backgroundColor: "#bd6be1",
                        position: "relative",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "left",
                      }}
                    >
                      <div
                        style={{
                          marginLeft: 0,
                          fontSize: 24,
                          background: "rgba(255,255,255,0.5)",
                          borderRadius: 60,
                          width: 30,
                          height: 30,
                          // center the content inside of this div:
                          display: "grid",
                          placeItems: "center",
                        }}
                      >
                        {emoji}
                      </div>
                    </div>
                  </div>
                ))}
              </div>,
              <div>
                <Card
                  style={{
                    ...PAPER_CARD_STYLE_2,
                    backgroundColor: "#efeefc",
                    padding: "16px 16px 16px",
                  }}
                  elevation={0}
                >
                  <Grid container spacing={2}>
                    {/* for each value in processRangedData add it to here: */}
                    <Grid item xs={6} align="center">
                      {/* Value 1 */}
                      <Typography variant="body1" align="center">
                        😂
                      </Typography>
                      {/* Secondary Label 1 */}
                      <Typography
                        variant="body1"
                        color="textSecondary"
                        align="center"
                        fontSize={8}
                      >
                        Most Used
                      </Typography>
                    </Grid>

                    <Grid item xs={6} align="center">
                      {/* Value 1 */}
                      <Typography variant="body1" align="center">
                        😵
                      </Typography>
                      {/* Secondary Label 1 */}
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        align="center"
                        fontSize={8}
                      >
                        Least used
                      </Typography>
                    </Grid>
                  </Grid>
                </Card>
                <CardContent>
                  {["🙄", "😴", "😵"].map((emoji, index) => (
                    <div
                      style={{
                        marginTop: "8px",

                        borderRadius: 24,
                        marginRight: 8,
                        height: 12,
                        // width is radnom numbe between 30 and 100:
                        width: Math.floor(Math.random() * 70) + 30,
                        transition: "height 0.5s ease-out",
                        backgroundColor: "#bd6be1",
                        position: "relative",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "left",
                      }}
                    >
                      <div
                        style={{
                          marginLeft: 0,
                          background: "rgba(255,255,255,0.5)",
                          borderRadius: 60,
                          width: 12,
                          height: 12,
                          // center the content inside of this div:
                          display: "grid",
                          placeItems: "center",
                          fontSize: 6,
                        }}
                      >
                        {emoji}
                      </div>
                    </div>
                  ))}
                </CardContent>
              </div>,
            ]}
          />
        </Box>
        <Box
          sx={{
            height: "48px",
            width: "2px",
            borderRadius: "2px",
            opacity: "0.2",
            background: "#000",
          }}
        />
        <Box
          sx={{
            height: "36px",
            width: "36px",
            borderRadius: "25%",
            background: GRADIENTS[2],
            display: "grid",
            // fontWeight: "600",
            placeItems: "center",
          }}
        >
          3
        </Box>
        <Typography
          sx={{
            // gradient text
            background: GRADIENTS[2],
            WebkitBackgroundClip: "text",
            fontWeight: "700",
            fontSize: "clamp(24px, 4.1vw, 36px)",
            WebkitTextFillColor: "transparent",
          }}
        >
          Gather Rich Insights
        </Typography>
        <Typography
          sx={{
            fontSize: "clamp(16px, 2.1vw, 24px)",
            fontWeight: "500",
            lineHeight: "clamp(24px, 2.1vw, 32px)",
            textAlign: "center",
            opacity: "0.6",
            color: "#000",
          }}
        >
          More than just answers - understand opinions, sentiments, and
          perspectives.
        </Typography>
      </Box>
    </Box>
  );
}

export const ShareMock = ({ strings, intervalChange }) => {
  const [currentTextIndex, setCurrentTextIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTextIndex((prevIndex) => (prevIndex + 1) % strings.length);
    }, intervalChange);

    return () => clearInterval(interval);
  }, [strings]);

  return (
    <Container sx={{ display: "flex", justifyContent: "center" }}>
      {/* CARD 1 (CARD) */}
      <Card
        style={{
          width: 250,
          height: 250,
          borderRadius: 24,
          position: "relative",
          alignContent: "center",
          justifyContent: "center",
          display: "flex",
          background: "none",
        }}
        elevation={0}
      >
        <Card
          style={{
            background: COLOR_PALLETTE_2.white,
            width: "80%",
          }}
        >
          <div style={{ margin: 8, padding: 8, borderRadius: 24, height: 64 }}>
            <Skeleton animation="wave"></Skeleton>
          </div>

          <div
            style={{
              margin: 8,
              padding: 8,
              borderRadius: 24,
              height: 40,
              marginBottom: 0,
            }}
          >
            <Skeleton animation="wave"></Skeleton>
            <Skeleton animation="wave"></Skeleton>
            <Skeleton animation="wave"></Skeleton>
          </div>
          <div
            style={{
              margin: 8,
              padding: 8,
              borderRadius: 24,
              height: 40,
              marginBottom: 0,
              marginTop: 0,
            }}
          ></div>
        </Card>
        {strings.map((text, index) => (
          <Fab
            key={index}
            variant="h6"
            align="center"
            style={{
              background: "#bd6be1",
              color: "white",
              padding: 8,
              opacity: index === currentTextIndex ? 1 : 0,
              marginTop: 24,
              width: "70%",
              borderRadius: 24,
              transition: "opacity 0.5s",
              position: "absolute",
              bottom: 8,
              right: 8,
            }}
          >
            <ShareIcon />
            {text}
          </Fab>
        ))}
      </Card>
      {/* CARD 2(CARD) */}

      {/* 
      <Grid container spacing={2}>
        {Array.from(new Array(9)).map((_, index) => (
          <Grid item xs={4}>
            <Card style={{ background: "#efeefc", width: "80%" }}>
              <Skeleton
                variant="rectangular"
                height={30}
                style={{ margin: 8, padding: 8, borderRadius: 24 }}
              />
              <Skeleton
                variant="text"
                height={20}
                style={{ margin: 8, padding: 8, borderRadius: 24 }}
              />
            </Card>
          </Grid>
        ))}
      </Grid> */}
    </Container>
  );
};

export const InsightsMock = ({}) => {
  const insightComponentRef = useRef(null);
  const barHeights = [100, 230, 140, 40, 70];
  useEffect(() => {
    let currentRef = insightComponentRef.current;
    const observer = new IntersectionObserver(
      ([entry]) => {
        console.log(entry.isIntersecting);
        if (entry.isIntersecting) {
          // Element is fully visible, add your CSS class
          // apply a class to all of it's child div:

          currentRef.childNodes.forEach((child, index) => {
            // child.classList.add("appear-with-flip");
            child.style.height = barHeights[index] + "px";
          });

          // currentRef.classList.add("your-css-class");
        }
      },
      { threshold: 1 } // Trigger when fully visible
    );

    if (currentRef) {
      observer.observe(currentRef);
    }

    // Clean up observer when component is unmounted
    return () => {
      if (currentRef) {
        observer.unobserve(currentRef);
      }
    };
  }, []);
  const [currentTextIndex, setCurrentTextIndex] = useState(0);

  return (
    <Container sx={{ display: "flex", justifyContent: "center" }}>
      {/* CARD 1 (CARD) */}
      <Card
        style={{
          width: 250,
          height: 250,
          borderRadius: 24,
          position: "relative",
          alignContent: "center",
          justifyContent: "center",
          display: "flex",
          background: COLOR_PALLETTE_2.white,
        }}
        // elevation={0}
      >
        {/* a div show a circle in top right of it's parent: */}

        <CheckCircleIcon
          style={{
            height: 60,
            width: 60,
            borderRadius: "50%",
            color: COLOR_PALLETTE_2.green,

            position: "absolute",
            top: 40,
            right: 18,
          }}
        />
        <CanceCirclelIcon
          style={{
            height: 60,
            width: 60,
            borderRadius: "50%",
            color: COLOR_PALLETTE_2.red,

            position: "absolute",
            top: 40,
            left: 8,
          }}
        />

        <Card
          style={{
            background: "none",
            borderRadius: 24,

            width: "80%",
          }}
          elevation={0}
        >
          <div
            ref={insightComponentRef}
            style={{ display: "flex", alignItems: "end", marginLeft: 8 }}
          >
            {[100, 230, 140, 40, 70].map((height, index) => (
              <div
                style={{
                  marginTop: 8,
                  padding: 8,
                  borderRadius: 24,
                  marginRight: 8,
                  height: 0,
                  width: 30,
                  transition: "height 0.5s ease-out",
                  backgroundColor: "#bd6be1",
                  position: "relative",
                }}
                // className="appear-with-flip"
              ></div>
            ))}
          </div>
        </Card>
      </Card>
      {/* CARD 2(CARD) */}
      <Card
        style={{
          width: 250,
          height: 250,
          borderRadius: 24,
          position: "relative",
          alignContent: "center",
          justifyContent: "center",
          display: "flex",
          background: COLOR_PALLETTE_2.white,
        }}
        // elevation={0}
      >
        {/* a div show a circle in top right of it's parent: */}

        <Card
          style={{
            background: "none",
            borderRadius: 24,

            width: "80%",
          }}
          elevation={0}
        >
          <div style={{ marginTop: 32 }}>
            {barHeights.map((width, index) => (
              <div style={{ display: "flex", flexDirection: "row" }}>
                <div
                  style={{
                    marginTop: 8,

                    borderRadius: 24,
                    marginRight: 8,
                    height: 30,
                    width: width,
                    transition: "height 0.5s ease-out",
                    backgroundColor: "#bd6be1",
                    position: "relative",
                    // Items inside this div will be center vertically:
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "left",
                  }}

                  // className="appear-with-flip"
                >
                  <Avatar sx={{ width: 30, height: 30 }} />
                </div>
              </div>
            ))}
          </div>
        </Card>
      </Card>
      {/* 
      <Grid container spacing={2}>
        {Array.from(new Array(9)).map((_, index) => (
          <Grid item xs={4}>
            <Card style={{ background: "#efeefc", width: "80%" }}>
              <Skeleton
                variant="rectangular"
                height={30}
                style={{ margin: 8, padding: 8, borderRadius: 24 }}
              />
              <Skeleton
                variant="text"
                height={20}
                style={{ margin: 8, padding: 8, borderRadius: 24 }}
              />
            </Card>
          </Grid>
        ))}
      </Grid> */}
    </Container>
  );
};
export const AnimatedCard = ({ strings, changeInterval }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const rotations = strings.map(
    (str, index) => (index % 2 ? 1 : -1) * (Math.random() * 3 + 3)
  );
  useEffect(() => {
    // create rotation for each string: ( % 2 ? 1 : -1) * (Math.random() * 3 + 1)

    const interval = setInterval(
      () => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % strings.length);
      },
      changeInterval ? changeInterval : 3000
    );

    return () => clearInterval(interval);
  }, [strings]);

  return (
    <>
      <Container
        sx={{ display: "flex", justifyContent: "center", marginBottom: 20 }}
      >
        {strings.map((str, index) => (
          <Card
            key={index}
            style={{
              width: "70%",
              maxWidth: "500px",
              padding: "16px",

              position: "absolute",
              transition: "transform 0.5s",
              rotate: `${rotations[index]}deg`,
              transform: `
              translateZ(${currentIndex === index ? 0 : -50}px)
              translateY(${
                currentIndex === index ? 0 : index % 2 === 0 ? -10 : 10
              }px)
              scale(${currentIndex === index ? 1.1 : 1})
              rotate(${currentIndex !== index ? 0 : -rotations[index]}deg)
            `,
              zIndex: currentIndex === index ? 1 : 0,
              opacity: currentIndex === index ? 1 : 0.8, // add opacity based on currentIndex
              // backgroundColor:
              // currentIndex === index ? "white" : "rgba(0, 0, 0, 0.5)", // darken the card based on currentIndex
            }}
          >
            <CardContent>
              <Typography variant="body1" component="div">
                {str}
              </Typography>
            </CardContent>
          </Card>
        ))}
      </Container>
    </>
  );
};

export const AnimatedCard2 = ({ cards, changeInterval }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const rotations = cards.map(
    (str, index) => (index % 2 ? 1 : -1) * (Math.random() * 3 + 3)
  );
  useEffect(() => {
    // create rotation for each string: ( % 2 ? 1 : -1) * (Math.random() * 3 + 1)

    const interval = setInterval(
      () => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % cards.length);
      },
      changeInterval ? changeInterval : 3000
    );

    return () => clearInterval(interval);
  }, [cards]);

  return (
    <>
      <Container
        sx={{ display: "flex", justifyContent: "center", marginBottom: 20 }}
      >
        {cards.map((cardContent, index) => (
          <Card
            key={index}
            style={{
              width: "70%",
              maxWidth: "200px",
              maxHeight: "230px",
              padding: "16px",

              position: "absolute",
              transition: "transform 0.5s",
              rotate: `${rotations[index]}deg`,
              transform: `
              translateZ(${currentIndex === index ? 0 : -50}px)
              translateY(${
                currentIndex === index ? 0 : index % 2 === 0 ? -10 : 10
              }px)
              scale(${currentIndex === index ? 1.1 : 1})
              rotate(${currentIndex !== index ? 0 : -rotations[index]}deg)
            `,
              zIndex: currentIndex === index ? 1 : 0,
              opacity: currentIndex === index ? 1 : 0.8, // add opacity based on currentIndex
              backgroundColor: COLOR_PALLETTE_2.white,
              // currentIndex === index ? "white" : "rgba(0, 0, 0, 0.5)", // darken the card based on currentIndex
            }}
          >
            {cardContent}
          </Card>
        ))}
      </Container>
    </>
  );
};
