// create boilerplate for ResetPasswordView:
import React, { useState } from "react";
import { Box } from "@mui/system";
import Typography from "@mui/material/Typography";
import { Snackbar, TextField } from "@mui/material";
import AuthCardContainer from "UI/AuthCardContainer";
import PurplePinkButton from "UI/PurplePinkButton";
import { passwordValidationHandler } from "Views/SignupView/SignupView";
import { useNavigate, useSearchParams } from "react-router-dom";
import { API_URL } from "Config";
import CustomCardContainer from "UI/CustomCardContainer";
const PasswordUpdateView = () => {
  const navigate = useNavigate();
  const [showLoginSnackbar, setShowLoginSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [email, setEmail] = useState("");
  const [passwordError, setPasswordError] = useState(null);
  const [passworConfirmationError, setPasswordConfirmationError] =
    useState(null);
  const [searchParams, setSearchParams] = useSearchParams();

  const [updateStatusOk, setUpdateStatusOk] = useState(null);

  const verifiticationCode = searchParams.get("c");

  const handlePasswordChange = (e) => {
    const password = e.target.value;
    setPassword(password);
    passwordValidationHandler(password, setPasswordError);
  };
  const handlePasswordConfirmChange = (e) => {
    setPasswordConfirm(e.target.value);
    if (e.target.value !== password) {
      setPasswordConfirmationError("Passwords do not match");
    } else {
      setPasswordConfirmationError(null);
    }
  };

  const handleSubmit = async () => {
    if (passwordError || passworConfirmationError) {
      return;
    }
    try {
      const response = await fetch(
        `${API_URL}/users/password?c=${verifiticationCode}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ password: password }),
        }
      );

      if (response.ok) {
        setUpdateStatusOk(true);
        //  wait 3 seconds and then redirect to login:
        setTimeout(() => {
          navigate("/login");
        }, 3000);
      } else {
        setUpdateStatusOk(false);
      }
    } catch (error) {
      setUpdateStatusOk(false);
    }
  };
  return (
    <CustomCardContainer>
      <Box sx={{ textAlign: "center" }}>
        <Typography variant="h5" sx={{ mb: 2 }} gutterBottom>
          Update Password
        </Typography>

        {/* <Box
          component="img"
          src={resetPasswordPhoto}
          alt="WK"
          height={96}
          borderRadius="0"
          style={{ cursor: "pointer", margin: 0, marginBottom: 10 }}
        /> */}
        <Typography variant="body1" sx={{ mb: 2 }} gutterBottom>
          Enter your new password below
        </Typography>
      </Box>
      {updateStatusOk === null && (
        <form component="form" onSubmit={handleSubmit}>
          <Box
            sx={{
              display: "grid",
              gap: 2,
            }}
          >
            <TextField
              label="Password"
              type="password"
              variant="outlined"
              fullWidth
              value={password}
              onChange={handlePasswordChange}
              error={passwordError !== null}
              helperText={passwordError}
            />
            <TextField
              label="Confirm Password"
              type="password"
              variant="outlined"
              fullWidth
              value={passwordConfirm}
              onChange={handlePasswordConfirmChange}
              error={passworConfirmationError !== null}
              helperText={passworConfirmationError}
            />
            {/* helper to show password not matching : */}

            <PurplePinkButton
              fullWidth
              size="large"
              sx={{
                height: "56px",
                marginTop: "24px",
              }}
              onClick={handleSubmit}
            >
              Save Password
            </PurplePinkButton>
          </Box>
        </form>
      )}
      {updateStatusOk === true && (
        <Box sx={{ textAlign: "center" }}>
          <Typography variant="body1" sx={{ mb: 2 }} gutterBottom>
            Password updated, redirecting to login...
          </Typography>
        </Box>
      )}
    </CustomCardContainer>
  );
};

export default PasswordUpdateView;
