import { KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material";
import ShareIcon from "@mui/icons-material/Share";
import {
  Card,
  CardActionArea,
  CardContent,
  Container,
  Divider,
  Fab,
  Grid,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import * as htmlToImage from "html-to-image";
import countries from "i18n-iso-countries";
import { round } from "lodash";
import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import AnswerStatsComponent from "../Components/AnswerStats";
import CountryStatsComponent from "../Components/CountryStatsComponent";
import { API_URL } from "../Config";
import { GetFlowByShortenKey, vfetch } from "../Utils/fetchers";
import { COLORS_PALETTE, PAPER_CARD_STYLE_2 } from "../Utils/static";
import "./Question.css";
import { Button } from "Components/CustomHTMLComponent";
function AnswerStatsView() {
  const [isLoading, setIsLoading] = useState(true);
  const [perQuestionStatus, setPerQuestionStatus] = useState(null);
  const [questionsMap, setQuestionsMap] = useState({});
  const [questionsArray, setQuestionsArray] = useState([]);
  const [flow, setFlow] = useState(null);
  const [activeStatsId, setActiveStatsId] = useState(0);
  const [eventGeos, setEventGeos] = useState({});
  const [twoRandomCountry, setTwoRandomCountry] = useState(null);
  const imageRef = useRef(null);

  const [activeTab, setActiveTab] = useState("Summary");
  let { flowShortenKey } = useParams();
  useEffect(() => {
    fetchFlow();
    fetchFlowEventsGeo();
    countries.registerLocale(require("i18n-iso-countries/langs/en.json"));

    // fetchFlowAnswerStats();
  }, []);
  const fetchFlow = () => {
    GetFlowByShortenKey(flowShortenKey)
      .then((response) => response.json())
      .then((data) => {
        const qMap = {};
        data.blueprint.questions.forEach((q) => {
          qMap[q.id] = q;
        });
        if (data.blueprint.type === 1) {
          // Limited flow load user's answer
          vfetch(API_URL + "/answers/flow/" + flowShortenKey + "/owner", {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + localStorage.getItem("vqa_token"),
            },
          })
            .then((response) => response.json())
            .then((data) => {})
            .catch((error) =>
              console.error("Error fetching answer for owner:", error)
            );

          // setIsLoading(false);
          // return;
        }
        setQuestionsMap(qMap);
        setQuestionsArray(data.blueprint.questions);
        setFlow(data.blueprint);
        fetchFlowAnswerStats(qMap);
        // console.log("qMap", questionsMap);
      })

      .catch((error) => console.error("Error fetching question:", error));
  };
  const handleShare = async () => {
    imageRef.current.style.background = "#ffffff";
    // imageRef.current.style.padding = "20px";
    const newFile = await htmlToImage.toBlob(imageRef.current);

    const data = {
      files: [
        new File([newFile], "wannaknow.png", {
          type: newFile.type,
        }),
      ],
    };
    try {
      if (!navigator.canShare(data)) {
        console.error("Can't share");
      }
      await navigator.share(data);
    } catch (err) {
      console.error(err);
    }
  };
  const fetchFlowEventsGeo = () => {
    var headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("vqa_token"),
    };
    fetch(API_URL + "/stats/f/" + flowShortenKey + "/events/geo", {
      method: "GET",
      headers: headers,
    })
      .then((response) => response.json())
      .then((data) => {
        // generate mock data similar to ["countries"][country]["*"]:
        // generate mock data similar to ["countries"][country]["*"]:
        // const ddd = {
        //   start_flow_count: 120,
        //   countries: {
        //     US: {
        //       Reston: 30,
        //       "New York": 20,
        //       "San Francisco": 10,
        //       "Los Angeles": 5,
        //       Chicago: 5,
        //       Seattle: 5,
        //       Boston: 5,
        //       "*": 80,
        //     },
        //     CA: {
        //       Toronto: 10,
        //       Vancouver: 5,
        //       Montreal: 3,
        //       Calgary: 2,
        //       "*": 20,
        //     },
        //     GB: {
        //       London: 30,
        //       "*": 30,
        //     },
        //   },
        // };
        setEventGeos(data);

        const countriesToDisplay = Object.keys(data["countries"]);
        if (countriesToDisplay.length >= 2) {
          // loop and find the two countries with the highest ["countries"][country]["*"] value and lowest ["countries"][country]["*"] value:
          var highestResCountry, lowestResCountry;
          for (var country in data["countries"]) {
            if (highestResCountry === undefined) {
              highestResCountry = country;
              lowestResCountry = country;
            } else {
              if (
                data["countries"][country]["*"] >
                data["countries"][highestResCountry]["*"]
              ) {
                highestResCountry = country;
              }
              if (
                data["countries"][country]["*"] <
                data["countries"][lowestResCountry]["*"]
              ) {
                lowestResCountry = country;
              }
            }
          }
          const twoRandomCountry = [
            {
              name: highestResCountry,
              count: data["countries"][highestResCountry]["*"],
            },
            {
              name: lowestResCountry,
              count: data["countries"][lowestResCountry]["*"],
            },
          ];

          setTwoRandomCountry(twoRandomCountry);
        }

        // sort data by highest ["countries"][country]["*"] values:

        //   const perCountry = {};
        //   data.forEach((countryStats) => {
        //     const { country_code, percentage, count, total_count } = countryStats;
        //     perCountry[country_code] = {
        //       percentage: round(percentage, 2),
        //       count,
        //       total_count,
        //     };
        //   });
      })
      .catch((error) => console.error("Error fetch stats:", error));
  };

  const fetchFlowAnswerStats = (questionsMap) => {
    var headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("vqa_token"),
    };

    fetch(API_URL + "/stats/f/" + flowShortenKey, {
      method: "GET",
      headers: headers,
    })
      .then((response) => response.json())
      .then((data) => {
        const perQuestionStatus = {};
        data.forEach((choiceStats) => {
          // console.log("Looping choiceStats", choiceStats, questionsMap);
          const { question_id, choice, percentage, count, total_count } =
            choiceStats;
          // if (!questionsMap.hasOwnProperty(question_id)) {
          // }
          if (questionsMap[question_id]?.type === "one_of_many") {
            if (!perQuestionStatus[question_id]) {
              perQuestionStatus[question_id] = { dummy_by: "dummy_by" };
            }
            perQuestionStatus[question_id][choice] = percentage;
          } else if (questionsMap[question_id]?.type === "many_of_many") {
            const possible_choices =
              questionsMap[question_id].metadata.images?.length > 0
                ? questionsMap[question_id].metadata.images.map((i) => i.url)
                : questionsMap[question_id].metadata.choices;
            if (!perQuestionStatus[question_id]) {
              perQuestionStatus[question_id] = { dummy_by: "dummy_by" };
            }
            // check if choice is , seperated then split and add to array:
            if (choice.includes(",")) {
              const choices = choice.split(",");
              choices.forEach((c) => {
                if (!perQuestionStatus[question_id][possible_choices[c]]) {
                  perQuestionStatus[question_id][possible_choices[c]] = 0;
                }
                perQuestionStatus[question_id][possible_choices[c]] +=
                  percentage;
              });
            } else {
              perQuestionStatus[question_id][possible_choices[choice]] =
                percentage;
            }
          } else if (questionsMap[question_id].type === "number_ranged") {
            if (!perQuestionStatus[question_id]) {
              perQuestionStatus[question_id] = [];
            }
            perQuestionStatus[question_id].push({
              y: "" + round(percentage),
              x: Number(choice),
              // count: count,
            });
          } else if (questionsMap[question_id].type === "sortable") {
            if (!perQuestionStatus[question_id]) {
              perQuestionStatus[question_id] = {};
            }
            perQuestionStatus[question_id][choice] = percentage;
          } else {
            if (!perQuestionStatus[question_id]) {
              perQuestionStatus[question_id] = { dummy_by: "dummy_by" };
            }
            perQuestionStatus[question_id][choice] = percentage;
          }
        });
        setPerQuestionStatus(perQuestionStatus);
        setIsLoading(false);
      })
      .catch((error) => console.error("Error fetching question:", error));
  };
  if (isLoading) return "Loading...";
  if (Object.keys(perQuestionStatus).length === 0)
    return (
      <Container maxWidth="xs" style={{ marginTop: "8px" }}>
        <Card
          style={{
            background: "#ffffff",
            minHeight: "90vh",
            borderRadius: "24px",
            padding: "16px",
          }}
        >
          <CardContent>
            <Typography
              variant="h6"
              color="primary"
              align="center"
              style={{ marginBottom: 10 }}
            >
              No data available for this flow yet.
            </Typography>
          </CardContent>
          <CardActionArea>
            <Button
              size="large"
              variant="contained"
              color="primary"
              fullWidth
              onClick={() => {
                // go to the previous page
                window.history.back();
              }}
              pill
            >
              Go Back
            </Button>
          </CardActionArea>
        </Card>
      </Container>
    );
  return (
    <Container maxWidth="xs" style={{ marginTop: "8px" }}>
      <Card
        style={{
          background: "#ffffff",
          minHeight: "90vh",
          borderRadius: "24px",
        }}
      >
        <div></div>
        <CardContent>
          <Tabs
            value={activeTab}
            centered
            variant="fullWidth"
            style={{ marginBottom: "10px" }}
            onChange={(e, v) => setActiveTab(v)}
          >
            <Tab value="Summary" label="Summary" />
            <Tab value="Details" label="Details" />
            <Tab value="Location" label="Location" />
          </Tabs>
          <div ref={imageRef}>
            {activeTab === "Location" ? (
              <div>
                <Typography variant="body1" color={COLORS_PALETTE.paletteText5}>
                  Highest and lowest Responses from:
                </Typography>
                <CardContent style={PAPER_CARD_STYLE_2}>
                  <Grid container spacing={2}>
                    <Grid item xs={6} align="center">
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        align="center"
                      >
                        Highest
                      </Typography>
                      {/* Value 1 */}

                      <Typography variant="h4" align="center">
                        {twoRandomCountry ? twoRandomCountry[0].count : "--"}
                      </Typography>
                      {/* Secondary Label 1 */}
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        align="center"
                      >
                        {twoRandomCountry
                          ? countries.getName(twoRandomCountry[0].name, "en", {
                              select: "alias",
                            })
                          : "--"}
                      </Typography>
                    </Grid>

                    <Grid item xs={6} align="center">
                      {/* Value 1 */}

                      <Typography
                        variant="body2"
                        color="textSecondary"
                        align="center"
                      >
                        Lowest
                      </Typography>
                      <Typography variant="h4" align="center">
                        {twoRandomCountry ? twoRandomCountry[1].count : "--"}
                      </Typography>
                      {/* Secondary Label 1 */}
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        align="center"
                      >
                        {twoRandomCountry
                          ? countries.getName(twoRandomCountry[1].name, "en", {
                              select: "alias",
                            })
                          : "--"}{" "}
                      </Typography>
                    </Grid>
                  </Grid>
                </CardContent>
                <Divider style={{ marginTop: "20px" }} />
                <Typography
                  variant="body1"
                  color={COLORS_PALETTE.paletteText5}
                  marginTop={"10px"}
                >
                  Break down by country:
                </Typography>
                <CountryStatsComponent eventGeos={eventGeos} />
              </div>
            ) : (
              ""
            )}
            {activeTab === "Summary" ? (
              <div>
                <Typography
                  variant="h5"
                  color="primary"
                  align="center"
                  style={{ marginBottom: 10 }}
                >
                  {flow.description}
                </Typography>

                {/* CARD 1 */}
                <CardContent style={PAPER_CARD_STYLE_2}>
                  <Grid container spacing={2}>
                    <Grid item xs={6} align="center">
                      {/* Value 1 */}
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        align="center"
                      >
                        Started by
                      </Typography>
                      <Typography variant="h4" align="center">
                        {eventGeos["start_flow_count"]}
                      </Typography>
                      {/* Secondary Label 1 */}
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        align="center"
                      >
                        Users
                      </Typography>
                    </Grid>

                    <Grid item xs={6} align="center">
                      {/* Value 1 */}
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        align="center"
                      >
                        Completed By
                      </Typography>
                      <Typography variant="h4" align="center">
                        {/* calculate percentage based on  {eventGeos["finish_flow_count"]} and                       {eventGeos["start_flow_count"]} */}
                        {round(
                          (eventGeos["finish_flow_count"] /
                            eventGeos["start_flow_count"]) *
                            100
                        ) + "%"}
                      </Typography>
                      {/* Secondary Label 1 */}
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        align="center"
                      >
                        Users
                      </Typography>
                    </Grid>
                  </Grid>
                </CardContent>
                {/* CARD 2 */}
              </div>
            ) : (
              <div></div>
            )}
            {activeTab === "Details" ? (
              <div>
                <div>
                  {/* <Typography variant="body1" color={COLORS_PALETTE.paletteText5}>
                  Break down by question:
                </Typography> */}
                  {/* <Pagination
                  count={questionsArray.length}
                  page={activeStatsId + 1}
                  onChange={(event, value) => {
                    event.preventDefault();
                    if (value - 1 > -1 && value - 1 < questionsArray.length)
                      setActiveStatsId(value - 1);
                  }}
                /> */}
                </div>
                <div style={{ minHeight: "60vh", overflowY: "scroll" }}>
                  {/* <h3>Question:</h3> */}
                  <Typography style={{ minHeight: 90 }} variant="h7">
                    {questionsMap[questionsArray[activeStatsId].id]?.text}
                  </Typography>
                  <div
                    style={{
                      height: "70vh",
                      width: "100%",
                      overflowY: "scroll",
                    }}
                  >
                    <AnswerStatsComponent
                      questionsStatuses={perQuestionStatus}
                      questionId={questionsArray[activeStatsId].id}
                      question={questionsMap[questionsArray[activeStatsId].id]}
                    />
                  </div>
                </div>
              </div>
            ) : (
              <div></div>
            )}
          </div>
          {/* when share button click generate image from this html and open share view on phone: */}
          <Fab
            variant="contained"
            color="primary"
            size="large"
            style={{
              position: "fixed",
              bottom: "20px",
              right: "20px",
            }}
            className="slide-from-bottom"
            onClick={() => {
              handleShare();
              // generate image from this html and open share view on phone:
            }}
          >
            <ShareIcon />
          </Fab>
          {activeTab === "Details" ? (
            <div>
              <Fab
                variant="contained"
                color="primary"
                size="large"
                style={{
                  position: "fixed",
                  bottom: "20px",
                  left: "20px",
                }}
                disabled={activeStatsId === 0}
                onClick={() => {
                  setActiveStatsId(activeStatsId - 1);
                }}
                className="slide-from-bottom"
              >
                <KeyboardArrowLeft />
              </Fab>
              <Fab
                variant="contained"
                color="primary"
                size="large"
                style={{
                  position: "fixed",
                  bottom: "20px",
                  left: "80px",
                }}
                disabled={activeStatsId === questionsArray.length - 1}
                onClick={() => {
                  setActiveStatsId(activeStatsId + 1);
                }}
                className="slide-from-bottom"
              >
                <KeyboardArrowRight />
              </Fab>
            </div>
          ) : (
            ""
          )}
        </CardContent>
      </Card>
    </Container>
  );
}

export default AnswerStatsView;
