import { API_URL } from "../Config";

// Users:
// authentication:
// expired token :
// Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOjE2OTAwNjAzNDksImlkIjoidXNlcjFAZW1haWwuY29tIiwib3JpZ19pYXQiOjE2OTAwNTY3NDl9.oArrmkieCTkI4jOuNt2ozoaxWByAYWszCPmYzhopDX0

// Questions:

import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";

export function vfetch(url, options) {
  if (!isTokenValid()) {
    // store current location in cookie
    Cookies.set("wk_redirect", window.location.pathname);
    // Assuming you have a function isTokenValid() that checks token validity
    window.location.href = "/login"; // Redirect to login page
    // return Promise.reject("Token is not valid");
  }

  return fetch(url, options);
}

export const HEADERS = () => {
  return {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("vqa_token"),
  };
};
const postQuestion = (question) => {
  const url = `${API_URL}/questions`;
  const token = localStorage.getItem("vqa_token");
  return fetch(url, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify(question),
  });
};
const putQuestion = (flowId, question) => {
  const url = `${API_URL}/questions`;
  const token = localStorage.getItem("vqa_token");
  return fetch(url, {
    method: "PUT",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify(question),
  });
};
const deleteQuestion = (question) => {
  const url = `${API_URL}/questions`;
  const token = localStorage.getItem("vqa_token");
  return fetch(url, {
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify({ id: question.id, flow_Id: question.flow_id }),
  });
};

const getAllFlows = () => {
  return fetch(API_URL + "/flows", {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("vqa_token"),
    },
  });
};

const getAllUsedFlows = (offset, limit) => {
  if (!offset) {
    offset = 0;
  }
  if (!limit) {
    limit = 10;
  }

  return vfetch(`${API_URL}/flows/copied?offset=${offset}&limit=${limit}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("vqa_token"),
    },
  });
};

const postCloneFlowByFlowId = (flowId) => {
  return vfetch(API_URL + "/flows/" + flowId + "/clone", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("vqa_token"),
    },
    body: { flow_id: flowId },
  });
};

const pauseFlowUserByFlowId = (flowId) => {
  return fetch(API_URL + "/flows/" + flowId + "/clone", {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("vqa_token"),
    },
  });
};
const resumeFlowUserByFlowId = (flowId) => {
  return fetch(API_URL + "/flows/" + flowId + "/clone/resume", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("vqa_token"),
    },
  });
};
// const getUserProfile = func {
//   return fetch(API_URL + "/users/me", {
//     method: "GET",
//     headers: {
//       "Content-Type": "application/json",
//       Authorization: "Bearer " + localStorage.getItem("vqa_token"),
//     },
//   });
// };

const isTokenValid = () => {
  const expiration = localStorage.getItem("vqa_expiration");
  if (localStorage.getItem("vqa_token")) {
    const now = new Date();
    const expire = new Date(expiration);

    if (now < expire) {
      if (now.getTime() + 24 * 60 * 60 * 1000 > expire.getTime()) {
        console.log("refreshing token");
        console.log("now.getTime()", now.getTime());
        console.log("expire.getTime()", expire.getTime());
        console.log(
          "expire.getTime() - now.getTime()",
          expire.getTime() - now.getTime()
        );

        refreshAccessToken();
      }

      return true;
    } else {
      localStorage.removeItem("vqa_token");
      localStorage.removeItem("vqa_expiration");
    }
    return false;
  }
};

const refreshAccessToken = () => {
  fetch(API_URL + "/users/refresh", {
    method: "GET",
    headers: HEADERS(),
  }).then((res) => {
    if (res.status === 200) {
      return res.json().then((data) => {
        localStorage.setItem("vqa_token", data.token);
        localStorage.setItem("vqa_expiration", data.expire);
      });
    } else {
      return res.json().then((error) => {
        console.log(error);
      });
    }
  });
};

const GetFlowByShortenKey = (flowShortenKey) => {
  return fetch(API_URL + "/flows/f/" + flowShortenKey, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
};
const GetBlueprintByBlueprintId = (blueprintId) => {
  // update this to use blueprint when blueprint is ready on backend
  return fetch(API_URL + "/flows/" + blueprintId, {
    method: "GET",
    headers: HEADERS(),
  });
};
const GetAnswerStatsForUser = () => {
  return fetch(API_URL + "/stats/user/me", {
    method: "GET",
    headers: HEADERS(),
  });
};
const GetUserProfile = () => {
  return vfetch(API_URL + "/user/me", {
    method: "GET",
    headers: HEADERS(),
  });
};

const postAnswerforQuestion = (answerRequest, uniqueId) => {
  let url = API_URL + "/answers";
  if (!isTokenValid()) {
    url = API_URL + "/answers/anonymous";
  }
  return fetch(url, {
    method: "POST",
    headers: HEADERS(),
    body: JSON.stringify({
      deviceId: uniqueId,
      answer: answerRequest,
    }),
  });
};

const postEvent = (event_type, event_data) => {
  fetch(API_URL + "/events", {
    method: "POST",
    headers: HEADERS(),
    body: JSON.stringify({
      event_type: event_type,
      event_data: event_data,
    }),
  });
};

const getRecommendedFlows = () => {
  return fetch(API_URL + "/flows/recommend", {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("vqa_token"),
    },
  });
};
const getUserActivities = () => {
  return vfetch(API_URL + "/activities", {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("vqa_token"),
    },
  });
};

const uploadQuestionPhoto = (formData) => {
  return vfetch(API_URL + "/questions/image", {
    method: "POST",
    headers: {
      Authorization: "Bearer " + localStorage.getItem("vqa_token"),
    },
    body: formData,
  });
};
const uploadFlowQuestionPhoto = (formData) => {
  return vfetch(API_URL + "/questions/flow/image", {
    method: "POST",
    headers: {
      Authorization: "Bearer " + localStorage.getItem("vqa_token"),
    },
    body: formData,
  });
};
const saveFlowMetadata = (flow_shorten_url, flowMetadata) => {
  const questionsOverride = {};
  Object.keys(flowMetadata).forEach((key) => {
    questionsOverride[key] = {
      text: flowMetadata[key].text,
      choices: flowMetadata[key].metadata.choices,
      images: flowMetadata[key].metadata.images,
    };
  });

  return vfetch(API_URL + "/flows/f/" + flow_shorten_url + "/metadata", {
    method: "PUT",
    headers: HEADERS(),
    body: JSON.stringify({
      metadata: {
        questionsOverride: questionsOverride,
      },
    }),
  });
};
const PostViolation = (violation) => {
  return vfetch(API_URL + "/violation", {
    headers: HEADERS(),
    method: "POST",
    body: JSON.stringify(violation),
  });
};

const PostIssue = (issue) => {
  return vfetch(API_URL + "/issue", {
    headers: HEADERS(),
    method: "POST",
    body: JSON.stringify(issue),
  });
};

export {
  postQuestion,
  putQuestion,
  deleteQuestion,
  getAllFlows,
  getAllUsedFlows,
  postCloneFlowByFlowId as postCloneFlowByFlowId,
  resumeFlowUserByFlowId,
  pauseFlowUserByFlowId,
  isTokenValid,
  GetUserProfile,
  GetBlueprintByBlueprintId,
  GetFlowByShortenKey,
  GetAnswerStatsForUser,
  postAnswerforQuestion,
  postEvent,
  getRecommendedFlows,
  getUserActivities,
  uploadQuestionPhoto,
  uploadFlowQuestionPhoto,
  saveFlowMetadata,
  PostViolation,
  PostIssue,
};
