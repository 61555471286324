import SplitButton from "Components/SplitButtonComponent";
import { API_URL } from "Config";
import QuickQuestionIcon from "Static/pending_payment/decision-making.png";
import QuestionsSet from "Static/pending_payment/decision-tree.png";
import PhotoQuestionSet from "Static/pending_payment/gallery.png";
import ChallengeIcon from "Static/pending_payment/goal.png";
import { vfetch } from "Utils/fetchers";
import { useNavigate } from "react-router-dom";

const options = [
  {
    title: "Quick Question",
    description: "Ask a quick question",
    image: QuickQuestionIcon,
  },
  {
    title: "Rank Photos",
    description: "Rank photos in order of preference",
    image: PhotoQuestionSet,
  },
  {
    title: "Challenge",
    description: "Challenge your friends to a fun challenge",
    image: ChallengeIcon,
  },
  {
    title: "Set of Question",
    description: "Create and discover all questions types",
    image: QuestionsSet,
  },
];

export default function CreateNewButtonDropdown() {
  const navigate = useNavigate();
  const handleCreateBlueprint = (description, type = 1, initRouteWith) => {
    vfetch(API_URL + "/flows", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("vqa_token"),
      },
      body: JSON.stringify({
        flow: { description: description, type: type },
      }),
    }).then((response) => {
      if (response.status === 200) {
        return response.json().then((data) => {
          let flow = { id: data.id, description: data.description };

          navigate(
            "/blueprints/" +
              flow.id +
              "/edit" +
              "?initRouteWith=" +
              initRouteWith
          );
        });
      }
      return response.json().then((error) => {
        console.error("Error creating flow:", error);
        // throw new Error(error.message);
      });
    });
  };

  const handleSelectedOption = (option) => {
    const title = option.title;

    if (title === "Quick Question") {
      handleCreateBlueprint(
        "Quick Question" + new Date().toLocaleDateString(),
        3,
        "quickQuestion"
      );
    } else if (title === "Challenge") {
      handleCreateBlueprint("New Challenge", 2, "challenge");
    } else if (title === "Set of Question") {
      handleCreateBlueprint("New Challenge", 1, "setOfQuestions");
    } else if (title === "Rank Photos") {
      handleCreateBlueprint(
        "Rank Photos" + new Date().toLocaleDateString(),
        3,
        "rankPhotos"
      );
    }
  };

  return (
    <SplitButton
      //   variant="outlined"
      fullWidth
      sx={{ borderRadius: "26px" }}
      options={options}
      label={"Create new"}
      selectedOption={handleSelectedOption}
    />
  );
}
