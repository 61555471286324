// this file will be used to store all the languages for text and in the app:
// modal confmiration messages for editting blueprints:
//enable auto complete for this file:
export const DIALOG_CONFIRMATION_MESSAGES = {
  en: {
    publish_blueprint: {
      title: "Publish Blueprint",
      body: "Are you sure you want to publish this blueprint? This will make it available to all users. and you will not be able to edit it again.",
      type: "publish",
    },
    archive_blueprint: {
      title: "Archive Blueprint",
      body: "Are you sure you want to archive this blueprint? This will make it unavailable to new users. to clone it",
      type: "archive",
    },
    test_flow_completed: {
      title: "Test Completed",
      body: "That's it! You have completed the flow. You can try it again or go back to previous page.",
      type: "archive",
      confirm: "Replay",
      cancel: "Close",
    },
  },
};

export const FIELD_HINT_AND_HELPER_TEXT = {
  en: {
    question_types: {
      yes_no: "Simple yes/no true/false question",
      one_of_many: "Select one of many choices",
      number_ranged: "Select a number from a range",
      sortable: "Sort the choices in the order you prefer",
      text: "Free text answer",
      me_not_me: "Me / Not me another flavor of yes/no question",
    },
  },
};
