import { ResponsiveBar } from "@nivo/bar";
import { ResponsiveLine } from "@nivo/line";
import { ResponsiveStream } from "@nivo/stream";
import { random, round } from "lodash";
import calculateSortableAnswerStats from "./StatsUtils";
import { QUESTION_TYPE_SUPPORT_PHOTOS } from "../Utils/static";
import { ResponsiveScatterPlot } from "@nivo/scatterplot";
import {
  COLORS_PALETTE,
  GRAPH_COLORS,
  PAPER_CARD_STYLE_2,
} from "../Utils/static";
import {
  Avatar,
  Card,
  CardContent,
  Divider,
  Grid,
  LinearProgress,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import { Photo } from "@mui/icons-material";

function AnswerStatsComponent({ questionsStatuses, questionId, question }) {
  useEffect(() => {
    if (
      QUESTION_TYPE_SUPPORT_PHOTOS.includes(question.type) &&
      question.metadata.images?.length > 1
    ) {
      question.metadata.choices = question.metadata.images;
      setIsPhotoQuestion(true);
    } else {
      setIsPhotoQuestion(false);
    }
  }, [question, questionId]);
  const [isPhotoQuestion, setIsPhotoQuestion] = React.useState(false);
  const [activeTab, setActiveTab] = React.useState("Summary");
  if (!questionsStatuses[questionId]) {
    // this question have no answer yet

    return <div>No Answers receieved yet </div>;
  } else if (question.type === "one_of_many" && isPhotoQuestion) {
    return (
      <div>
        {Object.keys(questionsStatuses[questionId])
          .filter((k) => k !== "dummy_by")
          .map((choice, index) => {
            return (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "16px",
                  marginTop: "16px",
                  backgroundColor: index % 2 === 0 ? "#f5f5f5" : "white",
                }}
              >
                <img
                  src={question.metadata.images[choice].url}
                  style={{ width: 100, height: 100 }}
                />
                <div style={{ marginLeft: "16px", flexGrow: 1 }}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <span>{questionsStatuses[questionId][choice]}%</span>
                  </div>
                  <LinearProgress
                    variant="determinate"
                    value={questionsStatuses[questionId][choice]}
                    sx={{ marginTop: "8px", height: 10, borderRadius: 5 }}
                  />
                </div>
              </div>
            );
          })}
      </div>
    );
  } else if (question.type === "many_of_many" && isPhotoQuestion) {
    return (
      <div>
        {Object.keys(questionsStatuses[questionId])
          .filter((k) => k !== "dummy_by")
          .map((choice, index) => {
            return (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginBottom: "16px",
                  marginTop: "16px",
                  backgroundColor:
                    index % 2 === 0 ? COLORS_PALETTE.cardLightPurple : "white",
                  borderRadius: 24,
                  padding: 3,
                }}
              >
                <img src={choice} style={{ width: 100, height: 100 }} />
                <div style={{ marginLeft: "16px", flexGrow: 1 }}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <span>
                      {round(questionsStatuses[questionId][choice], 1)}%
                    </span>
                  </div>
                  <LinearProgress
                    variant="determinate"
                    value={round(questionsStatuses[questionId][choice], 1)}
                    sx={{ marginTop: "8px", height: 10, borderRadius: 5 }}
                  />
                </div>
              </div>
            );
          })}
      </div>
    );
  } else if (
    question.type === "one_of_many" ||
    question.type === "yes_no" ||
    question.type === "many_of_many"
  ) {
    return (
      <ResponsiveBar
        theme={{
          fontSize: 14,
        }}
        data={[questionsStatuses[questionId]]}
        keys={Object.keys(questionsStatuses[questionId]).filter(
          (k) => k !== "dummy_by"
        )}
        indexBy="dummy_by"
        margin={{
          top: 10,
          right: 10,
          bottom: window.innerHeight / 2,
          left: 30,
        }}
        padding={0.2}
        layout="horizontal"
        // valueScale={{ type: "linear" }}
        // indexScale={{ type: "band", round: true }}
        // colors={{ scheme: "nivo" }}
        colors={GRAPH_COLORS}
        borderRadius={7}
        borderColor={{
          from: "color",
          modifiers: [["darker", 1.6]],
        }}
        legends={[
          {
            dataFrom: "keys",

            anchor: "bottom-left",
            direction: "column",
            justify: false,
            translateX: 0,
            translateY: window.innerHeight / 8,
            itemsSpacing: 2,
            itemWidth: 100,
            itemHeight: 20,
            itemDirection: "left-to-right",
            itemOpacity: 0.85,
            symbolSize: 20,
            fontSize: 18,
            effects: [
              {
                on: "hover",
                style: {
                  itemOpacity: 1,
                },
              },
            ],
          },
        ]}
        labelSkipWidth={36}
        labelSkipHeight={12}
        enableGridY={false}
        enableGridX={false}
        Label={(d) => `${d.id}: ${d.value}`}
        labelTextColor="white"
        axisBottom={false}
        axisLeft={false}
        valueFormat={(e) => [e.id] + " " + round(e) + "%"}
        enableLabel={true} // disable labels on the bars
        animate={true} // Enable animations for the chart
        tooltip={(e) => {
          return (
            // custom code to have beautiful tooltips
            <div
              style={{
                background: "white",
                padding: "9px 12px",
                border: "1px solid #ccc",
              }}
            >
              <div style={{ fontSize: "12px" }}>
                {[e.id]}: {round(e.data[e.id])}%
              </div>
            </div>
          );
        }}
        tooltipLabel={(e) => {
          return (
            <div
              style={{
                background: "black",
                padding: "9px 12px",
                border: "1px solid #ccc",
              }}
            >
              <div style={{ fontSize: "12px" }}>
                {[e.id]}: {round(e.data[e.id])}%
              </div>
            </div>
          );
        }}
      />
    );
  } else if (question.type === "sortable") {
    const processed_data = calculateSortableAnswerStats(
      questionsStatuses[question.id],
      question.metadata.choices,
      question.metadata.images
    );

    function calculateChoiceScore(choiceIndex, totalChoices, percentage) {
      return (totalChoices - choiceIndex) * (percentage / totalChoices);
    }

    function mapScoreToTier(score) {
      if (score >= 90) {
        return "S";
      } else if (score >= 75) {
        return "A";
      } else if (score >= 60) {
        return "B";
      } else if (score >= 45) {
        return "C";
      } else if (score >= 30) {
        return "D";
      } else {
        return "F";
      }
    }

    function summarizeRatings2(choicesData, ratingsData) {
      const summary = {};

      Object.keys(ratingsData).forEach((choiceKey) => {
        const choices = choiceKey.split(",").map((choice) => choice.trim());
        const percentage = ratingsData[choiceKey] / 100;

        choices.forEach((choice, index) => {
          if (!summary[choice]) {
            summary[choice] = {
              totalScore: 0,
              count: 0,
            };
          }

          const score = calculateChoiceScore(index, choices.length, percentage);
          summary[choice].totalScore += score;
          summary[choice].count++;
        });
      });

      for (const choice in summary) {
        summary[choice].averageScore =
          summary[choice].totalScore / summary[choice].count;
        summary[choice].rating = mapScoreToTier(
          summary[choice].totalScore * 100
        );
      }

      return summary;
    }

    const processed_data_summary2 = summarizeRatings2(
      question.metadata.choices,
      questionsStatuses[question.id]
    );
    return (
      <div>
        {activeTab === "Summary" ? (
          <CardContent style={PAPER_CARD_STYLE_2}>
            <Grid container spacing={2}>
              {/* generate table for each item in processed_data_summary2 */}
              {Object.keys(processed_data_summary2)
                .sort(
                  (a, b) =>
                    processed_data_summary2[b].totalScore -
                    processed_data_summary2[a].totalScore
                )
                .map((choice) => {
                  return (
                    <Grid item xs={12}>
                      <Grid container spacing={0}>
                        <Grid item xs={2}>
                          <Typography
                            variant="body1"
                            style={{
                              color: COLORS_PALETTE.paletteText5,
                              textAlign: "center",
                              paddingLeft: "10px",
                            }}
                          >
                            Rated
                          </Typography>

                          <Typography
                            variant="h5"
                            style={{
                              textAlign: "center",
                              paddingLeft: "10px",
                            }}
                          >
                            {processed_data_summary2[choice]["rating"]}
                          </Typography>
                        </Grid>
                        {/* add vertical divider: */}
                        <Grid item xs={1}>
                          <Divider orientation="vertical" />
                        </Grid>
                        <Grid item xs={9}>
                          {!isPhotoQuestion && (
                            <Typography
                              variant="body2"
                              color="textSecondary"
                              align="center"
                              style={{
                                color: COLORS_PALETTE.paletteText5,
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              {choice}
                            </Typography>
                          )}
                          {isPhotoQuestion && (
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <img
                                src={question.metadata.images[choice]?.url}
                                style={{
                                  height: "100px",
                                  width: "100px",
                                }}
                                alt="choice"
                              />
                            </div>
                          )}
                        </Grid>
                      </Grid>
                      <Divider style={{ marginTop: "15px" }} />
                    </Grid>
                  );
                })}
            </Grid>
          </CardContent>
        ) : (
          <ResponsiveBar
            data={processed_data}
            keys={question.metadata.choices}
            theme={{
              fontSize: 14,
              // make it bold font:
            }}
            indexBy="choice_index"
            margin={{
              top: 10,
              right: 10,
              bottom: window.innerHeight / 3,
              left: 55,
            }}
            padding={0.2}
            layout="horizontal"
            // valueScale={{ type: "linear" }}
            // indexScale={{ type: "band", round: true }}
            labelSkipWidth={100}
            colors={GRAPH_COLORS}
            labelTextColor="white"
            borderRadius={7}
            borderColor={{
              from: "color",
              modifiers: [["darker", 1.6]],
            }}
            legends={[
              {
                dataFrom: "keys",
                anchor: "bottom-left",
                direction: "column",
                justify: false,
                translateX: 0,
                translateY: window.innerHeight / 4,
                itemsSpacing: 2,
                itemWidth: 100,
                itemHeight: 20,
                itemDirection: "left-to-right",
                itemOpacity: 0.85,
                symbolSize: 20,
                fontSize: 18,
                effects: [
                  {
                    on: "hover",
                    style: {
                      itemOpacity: 1,
                    },
                  },
                ],
              },
            ]}
            enableGridY={false}
            enableGridX={false}
            Label={(d) => `${d.id}: ${round(d.value)}`}
            axisBottom={false}
            axisLeft={true}
            label={(d) => `${d.id}: ${round(d.value)}%`}
            // valueFormat={(e) => ""+round(e) + "%"}
            // enableLabel={true} // disable labels on the bars
            // animate={true} // Enable animations for the chart

            tooltip={(e) => {
              return (
                // custom code to have beautiful tooltips
                <div
                  style={{
                    background: "white",
                    padding: "9px 12px",
                    border: "1px solid #ccc",
                  }}
                >
                  <div style={{ fontSize: "12px" }}>
                    {[e.id]}: {round(e.data[e.id])}%
                  </div>
                </div>
              );
            }}
          />
        )}
      </div>
    );
  } else if (question.type === "number_ranged") {
    function generateSummary(frequencyMap) {
      let values = [];
      let totalWeight = 0;
      let weightedSum = 0;
      let mostCommon = null;
      let maxFrequency = 0;

      Object.entries(frequencyMap).forEach((entry) => {
        const choice = entry[1]["x"];
        const frequency = entry[1]["y"];

        values = values.concat(Array(frequency).fill(choice));

        totalWeight += frequency;
        weightedSum += choice * frequency;

        if (frequency > maxFrequency) {
          mostCommon = choice;
          maxFrequency = frequency;
        }
      });

      const weightedAverage = weightedSum / totalWeight;
      const average = calculateMean(values);
      const median = calculateMedian(values);
      const min = Math.min(...values);
      const max = Math.max(...values);
      const lowerQuartile = calculateQuartile(values, 0.25);
      const upperQuartile = calculateQuartile(values, 0.75);
      const interquartileRange = upperQuartile - lowerQuartile;

      return {
        "Weighted Average": weightedAverage.toFixed(0),
        "Most Common": mostCommon,
        Average: average.toFixed(0),
        Median: median,
        Minimum: min,
        Maximum: max,
        "Lower Quartile": lowerQuartile,
        "Upper Quartile": upperQuartile,
        "Interquartile Range": interquartileRange,
      };
    }

    // Calculate mean function
    function calculateMean(values) {
      const sum = values.reduce((total, value) => total + value, 0);
      return sum / values.length;
    }

    // Calculate median function
    function calculateMedian(values) {
      const sortedValues = values.slice().sort((a, b) => a - b);
      const middleIndex = Math.floor(sortedValues.length / 2);
      if (sortedValues.length % 2 === 0) {
        return (sortedValues[middleIndex - 1] + sortedValues[middleIndex]) / 2;
      } else {
        return sortedValues[middleIndex];
      }
    }

    // Calculate quartile function
    function calculateQuartile(values, quantile) {
      const sortedValues = values.slice().sort((a, b) => a - b);
      const index = Math.floor(quantile * (sortedValues.length - 1));
      return sortedValues[index];
    }

    //

    const processRangedData = generateSummary(questionsStatuses[question.id]);
    return (
      <div>
        <CardContent style={PAPER_CARD_STYLE_2}>
          <Grid container spacing={2}>
            {/* for each value in processRangedData add it to here: */}
            <Grid item xs={4} align="center">
              {/* Value 1 */}
              <Typography variant="h4" align="center">
                {processRangedData["Average"]}
              </Typography>
              {/* Secondary Label 1 */}
              <Typography variant="body2" color="textSecondary" align="center">
                Average
              </Typography>
            </Grid>

            <Grid item xs={4} align="center">
              {/* Value 1 */}
              <Typography variant="h4" align="center">
                {processRangedData["Most Common"]}
              </Typography>
              {/* Secondary Label 1 */}
              <Typography variant="body2" color="textSecondary" align="center">
                Most Common
              </Typography>
            </Grid>
            <Grid item xs={4} align="center">
              {/* Value 1 */}
              <Typography variant="h4" align="center">
                {processRangedData["Median"]}
              </Typography>
              {/* Secondary Label 1 */}
              <Typography variant="body2" color="textSecondary" align="center">
                Median
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
        <CardContent style={PAPER_CARD_STYLE_2}>
          <Grid container spacing={2}>
            {/* for each value in processRangedData add it to here: */}
            <Grid item xs={6} align="center">
              {/* Value 1 */}
              <Typography variant="h4" align="center">
                {processRangedData["Maximum"]}
              </Typography>
              {/* Secondary Label 1 */}
              <Typography variant="body2" color="textSecondary" align="center">
                Maximum
              </Typography>
            </Grid>

            <Grid item xs={6} align="center">
              {/* Value 1 */}
              <Typography variant="h4" align="center">
                {processRangedData["Minimum"]}
              </Typography>
              {/* Secondary Label 1 */}
              <Typography variant="body2" color="textSecondary" align="center">
                Minimum
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
        <CardContent style={PAPER_CARD_STYLE_2}>
          <Grid container spacing={2}>
            {/* for each value in processRangedData add it to here: */}
            <Grid item xs={6} align="center">
              {/* Value 1 */}
              <Typography variant="h4" align="center">
                {processRangedData["Upper Quartile"]}
              </Typography>
              {/* Secondary Label 1 */}
              <Typography variant="body2" color="textSecondary" align="center">
                Upper Quartile
              </Typography>
            </Grid>

            <Grid item xs={6} align="center">
              {/* Value 1 */}
              <Typography variant="h4" align="center">
                {processRangedData["Lower Quartile"]}
              </Typography>
              {/* Secondary Label 1 */}
              <Typography variant="body2" color="textSecondary" align="center">
                Lower Quartile
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
      </div>
      // <ResponsiveScatterPlot
      //   theme={{
      //     fontSize: 18,
      //     // make it bold font:
      //   }}
      //   data={[
      //     {
      //       id: "Answer",
      //       // color: "hsl(162, 70%, 50%)",
      //       data: questionsStatuses[questionId].sort((a, b) =>
      //         a.x <= b.x ? -1 : 1
      //       ),
      //     },
      //   ]}
      //   curve="natural"
      //   enableArea={true}
      //   margin={{
      //     top: 50,
      //     right: 80,
      //     bottom: window.innerHeight / 4,
      //     left: 60,
      //   }}
      //   xScale={{ type: "linear", nice: true, min: "auto", max: "auto" }}
      //   yScale={{
      //     type: "linear",
      //     min: "auto",
      //     max: "auto",
      //     stacked: true,
      //     reverse: false,
      //   }}
      //   colors={GRAPH_COLORS}
      //   yFormat=" >-.2f"
      //   axisTop={null}
      //   axisRight={null}
      //   axisBottom={{
      //     // tickValues: questionsStatuses[questionId].map((e) => {
      //     //   // calculate the max and min for the x axis in on shot:
      //     //   let max, min;
      //     //   questionsStatuses[questionId].forEach((e) => {
      //     //     if (max === undefined || e.x > max) {
      //     //       max = e.x;
      //     //     }
      //     //     if (min === undefined || e.x < min) {
      //     //       min = e.x;
      //     //     }
      //     //   });
      //     //   return min, max - min / 2, max;
      //     // }),
      //     tickSize: 5,
      //     tickPadding: 5,
      //     tickRotation: 0,
      //     legend: "Answer",
      //     legendOffset: 36,
      //     legendPosition: "middle",
      //   }}
      //   axisLeft={{
      //     tickSize: 5,
      //     tickPadding: 5,
      //     tickRotation: 0,
      //     legend: "count",
      //     legendOffset: -40,
      //     legendPosition: "middle",
      //   }}
      //   pointSize={10}
      //   pointColor={{ theme: "background" }}
      //   pointBorderWidth={2}
      //   pointBorderColor={{ from: "serieColor" }}
      //   pointLabelYOffset={-12}
      //   useMesh={false}
      //   enablePoints={false}
      // />
    );
  } else if (question.type === "emoji") {
    const calculateEmojiAnswerStats = (data) => {
      // data : {"dummy_by":"dummy_by","🤗":100}
      // find the key with the highest value in data :
      const mostCommon = Object.keys(data).reduce((a, b) =>
        data[a] > data[b] ? a : b
      );
      const leastCommon = Object.keys(data).reduce((a, b) =>
        data[a] < data[b] ? a : b
      );
      return {
        "Most Common": mostCommon,
        "Least Common": leastCommon,
      };
    };
    const processed_data = calculateEmojiAnswerStats(
      questionsStatuses[question.id]
    );
    return (
      <div>
        <CardContent style={PAPER_CARD_STYLE_2}>
          <Grid container spacing={2}>
            {/* for each value in processRangedData add it to here: */}
            <Grid item xs={6} align="center">
              {/* Value 1 */}
              <Typography variant="body2" color="textSecondary" align="center">
                Most Common
              </Typography>
              {/* Secondary Label 1 */}
              <Typography variant="h4" color="textSecondary" align="center">
                {processed_data["Most Common"]}
              </Typography>
              <Typography variant="body" align="center">
                {questionsStatuses[question.id][
                  processed_data["Most Common"]
                ].toFixed(0)}
                {"%"}
              </Typography>
            </Grid>

            <Grid item xs={6} align="center">
              {/* Value 1 */}
              <Typography variant="body2" color="textSecondary" align="center">
                Least Common
              </Typography>
              {/* Secondary Label 1 */}
              <Typography variant="h4" color="textSecondary" align="center">
                {processed_data["Least Common"] ===
                processed_data["Most Common"]
                  ? "--"
                  : processed_data["Least Common"]}
              </Typography>
              <Typography variant="body" align="center">
                {processed_data["Least Common"] ===
                processed_data["Most Common"]
                  ? "--"
                  : questionsStatuses[question.id][
                      processed_data["Least Common"]
                    ].toFixed(0) + "%"}
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
        <Typography
          variant="body1"
          color={COLORS_PALETTE.paletteText5}
          style={{ marginBottom: 5 }}
        >
          Break down by Emoji:
        </Typography>
        <CardContent>
          <Grid container spacing={2}>
            {/* for each value in processRangedData add it to here: */}
            {Object.keys(questionsStatuses[question.id])
              .sort(
                (a, b) =>
                  questionsStatuses[question.id][b] -
                  questionsStatuses[question.id][a]
              )
              .map((emoji, index) => {
                if (emoji === "dummy_by") return;
                return (
                  <Grid
                    container
                    xs={12}
                    justify="center"
                    alignItems="center"
                    style={{
                      marginBottom: 5,
                      padding: 5,
                      backgroundColor: index % 2 === 1 ? "#f5f5f5" : "white",
                      borderRadius: 5,
                    }}
                  >
                    <Grid item xs={3} align="center">
                      <Typography variant="h7" align="center">
                        {emoji}
                      </Typography>
                    </Grid>
                    <Grid item xs={9} align="center">
                      <Typography variant="body" align="center">
                        {questionsStatuses[question.id][emoji].toFixed(0) + "%"}
                      </Typography>
                    </Grid>
                  </Grid>
                );
              })}
          </Grid>
        </CardContent>
      </div>
    );
  } else if (question.type === "one_word") {
    const calculateEmojiAnswerStats = (data) => {
      // data : {"dummy_by":"dummy_by","🤗":100}
      // find the key with the highest value in data :
      const mostCommon = Object.keys(data).reduce((a, b) =>
        data[a] > data[b] ? a : b
      );
      const leastCommon = Object.keys(data).reduce((a, b) =>
        data[a] < data[b] ? a : b
      );
      return {
        "Most Common": mostCommon,
        "Least Common": leastCommon,
      };
    };
    const processed_data = calculateEmojiAnswerStats(
      questionsStatuses[question.id]
    );
    return (
      <div>
        <CardContent style={PAPER_CARD_STYLE_2}>
          <Grid container spacing={2}>
            {/* for each value in processRangedData add it to here: */}
            <Grid item xs={6} align="center">
              {/* Value 1 */}
              <Typography variant="body2" color="textSecondary" align="center">
                Most Common
              </Typography>
              {/* Secondary Label 1 */}
              <Typography variant="h6" color="textSecondary" align="center">
                {processed_data["Most Common"]}
              </Typography>
              <Typography variant="body" align="center">
                {questionsStatuses[question.id][
                  processed_data["Most Common"]
                ].toFixed(0)}
                {"%"}
              </Typography>
            </Grid>

            <Grid item xs={6} align="center">
              {/* Value 1 */}
              <Typography variant="body2" color="textSecondary" align="center">
                Least Common
              </Typography>
              {/* Secondary Label 1 */}
              <Typography variant="h6" color="textSecondary" align="center">
                {processed_data["Least Common"] ===
                processed_data["Most Common"]
                  ? "--"
                  : processed_data["Least Common"]}
              </Typography>
              <Typography variant="body" align="center">
                {processed_data["Least Common"] ===
                processed_data["Most Common"]
                  ? "--"
                  : questionsStatuses[question.id][
                      processed_data["Least Common"]
                    ].toFixed(0) + "%"}
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
        <Typography
          variant="body1"
          color={COLORS_PALETTE.paletteText5}
          style={{ marginBottom: 5 }}
        >
          Break down by Word:
        </Typography>
        <CardContent>
          <Grid container spacing={2}>
            {/* for each value in processRangedData add it to here: */}
            {Object.keys(questionsStatuses[question.id])
              .sort(
                (a, b) =>
                  questionsStatuses[question.id][b] -
                  questionsStatuses[question.id][a]
              )
              .map((emoji, index) => {
                if (emoji === "dummy_by") return;
                return (
                  <Grid
                    container
                    xs={12}
                    justify="center"
                    alignItems="center"
                    style={{
                      marginBottom: 5,
                      padding: 5,
                      backgroundColor: index % 2 === 1 ? "#f5f5f5" : "white",
                      borderRadius: 5,
                    }}
                  >
                    <Grid item xs={3} align="center">
                      <Typography variant="h7" align="center">
                        {emoji}
                      </Typography>
                    </Grid>
                    <Grid item xs={9} align="center">
                      <Typography variant="body" align="center">
                        {questionsStatuses[question.id][emoji].toFixed(0) + "%"}
                      </Typography>
                    </Grid>
                  </Grid>
                );
              })}
          </Grid>
        </CardContent>
      </div>
    );
  } else {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
        }}
      >
        <Typography variant="h6" align="center">
          No statistic support for this question type yet! We are working on it.
        </Typography>
      </div>
    );
  }
}

export default AnswerStatsComponent;
