import React, { useState } from "react";
import {
  TextField,
  Button,
  Grid,
  Typography,
  Paper,
  Card,
} from "@mui/material";
import { GoogleLoginButton } from "react-social-login-buttons";
import { API_URL } from "../../Config";
import { useNavigate } from "react-router-dom";
import { max, set } from "lodash";
const Signup = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const defaultData = {
    email: "admin@email.com",
    password: "admin1",
  };
  const [data, setData] = useState(defaultData);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [loginError, setLoginError] = useState(null);
  const navigate = useNavigate();
  const handleSubmit = (event) => {
    event.preventDefault();
    return fetch(API_URL + "/users/login", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ user: data }),
    }).then((response) => {
      if (response.status === 200) {
        return response.json().then((data) => {
          localStorage.setItem("vqa_token", data.token);
          localStorage.setItem("vqa_expiration", data.expire);
          setIsLoggedIn(true);
          navigate("/dashboard");
        });
      }
      return response.json().then((error) => {
        setIsLoggedIn(false);
        setLoginError(error);
        // throw new Error(error.message);
        console.log(error);
      });
    });
  };
  const handleUsernameChange = (e) => {
    // emoji regex:
    const regex =
      /(\u00a9|\u00ae|[\u2000-\u3300]|\ud83c[\ud000-\udfff]|\ud83d[\ud000-\udfff]|\ud83e[\ud000-\udfff])/;
    const regex1 =
      /^(\u00a9|\u00ae|[\u2000-\u3300]|\ud83c[\ud000-\udfff]|\ud83d[\ud000-\udfff]|\ud83e[\ud000-\udfff])$/;
    // allow one emoji and no space, anothing but one emoji:
    // regex to validate emoji :
    if (regex1.test(e.target.value)) {
      setUsername(e.target.value);
      setData({ ...data, username: e.target.value });
    } else if (!regex1.test(e.target.value)) {
      setUsername("");
    }
    // validate no space and single emoji:
    // regex to validate emoji :

    // if (!regex.test(e.target.value)) {
    // }
  };
  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
    setData({ ...data, password: e.target.value });
  };
  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    setData({ ...data, email: e.target.value });
  };

  const handleLogin = () => {
    // Implement your login logic her
  };

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      style={{ minHeight: "100vh" }}
    >
      <Grid item xs={10} sm={6} md={4}>
        <Paper elevation={3} style={{ padding: "20px" }}>
          <Typography variant="h5" gutterBottom>
            Sign up
          </Typography>
          <TextField
            label="Username"
            variant="outlined"
            fullWidth
            margin="normal"
            value={username}
            onChange={handleUsernameChange}
          />
          <TextField
            label="Email"
            variant="outlined"
            fullWidth
            margin="normal"
            value={email}
            onChange={handleEmailChange}
          />
          <TextField
            label="Password"
            type="password"
            variant="outlined"
            fullWidth
            margin="normal"
            value={password}
            onChange={handlePasswordChange}
          />
          <Button
            variant="contained"
            color="primary"
            fullWidth
            size="large"
            onClick={handleSubmit}
          >
            Sign Up
          </Button>
        </Paper>

        <Card
          style={{ marginTop: "20px", padding: "20px", position: "relative" }}
        >
          <Grid>
            <Grid item xs={6}>
              <Typography variant="h6" gutterBottom>
                Wanna Know
              </Typography>
            </Grid>
            <Grid item xs={6}>
              {[...Array(4)].map((e, i) => (
                <Typography
                  variant="h3"
                  gutterBottom
                  style={{
                    position: "absolute",
                    bottom: 0,
                    right: Math.random() * 20 + 10,
                    // random transform origin:
                    transformOrigin: `${Math.floor(
                      Math.random() * 100
                    )}% ${Math.floor(Math.random() * 100)}%`,

                    transform: `rotate(${
                      Math.floor(Math.random() * 70) - 35
                    }deg)`,
                    display: "inline-block",
                    opacity: `${Math.random()}`,
                  }}
                >
                  ?
                </Typography>
              ))}
            </Grid>
            <Grid item xs={12}>
              {[...Array(4)].map((e, i) => (
                <Typography
                  variant="h3"
                  gutterBottom
                  style={{
                    position: "absolute",
                    bottom: 0,
                    right: Math.random() * 20 + 10,
                    // random transform origin:
                    transformOrigin: `${Math.floor(
                      Math.random() * 100
                    )}% ${Math.floor(Math.random() * 100)}%`,

                    transform: `rotate(${
                      Math.floor(Math.random() * 70) - 35
                    }deg)`,
                    display: "inline-block",
                    opacity: `${Math.random()}`,
                  }}
                >
                  ?
                </Typography>
              ))}
            </Grid>
          </Grid>
        </Card>

        <Card
          style={{ marginTop: "20px", padding: "20px", position: "relative" }}
        >
          <Grid style={{ minHeight: 40 }}>
            <Grid item xs={12}>
              {[
                "How?",
                "Wanna know.",
                "Do you?",
                "How often?",
                "Wow!",
                "Impressive!",
                "Secret?",
                "Cool!",
                "Details?",
                "Show me.",
                "Wow!",
                "Unbelievable!",
              ]
                .slice(Math.floor(Math.random() * 5), 5)

                .map((e, i) => (
                  <Typography
                    variant="h5"
                    gutterBottom
                    style={{
                      position: "absolute",
                      bottom: 0,
                      right: Math.random() * 50 + 10,
                      // random transform origin:
                      fontSize: `${Math.random() * 2 + 1}em`,
                      transformOrigin: `${Math.floor(
                        Math.random() * 0
                      )}% ${Math.floor(Math.random() * 100)}%`,

                      transform: `rotate(${
                        Math.floor(Math.random() * 70) - 35
                      }deg)`,
                      display: "inline-block",
                      // random opacity between 0.2 and 0.6
                      opacity: `${Math.random() * 0.4 + 0.2}`,
                    }}
                  >
                    {e}
                  </Typography>
                ))}
            </Grid>
          </Grid>
        </Card>

        <Card
          style={{ marginTop: "20px", padding: "20px", position: "relative" }}
        >
          <Grid>
            <Grid item xs={6}>
              <Typography variant="h6" gutterBottom>
                Wanna Know
              </Typography>
            </Grid>

            <Grid item xs={12}>
              {/* list of 10 emojis with emotions */}
              {[
                "😀",
                "😃",
                "😄",
                "😁",
                "😆",
                "😅",
                "😂",
                "🤣",
                "😊",
                "😇",
                "😍",
                "🌟",
                "💯",
                "🔥",
                "💥",
                "🚀",
                "🎉",
                "👏",
                "🙌",
                "🌈",
                "💪",
                "💎",
                "🎁",
                "📣",
                "🌺",
                "🍕",
                "🍦",
                "🥂",
              ]
                // shuffle the array:
                .sort(() => Math.random() - 0.5)

                .slice(Math.floor(Math.random() * 5), 5)
                .map((e, i) => (
                  <Typography
                    variant="h3"
                    gutterBottom
                    style={{
                      position: "absolute",
                      bottom: 0,
                      right: Math.random() * 90 + 40 * i,
                      // random transform origin:
                      transformOrigin: `${Math.floor(
                        Math.random() * 100
                      )}% ${Math.floor(Math.random() * 100)}%`,

                      transform: `rotate(${
                        Math.floor(Math.random() * 70) - 35
                      }deg)`,
                      display: "inline-block",
                      opacity: `${Math.random()}`,
                      fontSize: `${Math.random() * 2 + 1}em`,
                    }}
                  >
                    {e}
                  </Typography>
                ))}
            </Grid>
          </Grid>
        </Card>
      </Grid>
    </Grid>
  );
};

export default Signup;
