import Footer from "./Components/Footer";
import First from "./Slides/First";
import Second from "./Slides/Second";
import Third from "./Slides/Third";

export default function LandingView() {
  return (
    <div>
      <First />
      <Second />
      <Third />

      {/* Add version in very small font in footer with the copyrights: */}
      {/* <div style={{ fontSize: "6px", textAlign: "center" }}>
        <span>WK:{process.env.REACT_APP_BUILD_VERSION}</span>
      </div> */}
      <Footer />
    </div>
  );
}
