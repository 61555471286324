import { Box, Slider, Typography } from "@mui/material";
import React, { useState } from "react";
import CustomizedRating from "./RatingComponent";

const RangeComponent = ({ onChange, min, max }) => {
  const [sliderValue, setSliderValue] = useState(50);

  const handleChange = (event, newValue) => {
    onChange(newValue);
    setSliderValue(newValue);
  };

  return (
    <Box width={"100%"} mt={3}>
      {/* <Typography id="slider-label" gutterBottom>
        Slider Value: {sliderValue}
      </Typography> */}

      <Slider
        // value={sliderValue}
        onChange={handleChange}
        // aria-labelledby="slider-label"
        color="primary"
        valueLabelDisplay="on"
        step={1}
        size="large"
        marks
        min={min}
        max={max}
        style={{
          track: {
            height: 24,
            borderRadius: 24,
          },
          rail: {
            height: 24,
            borderRadius: 24,
            opacity: 1,
          },
        }}
      />
    </Box>
  );
};

export default RangeComponent;
