import { useState, useEffect } from "react";
import { Box, Typography } from "@mui/material";
import { ANIMATION_SPEED, GRADIENTS } from "../constants";
import BetaAccessButton from "../Components/BetaAccessButton";

const spans = [
  <span>Ask, </span>,
  <span>Share, </span>,
  <span>&&nbsp;See.</span>,
];

export default function First() {
  const [date, setDate] = useState(new Date());

  useEffect(() => {
    const timerID = setInterval(() => {
      setDate(new Date());
    }, 1000);

    return () => {
      clearInterval(timerID);
    };
  }, []);

  const index = Math.floor(date.getSeconds() / ANIMATION_SPEED) % 3;

  return (
    <Box
      sx={{
        display: "grid",
        placeItems: "center",
        textAlign: "center",
        background:
          "-webkit-linear-gradient(270deg, #FFFFFF 60%, #F4EFF4 100%)",
        color: "#000",
        minHeight: "80vh",
        width: "100vw",
        padding: "16px",
        boxSizing: "border-box",
      }}
    >
      <Box
        sx={{
          maxWidth: "1200px",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Typography
          component="h1"
          sx={{
            fontSize: "clamp(72px, 14.9vw, 112px)",
            fontWeight: "800",
            lineHeight: "clamp(76px, 15.7vw, 114px)",
            textAlign: "center",
            position: "relative",
          }}
        >
          <Box
            sx={{
              position: "absolute",
              zIndex: "0",
            }}
          >
            {spans.map((span, i) => (
              <span key={i}>{span}</span>
            ))}
          </Box>
          <div>
            {spans.map((span, i) => (
              <span
                key={i}
                style={{
                  ...{
                    background:
                      "linear-gradient(90deg, #be22ff 0%, #ff279c 100%)",
                  },
                  position: "relative",
                  WebkitBackgroundClip: "text",
                  WebkitTextFillColor: "transparent",
                  transition: `opacity ${ANIMATION_SPEED / 4}s ease-in-out`,
                  background: GRADIENTS[i],
                  opacity: index === i ? 1 : 0,
                }}
              >
                {span}
              </span>
            ))}
          </div>
        </Typography>
        <p
          style={{
            fontSize: "clamp(20px, 3.1vw, 28px)",
            fontWeight: "500",
            opacity: "0.6",
            lineHeight: "clamp(28px, 4.1vw, 36px)",
          }}
        >
          Discover a world of curiosity, Dive deep into the answers, and get
          fascinating insights!
        </p>

        <BetaAccessButton />
      </Box>
    </Box>
  );
}
