import { useParams, useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import ShareIcon from "@mui/icons-material/Share";

import BarChartIcon from "@mui/icons-material/BarChart";
import PauseIcon from "@mui/icons-material/Pause";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import {
  pauseFlowUserByFlowId,
  resumeFlowUserByFlowId,
  getAllFlows,
  getAllUsedFlows,
  postCloneFlowByFlowId,
} from "../../Utils/fetchers";
import { useNavigate } from "react-router-dom";
import {
  Autocomplete,
  Button,
  ButtonGroup,
  Card,
  CardContent,
  Grid,
  TextField,
  Typography,
  Chip,
  Snackbar,
  Container,
} from "@mui/material";
import { formatDistanceToNow, formatDistanceToNowStrict } from "date-fns";
import {
  BUTTON_CARD_STYLE_1,
  COLORS_PALETTE,
  COLOR_PALLETTE_2,
  PAPER_CARD_STYLE_1,
  PAPER_CARD_STYLE_2,
} from "../../Utils/static";
import "./FlowCompletedView.css";
import {
  Opacity,
  Replay,
  SlowMotionVideo,
  Visibility,
} from "@mui/icons-material";
import { copyToClipboard } from "../../Utils/helper";
import { ViewTitle } from "Components/ViewTitle";
import CreateNewButtonDropdown from "Views/MainView/Components/CreateNewButtonDropdown";
import FlowSettingsDialog from "Dialogs/FlowSettingsDialog";

const FETCH_LIMIT = 10;

const flowSorter = (a, b) => {
  // Compare expire properties first
  if (a.is_expired === b.is_expired) {
    // If expire properties are equal, compare dates in descending order (newer dates come first)
    // return new Date(b.date) - new Date(a.date);
    return b.id - a.id;
  } else {
    // If expire properties are different, put `false` before `true`
    return a.is_expired ? 1 : -1;
  }
};

function FlowViewList() {
  // const [flows, setFlows] = useState([]);
  const [ownedFlows, setOwnedFlows] = useState([]);
  const [usedFlows, setUsedFlows] = useState([]);
  const [usedFlowsFiltered, setUsedFlowsFiltered] = useState([]);
  const [showCopiedSnackbar, setShowCopiedSnackbar] = useState(false);
  const [scrollYState, setScrollYState] = useState(0);
  const [flowOffset, setFlowOffset] = useState(0);
  const [hasMoreToFetch, setHasMoreToFetch] = useState(true);
  const [showFlowSettingsDialog, setShowFlowSettingsDialog] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    fetchUsedFlows(flowOffset, FETCH_LIMIT, true);
  }, []);

  useEffect(() => {
    // scroll when
    if (flowOffset > 0) {
      window.scrollTo(0, scrollYState);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [usedFlowsFiltered]);

  let { flowId } = useParams();

  const fetchUsedFlows = (offset, limit, override) => {
    getAllUsedFlows(offset, limit).then((response) => {
      if (response.status === 200) {
        response.json().then((data) => {
          let newFlows;
          if (override) {
            newFlows = data;
          } else {
            newFlows = [...usedFlows, ...data];
          }
          setUsedFlows(newFlows);
          setUsedFlowsFiltered(newFlows);
          if (data.length < FETCH_LIMIT) {
            setHasMoreToFetch(false);
          }
        });
      }
    });
  };
  const consumeFlow = (flowId) => {
    postCloneFlowByFlowId(flowId).then((response) => {
      if (response.status === 200) {
        response.json().then((data) => {
          fetchUsedFlows(
            0,
            (flowOffset > 0 ? flowOffset : 1) * FETCH_LIMIT,
            true
          );
        });
      }
    });
  };

  const pauseClonedFlow = (flowId) => {
    pauseFlowUserByFlowId(flowId).then((response) => {
      if (response.status === 202) {
        fetchUsedFlows(
          0,
          (flowOffset > 0 ? flowOffset : 1) * FETCH_LIMIT,
          true
        );
      }
    });
  };
  const resumeClonedFlow = (flowId) => {
    resumeFlowUserByFlowId(flowId).then((response) => {
      if (response.status === 202) {
        fetchUsedFlows(
          0,
          (flowOffset > 0 ? flowOffset : 1) * FETCH_LIMIT,
          true
        );
      }
    });
  };
  const handleSearch = (event) => {
    const searchInput = event.target.value;
    const filteredFlows = usedFlows.filter((flow) =>
      String(flow.description)
        .toLowerCase()
        .includes(String(searchInput).toLowerCase())
    );
    setUsedFlowsFiltered(filteredFlows);
  };

  const FlowDetailsCard = ({ flow }) => {
    return (
      <Card
        variant="outlined"
        style={{
          margin: "16px",
          borderRadius: "16px",
          marginLeft: 0,
          marginRight: 0,
          padding: 8,
        }}
        id={"flow-card-container-" + flow.id}
        className=""
      >
        {/* First Row */}
        <CardContent style={{ padding: "8px" }}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              {/* Status */}

              <Chip
                size="medium"
                color={flow.is_expired ? "error" : "success"}
                label={flow.is_expired ? "Paused" : "Active"}
              ></Chip>
              {/* <Chip
                style={{
                  marginLeft: "5px",
                }}
                size="medium"
                label={flow.blueprint_type === 1 ? "General" : "Challenge"}
              ></Chip> */}
            </Grid>
            <Grid
              item
              xs={6}
              style={{ display: "flex", justifyContent: "flex-end" }}
            >
              {/* Relative Date */}
              {/* <Chip size="medium" label={flow.username}></Chip> */}
              <Chip
                size="medium"
                label={formatDistanceToNow(new Date(flow?.created_at)) + " ago"}
              ></Chip>
            </Grid>
          </Grid>
        </CardContent>

        {/* Second Row */}
        <CardContent style={{ padding: "10px" }}>
          {/* Flow Name */}
          <Typography variant="h6">{flow?.description}</Typography>
        </CardContent>

        {/* Third Row */}
        <CardContent style={PAPER_CARD_STYLE_2}>
          <Grid container spacing={2}>
            <Grid item xs={4} align="center">
              {/* Label 1 */}
              <Typography variant="body2" color="textSecondary" align="center">
                Today
              </Typography>
              {/* Value 1 */}
              <Typography variant="h6" align="center">
                {flow.today_answer ? flow.today_answer : 0}
              </Typography>
              {/* Secondary Label 1 */}
              <Typography variant="body2" color="textSecondary" align="center">
                Answers
              </Typography>
            </Grid>

            <Grid item xs={4}>
              {/* Label 2 */}
              <Typography variant="body2" color="textSecondary" align="center">
                Total
              </Typography>
              {/* Value 2 */}
              <Typography variant="h6" align="center">
                {flow.total_answer ? flow.total_answer : 0}
              </Typography>
              {/* Label 2 */}
              <Typography variant="body2" color="textSecondary" align="center">
                Answers
              </Typography>
            </Grid>
            <Grid item xs={4}>
              {/* Label 3 */}
              <Typography variant="body2" color="textSecondary" align="center">
                Last
              </Typography>
              {/* Value 3 */}
              <Typography variant="h6" align="center">
                {flow.last_answer
                  ? formatDistanceToNowStrict(new Date(flow.last_answer))
                  : "--"}
              </Typography>
              {/* Secondary Label 3 */}
              <Typography variant="body2" color="textSecondary" align="center">
                ago
              </Typography>
            </Grid>
          </Grid>
        </CardContent>

        {/* Fourth Row */}
        <CardContent style={{ padding: 5 }}>
          <ButtonGroup
            variant="text"
            aria-label="large button group"
            disableElevation
            fullWidth
          >
            <Button
              variant="text"
              color="primary"
              size="small"
              // style={BUTTON_CARD_STYLE_1}
              onClick={() => {
                if (flow.is_expired) {
                  resumeClonedFlow(flow.id);
                } else {
                  document
                    .getElementById(`flow-card-container-${flow.id}`)
                    .classList.add("list-item-remove");
                  setTimeout(() => {
                    pauseClonedFlow(flow.id);
                  }, 1200);
                }
                // setShowFlowSettingsDialog(true);
              }}
              fullWidth
              startIcon={flow.is_expired ? <PlayArrowIcon /> : <PauseIcon />}
            >
              {flow.is_expired ? "Resume" : "Pause"}
            </Button>
            <Button
              variant="text"
              color="primary"
              size="small"
              fullWidth
              // style={BUTTON_CARD_STYLE_1}
              onClick={() => {
                navigate("/preview/" + flow.shorten_url + "?viewOnly=true");
              }}
            >
              <Visibility />
              View
            </Button>

            {/* if blueprint type ==2 and no response yet show hi else show hello: */}
            {flow.blueprint_type === 2 && !flow.total_answer ? (
              <Button
                variant="text"
                color="primary"
                size="small"
                fullWidth
                disabled={flow.is_expired}
                // style={BUTTON_CARD_STYLE_1}
                onClick={(event) => {
                  event.preventDefault();
                  navigate("/f/" + flow.shorten_url + "?isOwner=true");
                }}
              >
                <SlowMotionVideo />
                Take
              </Button>
            ) : (
              <Button
                variant="text"
                color="primary"
                size="small"
                fullWidth
                disabled={flow.is_expired}
                // style={BUTTON_CARD_STYLE_1}
                onClick={(event) => {
                  event.preventDefault();
                  const flowURL =
                    window.location.protocol +
                    "//" +
                    window.location.host +
                    "/f/" +
                    flow.shorten_url;
                  copyToClipboard(flowURL);
                  setShowCopiedSnackbar(true);
                }}
              >
                <ShareIcon />
                Share
              </Button>
            )}

            <Button
              variant="text"
              color="primary"
              size="small"
              fullWidth
              // style={BUTTON_CARD_STYLE_1}
              onClick={(event) => {
                event.preventDefault();
                navigate("/s/f/" + flow.shorten_url);
              }}
            >
              <BarChartIcon />
              Stats
            </Button>
          </ButtonGroup>
        </CardContent>
      </Card>
    );
  };
  // if there is no content in the array, show message promting user to create a flow in by clicking the button and navigating /home :
  if (usedFlowsFiltered.length === 0) {
    return (
      <Container maxWidth="xs" style={{ marginTop: "8px" }}>
        <ViewTitle>My Inquiries</ViewTitle>

        <Card variant="outlined" style={{ borderRadius: "24px" }}>
          <CardContent>
            <Typography
              variant="body1"
              style={{ textAlign: "center", marginBottom: 50 }}
            >
              You don't have any Inquiries yet.
            </Typography>
            <CreateNewButtonDropdown />
          </CardContent>
        </Card>
      </Container>
    );
  }
  return (
    <Container maxWidth="xs" style={{ marginTop: "8px" }}>
      <ViewTitle>My Inquiries</ViewTitle>

      {usedFlowsFiltered.sort(flowSorter).map((flow) => (
        <FlowDetailsCard flow={flow} />
      ))}
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Button
          variant="contained"
          color="primary"
          size="large"
          style={{
            height: "56px",
            marginTop: "24px",
            // background transperant 80%
            backgroundColor: "rgba(255, 255, 255, 0.8)",
            color: COLORS_PALETTE.paletteClassy1,
            marginBottom: "24px",
          }}
          onClick={() => {
            // doublpicate usedFlowsFiltered:

            const currentYposition = Number(window.scrollY);
            const newOffset = flowOffset + FETCH_LIMIT;
            setScrollYState(currentYposition);
            // confirm we can fetch more:

            fetchUsedFlows(newOffset, FETCH_LIMIT, false);
            setFlowOffset(newOffset);

            // remove the first element:
          }}
          disabled={!hasMoreToFetch}
        >
          {hasMoreToFetch ? "Load More" : "No More Inquiries"}
        </Button>
      </div>
      <FlowSettingsDialog
        isOpen={showFlowSettingsDialog}
        flow={usedFlowsFiltered[0]}
        handleClose={() => {
          setShowFlowSettingsDialog(false);
        }}
      ></FlowSettingsDialog>
      <Snackbar
        open={showCopiedSnackbar}
        autoHideDuration={5000}
        onClose={() => setShowCopiedSnackbar(false)}
        message="Link Copied to Clipboard"
      />
    </Container>
  );
}
export default FlowViewList;
