import { API_URL } from "Config";
import AuthCardContainer from "UI/AuthCardContainer";
import PurplePinkButton from "UI/PurplePinkButton";
import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Button, Divider, TextField, Typography } from "@mui/material";
import { GetUserProfile } from "Utils/fetchers";
import { UserContext } from "Providers/UserContext";

const SignupView = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [displayName, setDisplayName] = useState("");

  const [data, setData] = useState({});
  const [usernameError, setUsernameError] = useState(null);
  const [passwordError, setPasswordError] = useState(null);
  const [emailError, setEmailError] = useState(null);
  const [displayNameError, setDisplayNameError] = useState(null);
  const { userProfile, setUserProfile } = useContext(UserContext);
  const navigate = useNavigate();

  const handleSubmit = (event) => {
    event.preventDefault();
    if (validateForm()) {
      return fetch(API_URL + "/users", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ user: data }),
      }).then((response) => {
        if (response.status === 201) {
          navigate("/signup/emailSent");

          // return response.json().then((d) => {
          //   fetch(API_URL + "/users/login", {
          //     method: "POST",
          //     headers: {
          //       "Content-Type": "application/json",
          //     },
          //     body: JSON.stringify({ user: data }),
          //   }).then((response) => {
          //     response.json().then((loginRes) => {
          //       localStorage.setItem("vqa_token", loginRes.token);
          //       localStorage.setItem("vqa_expiration", loginRes.expire);
          //       getUserProfileAndNavigateToHome();
          //     });
          //   });
          // });
        } else if (response.status === 409) {
          return response.json().then((error) => {
            setUsernameError("Username or email already exists");
          });
        } else if (response.status === 429) {
          return response.json().then((error) => {
            setUsernameError("Too many requests, please try again later");
          });
        }

        return response.json().then((error) => {
          console.log(error);
        });
      });
    }
  };

  const getUserProfileAndNavigateToHome = () => {
    GetUserProfile().then((data) => {
      data.json().then((data) => {
        setUserProfile(data.user);
        navigate("/home");
      });
    });
  };
  const handleUsernameChange = (e) => {
    setUsername(e.target.value);
    setData({ ...data, username: e.target.value });
    if (e.target.value.length < 4) {
      setUsernameError("Username should be at least 4 characters");
    } else {
      setUsernameError(null);
    }
  };

  const handlePasswordChange = (e) => {
    const password = e.target.value;
    setPassword(password);
    setData({ ...data, password });

    passwordValidationHandler(password, setPasswordError);
  };
  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    setData({ ...data, email: e.target.value });
    if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(e.target.value)) {
      setEmailError("Invalid email address");
    } else {
      setEmailError(null);
    }
  };
  const handleDisplayNameChange = (e) => {
    setDisplayName(e.target.value);
    setData({ ...data, displayName: e.target.value });
    if (e.target.value.length < 3) {
      setDisplayNameError("Display name should be at least 3 characters");
    } else if (!/^[a-zA-Z\s]*$/.test(e.target.value)) {
      // only Letters and spaces:
      setDisplayNameError("Display name should only contain letters");
    } else {
      setDisplayNameError(null);
    }
  };
  const validateForm = () => {
    if (username.length < 4) {
      setUsernameError("Username should be at least 4 characters");
      return false;
    }
    if (password.length < 8) {
      setPasswordError("Password should be at least 8 characters");
      return false;
    }

    if (password.length < 8) {
      setPasswordError("Password should be at least 8 characters long.");
      return false;
    }
    if (!/[A-Za-z]/.test(password)) {
      setPasswordError("Password should contain at least one letter.");
      return false;
    }
    if (!/\d/.test(password)) {
      setPasswordError("Password should contain at least one number.");
      return false;
    }
    if (!/[!@#$%^&*]/.test(password)) {
      setPasswordError(
        "Password should contain at least one special character (!@#$%^&*)."
      );
      return false;
    }

    if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email)) {
      setEmailError("Invalid email address");
      return false;
    }
    return true;
  };

  return (
    <AuthCardContainer>
      <Box
        sx={{
          display: "grid",
          gap: 2,
        }}
      >
        <TextField
          label="Username"
          variant="outlined"
          fullWidth
          value={username}
          onChange={handleUsernameChange}
          error={usernameError !== null}
          helperText={usernameError}
        />
        <TextField
          label="Display Name"
          variant="outlined"
          fullWidth
          value={displayName}
          onChange={handleDisplayNameChange}
          error={displayNameError !== null}
          helperText={displayNameError}
        />
        <TextField
          label="Email"
          variant="outlined"
          fullWidth
          value={email}
          onChange={handleEmailChange}
          error={emailError !== null}
          helperText={emailError}
        />
        <TextField
          label="Password"
          type="password"
          variant="outlined"
          fullWidth
          value={password}
          onChange={handlePasswordChange}
          error={passwordError !== null}
          helperText={passwordError}
          onKeyDown={(e) => {
            if (e.key === "Enter" && validateForm()) {
              handleSubmit();
            }
          }}
        />
        <PurplePinkButton
          fullWidth
          size="large"
          sx={{
            height: "56px",
          }}
          onClick={handleSubmit}
        >
          Sign Up
        </PurplePinkButton>
        <Divider />
        <Typography
          variant="body1"
          sx={{ textAlign: "center", color: "#6b6b6b", paddingTop: 1 }}
        >
          Already have an account?
        </Typography>
        <Button
          variant="text"
          // color="success"
          fullWidth
          size="large"
          type="submit"
          sx={{ height: "56px", textDecoration: "underline" }}
          href="/login"
        >
          Go to Login
        </Button>
      </Box>
    </AuthCardContainer>
  );
};

export default SignupView;
export function passwordValidationHandler(password, setPasswordError) {
  if (password.length < 8) {
    setPasswordError("Password should be at least 8 characters long.");
  } else if (!/[A-Za-z]/.test(password)) {
    setPasswordError("Password should contain at least one letter.");
  } else if (!/\d/.test(password)) {
    setPasswordError("Password should contain at least one number.");
  } else if (!/[!@#$%^&*]/.test(password)) {
    setPasswordError(
      "Password should contain at least one special character (!@#$%^&*)."
    );
  } else {
    setPasswordError(null);
  }
}
