import PropTypes from "prop-types";
import * as React from "react";
import { MenuItem, TextField, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { PostViolation } from "Utils/fetchers";

const VIOLATION_TYPES = [
  "Harassment",
  "Inappropriate Content",
  "Hate Speech",
  "Other",
];
export default function ReportViolationDialog(props) {
  const { onClose, open, data, ...other } = props;

  const violationTextField = React.useRef(null);
  const [violationType, setViolationType] = React.useState(2);
  const [violationDescription, setViolationDescription] = React.useState("");

  React.useEffect(() => {}, [open]);

  const handleEntering = () => {
    if (violationTextField.current != null) {
      violationTextField.current.focus();
    }
  };

  const handleCancel = () => {
    onClose();
  };

  const handleOk = () => {
    // obtain user-agent:
    const body = {
      type: violationType,
      blueprintId: data.blueprintId,
      questionId: data.questionId,
      flowId: data.flow_id,
      description: violationDescription,
    };
    PostViolation(body).then((res) => {
      if (res.status === 429 || res.status < 300) {
        onClose(true);
      }
    });
  };

  return (
    <Dialog
      sx={{
        "& .MuiDialog-paper": {
          width: "90%",
          maxHeight: 500,
          borderRadius: "24px",
          padding: "24px",
        },
      }}
      maxWidth="xs"
      TransitionProps={{ onEntering: handleEntering }}
      open={open}
      {...other}
    >
      <DialogTitle>Report</DialogTitle>
      <DialogContent dividers>
        {/* Form to report issue or violation: */}
        <Typography gutterBottom>
          If you believe this content violates our guidelines, please select the
          violation below. and we will review it
        </Typography>
        <TextField
          select
          label="Violation"
          variant="outlined"
          fullWidth
          margin="normal"
          sx={{ marginBottom: "24px" }}
          value={violationType}
          onChange={(e) => setViolationType(e.target.value)}
          ref={violationTextField}
        >
          {VIOLATION_TYPES.map((label, index) => (
            <MenuItem key={label} value={index + 1}>
              {label}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          multiline
          label="Additional Information"
          variant="outlined"
          fullWidth
          margin="normal"
          sx={{ marginBottom: "24px" }}
          onChange={(e) => setViolationDescription(e.target.value)}
          value={violationDescription}
          inputProps={{ maxLength: 120 }}
          helperText={`${violationDescription.length}/120 character limit`}
        />
      </DialogContent>
      <DialogActions>
        <Button
          autoFocus
          onClick={handleCancel}
          size="large"
          sx={{ background: "gray" }}
          variant="contained"
        >
          Cancel
        </Button>
        <Button
          onClick={handleOk}
          size="large"
          color="primary"
          variant="contained"
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
}

ReportViolationDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  message: PropTypes.object,
};
