import { Box } from "@mui/system";
import { GRADIENTS } from "../constants";

export default function QuestionTypeCard({
  icon,
  title,
  description,
  gradientIndex = 0,
}) {
  return (
    <Box
      sx={{
        display: "grid",
        gridTemplateRows: "max-content 1fr",
        gridGap: "4px",
        "& > *": {
          margin: 0,
          padding: 0,
        },
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: "8px",
          background: GRADIENTS[gradientIndex],
          WebkitBackgroundClip: "text",
          WebkitTextFillColor: "transparent",
        }}
      >
        <Box
          component="i"
          sx={{ fontSize: "28px" }}
          className={`fas ${icon}`}
        />
        <h2>{title}</h2>
      </Box>
      <p style={{ opacity: 0.8 }}>{description}</p>
    </Box>
  );
}
