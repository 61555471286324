import { API_URL } from "Config";
import AuthCardContainer from "UI/AuthCardContainer";
import PurplePinkButton from "UI/PurplePinkButton";
import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Button, Divider, TextField, Typography } from "@mui/material";
import { GetUserProfile } from "Utils/fetchers";
import { UserContext } from "Providers/UserContext";

const EmailVerificationHandler = () => {
  const navigate = useNavigate();

  return (
    <AuthCardContainer>
      <Box
        sx={{
          display: "grid",
          gap: 2,
        }}
      >
        <Typography variant="h4" align="center">
          Email Verification
        </Typography>
        <Typography variant="body1" align="center">
          Please check your email for the verification link, once you have
          verified your account you can login.
        </Typography>
        <Divider />
        <Button
          variant="text"
          // color="success"
          fullWidth
          size="large"
          type="submit"
          sx={{ height: "56px", textDecoration: "underline" }}
          href="/login"
        >
          Go to Login
        </Button>
      </Box>
    </AuthCardContainer>
  );
};

export default EmailVerificationHandler;
