import { CheckCircleOutline } from "@mui/icons-material";
import { Grid, Zoom } from "@mui/material";
import { COLORS_PALETTE } from "../Utils/static";
import { useEffect, useState } from "react";

function MultiChoicePhotoAnswer({
  items,
  answer,
  answerCallback,
  selectedPhoto,
  multiple = true,
}) {
  // create array 5 of false value
  useEffect(() => {
    // reset selectedAnswers
    setSelectedAnswers(Array(items.length).fill(false));
    setOneSelectedAnswer(null);
  }, [items]);

  const [selectedAnswers, setSelectedAnswers] = useState(
    Array(items.length).fill(false)
  );
  const [oneSelectedAnswer, setOneSelectedAnswer] = useState(null); // [0, 1, 2, 3
  return (
    <Grid
      container
      spacing={1}
      sm={12}
      style={{
        backgroundColor: COLORS_PALETTE.cardLightPurple,
        padding: 8,
        borderRadius: "14px",
      }}
    >
      {items.map((photo, index) => (
        <Grid item xs={3} sm={3} key={index}>
          <Zoom in={true} timeout={200 + index * 300}>
            <div
              style={{
                width: "100%",
                borderRadius: "50%",
                paddingBottom: "100%", // For a 1:1 aspect ratio
                cursor: "pointer",
                position: "relative",
              }}
              onClick={() => {
                if (multiple) {
                  const temp = [...selectedAnswers];
                  if (selectedAnswers[index]) {
                    temp[index] = false;
                    setSelectedAnswers(temp);
                    selectedPhoto({ type: null, value: null });
                    //create list on indexes that have temp[index] === true:
                  } else {
                    temp[index] = true;
                    setSelectedAnswers(temp);
                    selectedPhoto({ type: "image", value: index });
                  }
                  const selectedIndexes = temp
                    .map((item, index) => (item ? index : null))
                    .filter((item) => item !== null);
                  answerCallback(selectedIndexes);
                } else {
                  if (oneSelectedAnswer === index) {
                    setOneSelectedAnswer(null);
                    selectedPhoto({ type: null, value: null });
                    answerCallback(null);
                  } else {
                    setOneSelectedAnswer(index);
                    selectedPhoto({ type: "image", value: index });
                    answerCallback(index);
                  }
                }
              }}
            >
              <img
                src={photo.url}
                alt="question"
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                  borderRadius: "50%",
                  border: index === answer ? "2px solid" : "2px solid #fff",
                }}
              />
              {(selectedAnswers[index] || oneSelectedAnswer === index) && (
                <div
                  style={{
                    position: "absolute",
                    bottom: -8,
                    right: -8,
                    background: "#white",
                    borderRadius: "50%",
                    padding: "4px",
                  }}
                >
                  <Zoom
                    in={selectedAnswers[index] || oneSelectedAnswer === index}
                  >
                    <CheckCircleOutline
                      style={{
                        color: COLORS_PALETTE.paletteSpot3,
                        borderRadius: "50%",
                        backgroundColor: "rgba(255, 255, 255, 0.85)",
                      }}
                    />
                  </Zoom>
                </div>
              )}
            </div>
          </Zoom>
        </Grid>
      ))}
    </Grid>
  );
}

export default MultiChoicePhotoAnswer;
