import React, { useEffect, useState } from "react";
import {
  Card,
  CardContent,
  TextField,
  IconButton,
  Typography,
  MenuItem,
  Modal,
  Box,
  InputLabel,
  FormControl,
  Select,
  List,
  Chip,
  Fab,
  Stepper,
  Step,
  StepLabel,
  FormHelperText,
  Snackbar,
  Container,
} from "@mui/material";
import {
  AddCircleOutline as AddCircleOutlineIcon,
  Delete as DeleteIcon,
  ArrowUpward as ArrowUpwardIcon,
  ArrowDownward as ArrowDownwardIcon,
  EditNote as EditIcon,
  FormatListNumbered as FormatListNumberedIcon,
  Quiz as QuizIcon,
  DisabledByDefaultRounded,
} from "@mui/icons-material";
import AddIcon from "@mui/icons-material/Add";
import PhotoLibraryIcon from "@mui/icons-material/PhotoLibrary";
import {
  Navigate,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { API_URL } from "../../Config";
import { BLUEPRINT_TYPES_MAP, QUESTION_TYPE_ENUM } from "../../Utils/static";
import QuestionEditDialog from "../../Dialogs/QuestionEditDialog";
import {
  deleteQuestion,
  postQuestion,
  putQuestion,
  uploadQuestionPhoto,
} from "../../Utils/fetchers";
import ConfimationDialog from "../../Dialogs/ConfirmationDialog";
import { DIALOG_CONFIRMATION_MESSAGES } from "../../Utils/languages";
import { Button } from "Components/CustomHTMLComponent";
const BlueprintEditView = () => {
  const navigate = useNavigate();

  const { blueprintId } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const [blueprint, setBlueprint] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [questions, setQuestions] = useState([]);
  const [EdittableQuestionId, setEdittableQuestionId] = useState(null);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [confirmationModalMessage, setConfirmationModalMessage] =
    useState(null);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [errors, setErrors] = useState({});
  const { seedData } = useLocation();
  const [questionModelMetadata, setQuestionModelMetadata] = useState({});

  useEffect(() => {
    fetchBlueprint();
  }, []);
  const fetchBlueprint = () => {
    fetch(API_URL + "/flows/" + blueprintId, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("vqa_token"),
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.metadata?.questions_order?.length >= 0) {
          // sort data.questions by the order in metadata :
          const orderedQuestions = [];

          data.metadata.questions_order.forEach((qId) => {
            const q = data.questions.find((q) => q.id === qId);
            if (q) orderedQuestions.push(q);
          });
          // add the rest of questions that are not in metadata.questions_order:
          data.questions.forEach((q) => {
            if (!orderedQuestions.find((q1) => q1.id === q.id)) {
              orderedQuestions.push(q);
            }
          });
          setQuestions(orderedQuestions);
        } else {
          setQuestions(data.questions);
        }
        setBlueprint(data);
        initalView();
      });
  };
  const initalView = () => {
    const initalQuestion = searchParams.get("initRouteWith");
    let newQuestion = null;
    if (questions.length > 0) {
      return;
    }

    if (initalQuestion === "quickQuestion") {
      newQuestion = {
        text: "Question " + (questions.length + 1),
        type: "yes_no",
        flow_id: Number(blueprintId),
        metadata: {
          choices: ["Yes", "No"],
        },
      };
    } else if (initalQuestion === "rankPhotos") {
      newQuestion = {
        id: null,
        text: "Question " + (questions.length + 1),
        type: "sortable",
        flow_id: Number(blueprintId),
        metadata: {
          choices: ["Choice 1", "Choice 2", "Choice 3"],
        },
      };
      setQuestionModelMetadata({ usePhotosforChoices: true });
    }

    if (newQuestion) {
      const updatedQuestions = [...questions, newQuestion];

      setQuestions(updatedQuestions);
      setEdittableQuestionId(questions.length);
      setModalOpen(true);
      // });
    }
  };
  const handleAddQuestion = () => {
    const newQuestion = {
      id: null,
      text: "",
      type: "yes_no",
      flow_id: Number(blueprintId), // TODO : change this when change in backend
      metadata: {
        choices: ["Yes", "No"],
      },
    };
    // saveQuestionInit(newQuestion).then((completed) => {
    //   setEdittableQuestionId(questions.length);
    //   setModalOpen(true);
    // });

    const updatedQuestions = [...questions, newQuestion];

    setQuestions(updatedQuestions);
    setEdittableQuestionId(questions.length);
    setModalOpen(true);
  };
  const saveQuestionPhotos = (question) => {
    const promises = [];
    question.metadata.images.forEach((image, idx) => {
      if (image.blob) {
        const formData = new FormData();
        formData.append("image", image.blob);
        formData.append("questionId", question.id);
        formData.append("blueprintId", blueprintId);
        formData.append("imageId", idx);
        promises.push(
          // we are using promise to make sure that every image is uploaded:
          // am doing nested /wrapper promise so that we don't return the promise until we set the url on the image

          new Promise((resolve, reject) => {
            uploadQuestionPhoto(formData)
              .then((response) => {
                response
                  .json()
                  .then((data) => {
                    image.url = data;
                    delete image.blob;
                    resolve();
                  })
                  .catch((error) => {
                    reject(error);
                  });
              })
              .catch((error) => {
                reject(error);
              });
          })
        );
      }
    });
    return Promise.all(promises);
  };
  const saveQuestionInit = (question) => {
    return new Promise((resolve, reject) => {
      postQuestion(question)
        .then((questionResponse) => {
          questionResponse
            .json()
            .then((questionData) => {
              // const tempQuestions = [...questions, questionData];
              // const finalQuestions = tempQuestions.filter((q) => q.id !== null);
              // setQuestions(finalQuestions);
              resolve(questionData);
            })
            .catch((error) => {
              reject(error);
            });
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  const saveQuestion = (qIndex, question) => {
    let questionPromise = null;
    // copy all questions properties to a new object except metadata's images that doesn't have url:
    // because currently i have different endpoint for save photos and save question

    saveQuestionPhotos(question).then((completed) => {
      // Update question after all photos are uploaded:
      console.log("putQuestion postSavePhoto", question);
      questionPromise = putQuestion(blueprintId, question);

      questionPromise.then((questionResponse) => {
        questionResponse.json().then((questionData) => {
          const tempQuestions = [...questions];
          // find question in tempQuestions  where id === questionData.id and replace it with questionData
          let qIndex = tempQuestions.findIndex((q) => q.id === questionData.id);
          // if the question is not found in tempQuestions then add it to the end of the array:
          if (qIndex === -1) {
            qIndex = tempQuestions.length - 1;
          }
          tempQuestions[qIndex] = questionData;

          setShowSnackbar(true);
          setQuestions(tempQuestions);
        });
      });

      // finish updating question
    });
  };
  const removeQuestion = (index, question) => {
    if (question.id) {
      // question already saved. need to delete from db
      deleteQuestion(question).then((questionResponse) => {
        if (questionResponse.status === 200) {
          const tempQuestions = [...questions];
          tempQuestions.splice(index, 1);
          setQuestions(tempQuestions);
        }
      });
    } else {
      // question not saved. just remove from state
      const tempQuestions = [...questions];
      tempQuestions.splice(index, 1);
      setQuestions(tempQuestions);
    }
  };
  const deleteBlueprint = () => {
    fetch(API_URL + "/flows/" + blueprintId, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("vqa_token"),
      },
    })
      .then((response) => response.json())
      .then((data) => {
        window.history.back();
      });
  };

  const handleDeleteQuestion = (index) => {
    const updatedQuestions = questions.filter((_, i) => i !== index);
    setQuestions(updatedQuestions);
  };

  const handleMoveQuestionUp = (index) => {
    if (index > 0) {
      const updatedQuestions = [...questions];
      const temp = updatedQuestions[index];
      updatedQuestions[index] = updatedQuestions[index - 1];
      updatedQuestions[index - 1] = temp;
      setQuestions(updatedQuestions);
    }
  };

  const handleMoveQuestionDown = (index) => {
    if (index < questions.length - 1) {
      const updatedQuestions = [...questions];
      const temp = updatedQuestions[index];
      updatedQuestions[index] = updatedQuestions[index + 1];
      updatedQuestions[index + 1] = temp;
      setQuestions(updatedQuestions);
    }
  };
  const handleModelClose = (q) => {
    if (q) {
      if (q.id) {
        // this is an existing question that has been edited
        saveQuestion(EdittableQuestionId, q);
      } else {
        saveQuestionInit(q).then((savedQ) => {
          q.id = savedQ.id;
          saveQuestion(EdittableQuestionId, q);
        });
      }
      const updatedQuestions = [...questions];
      updatedQuestions[EdittableQuestionId] = q;
      setQuestions(updatedQuestions);
      // above handle as part of saveQuestion
    } else {
      // this is a new question that has been cancelled
      // pop it if it doesn't have id:
      const updatedQuestions = [...questions];
      if (updatedQuestions[EdittableQuestionId].id === null) {
        updatedQuestions.pop();
      }
      setQuestions(updatedQuestions);
    }

    setModalOpen(false);
    // if url has initRouteWith=quickQuestion remove that parameter from the url:
    if (searchParams.get("initRouteWith") !== null) {
      searchParams.delete("initRouteWith");
      setSearchParams(searchParams);
    }
  };
  const validateForm = () => {
    let errors = {};
    let formIsValid = true;
    if (
      !blueprint.description ||
      blueprint.description.length < 3 ||
      blueprint.description.length > 120
    ) {
      formIsValid = false;
      errors["description"] = "Please enter description.";
    }
    setErrors(errors);
    return formIsValid;
  };
  const handleSubmit = (event) => {
    if (event) event.preventDefault();
    if (!validateForm()) {
      return;
    }
    fetch(API_URL + "/flows", {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("vqa_token"),
      },
      body: JSON.stringify({
        id: Number(blueprintId),
        description: blueprint.description,
        status_code: Number(blueprint.status_code),
        type: Number(blueprint.type),
        metadata: {
          questions_order: questions.map((q) => q.id),
        },
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        setShowSnackbar(true);
        // only navigate if this is a submit. if we are publishing we don't want to navigate because we want to show the model
        if (event != null) navigate(`/blueprints/?highlight=${blueprintId}`);
      });
  };
  const handleStatusUpdate = (status_code) => {
    return fetch(API_URL + "/flows/" + blueprintId + "/status", {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("vqa_token"),
      },
      body: JSON.stringify({
        id: Number(blueprintId),
        status_code: Number(status_code),
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
      });
  };

  if (!blueprint) {
    return <div>Loading...</div>;
  }

  return (
    <Container maxWidth="xs">
      <Card style={{ marginTop: 15, borderRadius: "24px" }}>
        <CardContent>
          <Typography variant="h6" gutterBottom colo>
            Edit {BLUEPRINT_TYPES_MAP[blueprint.type].name}
          </Typography>
          <FormHelperText>
            {blueprint.status_code > 1
              ? "Blueprint has been Published. this is ready only view"
              : ""}
          </FormHelperText>

          <form onSubmit={handleSubmit}>
            <FormControl
              fullWidth
              variant="outlined"
              style={{ marginTop: "1rem" }}
            >
              <TextField
                label="Blueprint Description"
                variant="outlined"
                fullWidth
                inputProps={{ maxLength: 120, minLength: 3 }}
                value={blueprint.description}
                disabled={blueprint.status_code > 1}
                helperText={
                  blueprint?.description?.length > 80 &&
                  `${
                    120 - blueprint.description.length
                  } characters left. (Description cannot be longer than 120 characters)`
                }
                error={errors["description"]}
                onChange={(e) =>
                  setBlueprint({ ...blueprint, description: e.target.value })
                }
                style={{ background: "white", marginTop: "1rem" }}
              />
            </FormControl>
            <FormControl
              fullWidth
              variant="outlined"
              style={{ marginTop: "1rem" }}
            >
              {/* Add select with Mock type for Blueprint Type: */}
              <InputLabel id="blueprint-type-label">Blueprint Type</InputLabel>
              <Select
                labelId="blueprint-type-label"
                id="blueprint-type"
                value={blueprint.type}
                label="Blueprint Type"
                disabled={blueprint.status_code > 1}
                onChange={(e) =>
                  setBlueprint({ ...blueprint, type: e.target.value })
                }
                style={{ background: "white" }}
              >
                {Object.keys(BLUEPRINT_TYPES_MAP).map((key, index) => (
                  <MenuItem value={BLUEPRINT_TYPES_MAP[key].id}>
                    {BLUEPRINT_TYPES_MAP[key].name}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText style={{ minHeight: 40 }}>
                {BLUEPRINT_TYPES_MAP[blueprint.type].description}
              </FormHelperText>

              <Stepper
                activeStep={parseInt(blueprint.status_code)}
                style={{ marginTop: "1rem" }}
              >
                {["Created", "Live", "Archived"].map((label, index) => (
                  <Step key={label}>
                    <StepLabel>{label}</StepLabel>
                  </Step>
                ))}
              </Stepper>
            </FormControl>
            <Typography
              variant="h6"
              style={{ marginTop: "1rem", marginBottom: 0 }}
            >
              Questions:
            </Typography>

            <List>
              {questions.map((question, index) => (
                <Card
                  key={index}
                  style={{
                    marginTop: "1rem",
                    // backgroundColor: "#efeefc",
                    borderRadius: "8px",
                  }}
                  elevation={3}
                >
                  <CardContent>
                    <Typography variant="body1" style={{ fontWeight: "bold" }}>
                      {question.text}
                    </Typography>
                    {/* <Typography variant="body1" style={{ marginTop: "0.5rem" }}>
                    Type: 
                  </Typography> */}
                    <Chip
                      style={{ backgroundColor: "#efeefc", marginRight: 8 }}
                      label={QUESTION_TYPE_ENUM[question.type]}
                      icon={<QuizIcon />}
                    />
                    {question.metadata.choices?.length > 0 && (
                      <Chip
                        style={{ backgroundColor: "#efeefc", marginRight: 8 }}
                        label={question.metadata.choices?.length + " choices"}
                        icon={<FormatListNumberedIcon />}
                      />
                    )}
                    {question.metadata.images?.length > 0 && (
                      <Chip
                        style={{ backgroundColor: "#efeefc" }}
                        label={question.metadata.images?.length}
                        icon={<PhotoLibraryIcon />}
                      />
                    )}
                    <div
                      style={{
                        marginTop: "1rem",
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-start",
                        }}
                      >
                        <IconButton
                          style={{
                            backgroundColor: "#f5f5f5",
                            marginRight: 10,
                          }}
                          disabled={blueprint.status_code > 1}
                        >
                          <EditIcon
                            onClick={() => {
                              setEdittableQuestionId(index);
                              setModalOpen(true);
                            }}
                          />
                        </IconButton>

                        <IconButton
                          onClick={() => removeQuestion(index, question)}
                          style={{
                            backgroundColor: "#f5f5f5",
                            marginRight: 10,
                          }}
                          disabled={blueprint.status_code > 1}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </div>

                      <div
                        style={{ display: "flex", justifyContent: "flex-end" }}
                      >
                        <IconButton
                          onClick={() => handleMoveQuestionUp(index)}
                          style={{
                            backgroundColor: "#f5f5f5",
                            marginRight: 10,
                          }}
                          disabled={blueprint.status_code > 1}
                        >
                          <ArrowUpwardIcon />
                        </IconButton>
                        {/* a small select input to quickly move the question  */}
                        <FormControl variant="outlined" size="small">
                          <Select
                            value={index}
                            onChange={(e) => {
                              const newIndex = e.target.value;
                              const updatedQuestions = [...questions];
                              const temp = updatedQuestions[index];
                              updatedQuestions[index] =
                                updatedQuestions[newIndex];
                              updatedQuestions[newIndex] = temp;
                              setQuestions(updatedQuestions);
                            }}
                            style={{ marginRight: 10 }}
                            disabled={blueprint.status_code > 1}
                          >
                            {questions.map((q, i) => (
                              <MenuItem value={i}>{i + 1}</MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        <IconButton
                          onClick={() => handleMoveQuestionDown(index)}
                          style={{
                            backgroundColor: "#f5f5f5",
                            marginRight: 10,
                          }}
                          disabled={blueprint.status_code > 1}
                        >
                          <ArrowDownwardIcon />
                        </IconButton>
                      </div>
                    </div>
                  </CardContent>
                </Card>
              ))}
            </List>

            {/* <Fab
              variant="extended"
              onClick={handleAddQuestion}
              color="primary"
              disabled={blueprint.status_code > 1}
            >
              <AddIcon sx={{ mr: 1 }} />
              Add Question
            </Fab> */}
            <div
              style={{
                borderRadius: "14px",
                // backgroundColor: "#f5f5f5",
                position: "relative",
                marginBottom: 0,

                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                padding: 4,
              }}
            >
              <Button
                component="label"
                variant="contained"
                startIcon={<AddIcon />}
                onClick={handleAddQuestion}
                color="primary"
                disabled={blueprint.status_code > 1}
                style={{
                  boxShadow: "none",
                  borderRadius: "inherit",
                  width: "100%",
                  height: "36px",
                  padding: 0,
                }}
              >
                Add Question
              </Button>
              {/*  */}
            </div>

            <div
              style={{
                marginTop: "1rem",
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              {questions.length === 0 && blueprint.status_code === 1 ? (
                <Button
                  type="reset"
                  variant="contained"
                  color="warning"
                  style={{ marginRight: "1rem" }}
                  onClick={() => {
                    // go back to the previous page:
                    deleteBlueprint();
                  }}
                  pill
                >
                  Delete
                </Button>
              ) : (
                <Button
                  type="reset"
                  variant="outlined"
                  style={{ marginRight: "1rem" }}
                  onClick={() => {
                    // go back to the previous page:
                    window.history.back();
                  }}
                  pill
                >
                  Cancel
                </Button>
              )}

              {/* if {blueprint.status_code === 1} button should save publish  if it's 2 then should save archive*/}
              {blueprint.status_code === 1 ? (
                <Button
                  variant="contained"
                  color="info"
                  onClick={(e) => {
                    e.preventDefault();
                    handleSubmit(null);
                    setConfirmationModalMessage(
                      DIALOG_CONFIRMATION_MESSAGES.en.publish_blueprint
                    );
                    setShowConfirmationModal(true);
                  }}
                  pill
                >
                  Publish
                </Button>
              ) : (
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={(e) => {
                    e.preventDefault();
                    setConfirmationModalMessage(
                      DIALOG_CONFIRMATION_MESSAGES.en.archive_blueprint
                    );
                    setShowConfirmationModal(true);
                  }}
                  disabled={blueprint.status_code > 2}
                  pill
                >
                  Archive
                </Button>
              )}
              <Button
                type="submit"
                variant="contained"
                color="primary"
                style={{ marginLeft: "1rem" }}
                disabled={blueprint.status_code > 1}
                pill
              >
                Save
              </Button>
            </div>
          </form>
        </CardContent>
        {modalOpen && (
          <QuestionEditDialog
            isOpen={modalOpen}
            question={questions[EdittableQuestionId]}
            handleClose={handleModelClose}
            modelMetadata={questionModelMetadata}
          ></QuestionEditDialog>
        )}
        <ConfimationDialog
          open={showConfirmationModal}
          message={confirmationModalMessage}
          onClose={(v, eventType) => {
            if ((eventType === "publish" || eventType === "archive") && v) {
              blueprint.status_code = blueprint.status_code + 1;
              handleStatusUpdate(blueprint.status_code).then((data) => {
                if (eventType === "publish") {
                  navigate("/flows");
                } else {
                  window.history.back();
                }
              });
            }
            setShowConfirmationModal(false);
          }}
        />
        <Snackbar
          open={showSnackbar}
          autoHideDuration={3000}
          onClose={() => setShowSnackbar(false)}
          message="Save Successful"
        />
      </Card>
    </Container>
  );
};

export default BlueprintEditView;
