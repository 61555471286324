import { useParams, useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import {
  pauseFlowUserByFlowId,
  resumeFlowUserByFlowId,
  getAllFlows,
  postCloneFlowByFlowId,
  vfetch,
} from "../../Utils/fetchers";
import { useNavigate } from "react-router-dom";
import { API_URL } from "../../Config";
import {
  Button,
  ButtonGroup,
  Card,
  CardContent,
  Chip,
  Container,
  Fab,
  Grid,
  IconButton,
  Skeleton,
  Typography,
} from "@mui/material";
import {
  Delete as DeleteIcon,
  EditNote as EditIcon,
  PlayArrow,
  Create as CreateIcon,
} from "@mui/icons-material";
import ReplayIcon from "@mui/icons-material/Replay";
import SettingsSuggestIcon from "@mui/icons-material/SettingsSuggest";
import {
  BLUEPRINT_STATUS_ENUM,
  BLUEPRINT_TYPES_MAP,
  COLORS_PALETTE,
  PAPER_CARD_STYLE_2,
  QUESTION_TYPE_ENUM,
} from "../../Utils/static";
import { formatDistanceToNow } from "date-fns";
import { ViewTitle } from "Components/ViewTitle";
import CreateNewButtonDropdown from "Views/MainView/Components/CreateNewButtonDropdown";
const flowSorter = (a, b) => {
  // Compare expire properties first
  if (a.is_expired === b.is_expired) {
    // If expire properties are equal, compare dates in descending order (newer dates come first)
    // return new Date(b.date) - new Date(a.date);
    return b.id - a.id;
  } else {
    // If expire properties are different, put `false` before `true`
    return a.is_expired ? 1 : -1;
  }
};

function BlueprintList() {
  // const [flows, setFlows] = useState([]);
  const [ownedFlows, setOwnedFlows] = useState();

  const [searchParams, setSearchParams] = useSearchParams();
  const highlightBlueprintId = searchParams.get("highlight");
  const navigate = useNavigate();
  useEffect(() => {
    fetchFlows();
  }, []);
  let { flowId } = useParams();
  const handleCreateBlueprint = () => {
    vfetch(API_URL + "/flows", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("vqa_token"),
      },
      body: JSON.stringify({
        flow: { description: "New Flow blueprint", type: 1 },
      }),
    }).then((response) => {
      if (response.status === 200) {
        return response.json().then((data) => {
          let flow = { id: data.id, description: data.description };

          navigate("/blueprints/" + flow.id + "/edit");
        });
      }
      return response.json().then((error) => {
        console.error("Error creating flow:", error);
        // throw new Error(error.message);
      });
    });
  };
  // Input Change handlers
  const fetchFlows = () => {
    getAllFlows().then((response) => {
      if (response.status === 200) {
        response.json().then((data) => {
          setOwnedFlows(data);
          // scroll to the highlighted flow:
          if (highlightBlueprintId) {
            const element = document.getElementById(
              "flow-item-" + highlightBlueprintId
            );
            if (element) {
              element.scrollIntoView({
                behavior: "instant",
                block: "center",
                inline: "center",
              });
            }
          }
        });
      }
    });
  };

  const consumeFlow = (flowId) => {
    postCloneFlowByFlowId(flowId).then((response) => {
      if (response.status === 200) {
        response.json().then((data) => {
          fetchFlows();
        });
      }
    });
  };

  const pauseClonedFlow = (flowId) => {
    pauseFlowUserByFlowId(flowId).then((response) => {
      if (response.status === 202) {
        // response.json().then((data) => {
        fetchFlows();
        // });
      }
    });
  };
  const resumeClonedFlow = (flowId) => {
    resumeFlowUserByFlowId(flowId).then((response) => {
      if (response.status === 202) {
        // response.json().then((data) => {
        fetchFlows();
        // });
      }
    });
  };
  if (!ownedFlows || ownedFlows?.length === 0) {
    return (
      <Container maxWidth="xs" style={{ marginTop: "8px" }}>
        <ViewTitle>My Drafts</ViewTitle>
        <Card variant="outlined" style={{ borderRadius: "24px" }}>
          <CardContent>
            <Typography
              variant="body1"
              style={{ textAlign: "center", marginBottom: 50 }}
            >
              You don't have any draft yet.
            </Typography>
            <CreateNewButtonDropdown />
          </CardContent>
        </Card>
      </Container>
    );
  }
  return (
    <Container maxWidth="xs" style={{ marginTop: "8px" }}>
      <ViewTitle>My Drafts</ViewTitle>

      {!ownedFlows ? (
        <div style={{ marginTop: "1rem" }}>
          {[...Array(3)].map((e, i) => (
            <Card
              style={{
                borderRadius: "16px",
                minHeight: 260,
                marginBottom: "1rem",
              }}
            >
              <CardContent>
                <Skeleton animation="wave" height={32} width="80%" />
                <Skeleton animation="wave" height={32} width="20%" />
                <Skeleton animation="wave" height={90} width="90%" />
                <Skeleton animation="wave" height={42} width="60%" />
              </CardContent>
            </Card>
          ))}
        </div>
      ) : (
        ownedFlows
          .sort((a, b) => {
            return b.id - a.id;
          })
          .map((flow, index) => (
            <Card
              key={index}
              id={"flow-item-" + flow.id}
              //  if this is highlighted, show breathing animation:

              style={{
                marginTop: "1rem",
                borderRadius: "16px",
              }}
              className={
                Number(highlightBlueprintId) === flow.id ? "breathing-flow" : ""
              }
            >
              <CardContent>
                <Typography
                  variant="h5"
                  style={{ fontWeight: "bold" }}
                  gutterBottom
                >
                  {flow.description}
                </Typography>
                <Chip
                  label={BLUEPRINT_STATUS_ENUM[flow.status_code]}
                  style={{ backgroundColor: "#efeefc", marginRight: "0.5rem" }}
                  size="medium"

                  // icon={<QuizIcon />}
                />
                <Chip
                  label={`${
                    BLUEPRINT_TYPES_MAP[flow.type].short
                      ? BLUEPRINT_TYPES_MAP[flow.type].short
                      : ""
                  }`}
                  style={{
                    backgroundColor: "#efeefc",
                    marginRight: "0.5rem",
                  }}
                  size="medium"

                  // icon={<FormatListNumberedIcon />}
                />
                <Chip
                  label={`${flow.questions_count} Questions`}
                  style={{ backgroundColor: "#efeefc", marginRight: "0.5rem" }}
                  size="medium"

                  // icon={<FormatListNumberedIcon />}
                />

                <CardContent style={PAPER_CARD_STYLE_2}>
                  <Grid container spacing={2}>
                    <Grid item xs={4} align="center">
                      {/* Label 1 */}
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        align="center"
                      >
                        Total
                      </Typography>
                      {/* Value 1 */}
                      <Typography variant="h6" align="center">
                        {flow.copies_count ? flow.copies_count : "--"}
                      </Typography>
                      {/* Secondary Label 1 */}
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        align="center"
                      >
                        Clones
                      </Typography>
                    </Grid>

                    <Grid item xs={4}>
                      {/* Label 2 */}
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        align="center"
                      >
                        Total
                      </Typography>
                      {/* Value 2 */}
                      <Typography variant="h6" align="center">
                        {flow.total_answer ? flow.total_answer : 0}
                      </Typography>
                      {/* Label 2 */}
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        align="center"
                      >
                        Answers
                      </Typography>
                    </Grid>
                    <Grid item xs={4}>
                      {/* Label 3 */}
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        align="center"
                      >
                        Last
                      </Typography>
                      {/* Value 3 */}
                      <Typography variant="h6" align="center">
                        {flow.last_answer
                          ? formatDistanceToNow(new Date(flow.last_answer))
                          : "--"}
                      </Typography>
                      {/* Secondary Label 3 */}
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        align="center"
                      >
                        ago
                      </Typography>
                    </Grid>
                  </Grid>
                </CardContent>
              </CardContent>
              <ButtonGroup
                variant="text"
                aria-label="large button group"
                disableElevation
                fullWidth
                style={{ minHeight: "3rem", bottom: 0 }}
              >
                <Button
                  // style={{
                  //   backgroundColor: "var(--palette-color-0)",
                  //   borderRadius: "20px",
                  //   color: "var(--palette-text-1)",
                  // }}
                  size="large"
                  startIcon={
                    flow.status_code === 1 ? (
                      <EditIcon />
                    ) : (
                      <SettingsSuggestIcon />
                    )
                  }
                  onClick={() => {
                    navigate("/blueprints/" + flow.id + "/edit");
                  }}
                >
                  {flow.status_code === 1 ? "Edit" : "Manage"}
                </Button>

                <Button
                  // style={{
                  //   backgroundColor: "var(--palette-color-0)",
                  //   borderRadius: "20px",
                  //   color: "var(--palette-text-1)",
                  //   marginLeft: "0.5rem",
                  // }}
                  size="large"
                  startIcon={<ReplayIcon />}
                  onClick={(event) => {
                    navigate(flow.id + "/start?test=true");
                  }}
                  color="primary"
                >
                  Test
                </Button>
                {/* {flow.type === 2 && (
                  <Button
                    // style={{
                    //   backgroundColor: "var(--palette-color-0)",
                    //   borderRadius: "20px",
                    //   color: "var(--palette-text-1)",
                    //   marginLeft: "0.5rem",
                    // }}
                    size="large"
                    startIcon={<PlayArrow />}
                    id={"flow-item-" + flow.id}
                    onClick={(event) => {
                      navigate(flow.id + "/start?test=true");
                    }}
                    color="primary"
                  >
                    Try
                  </Button>
                )} */}
              </ButtonGroup>
            </Card>
          ))
      )}

      <Fab
        variant="extended"
        color="primary"
        fullWidth
        size="large"
        style={{
          position: "fixed",
          bottom: "20px",
          right: "20px",
        }}
        onClick={() => {
          handleCreateBlueprint();
        }}
      >
        <CreateIcon /> Create New
      </Fab>
    </Container>
  );
}
export default BlueprintList;
