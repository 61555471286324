import { KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material";
import ShareIcon from "@mui/icons-material/Share";
import {
  Card,
  CardContent,
  Container,
  Fab,
  Grid,
  Pagination,
  Typography,
} from "@mui/material";
import * as htmlToImage from "html-to-image";
import Cookies from "js-cookie";
import { round } from "lodash";
import { useEffect, useRef, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { API_URL } from "../Config";
import { GetFlowByShortenKey, vfetch } from "../Utils/fetchers";
import { PAPER_CARD_STYLE_2, PAPER_CARD_STYLE_6 } from "../Utils/static";
import "./Question.css";
function AnswerStatsViewChallenge() {
  const [isLoading, setIsLoading] = useState(true);
  const [searchParams, setSearchParams] = useSearchParams();
  const [perQuestionStatus, setPerQuestionStatus] = useState(null);
  const [questionsMap, setQuestionsMap] = useState({});
  const [questionsArray, setQuestionsArray] = useState([]);
  const [activeQuestionId, setActiveQuestionId] = useState(0);
  const [ownerAnswers, setOwnerAnswers] = useState({});
  const [userAnswers, setUserAnswers] = useState({});
  const [flow, setFlow] = useState(null);
  const imageRef = useRef(null);

  let { flowShortenKey } = useParams();
  const isOwner = searchParams.get("isOwner") === "true";
  useEffect(() => {
    fetchFlow();
  }, []);

  function fetchFlow() {
    return GetFlowByShortenKey(flowShortenKey)
      .then((response) => response.json())
      .then((data) => {
        const qMap = {};
        data.blueprint.questions.forEach((q) => {
          // console.log(q);
          qMap[q.id] = q;
        });

        fetchUserAnswers();
        if (data.blueprint.type === 2) {
          fetchOwnerAnswers();
          // return;
        }
        setQuestionsMap(qMap);
        setQuestionsArray(data.blueprint.questions);
        setFlow(data.blueprint);
        fetchFlowAnswerStats(qMap);
        setIsLoading(false);

        // console.log("qMap", questionsMap);
      });

    // .catch((error) => console.error("Error fetching question:", error));
  }
  const fetchOwnerAnswers = () => {
    return fetch(API_URL + "/answers/flow/" + flowShortenKey + "/owner", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("vqa_token"),
      },
    })
      .then((response) => response.json())
      .then((data) => {
        // convert data array into object where the key is quesiton_id and value is the text property:
        const qOwnerAnswers = {};
        data.forEach((answer) => {
          qOwnerAnswers[answer.question_id] = answer.text;
        });
        setOwnerAnswers(qOwnerAnswers);
      })
      .catch((error) =>
        console.error("Error fetching answer for owner:", error)
      );
  };
  const fetchUserAnswers = () => {
    const rawAnswerCookie = Cookies.get(`flow#${flowShortenKey}#answers`);
    if (rawAnswerCookie) {
      const cookieAnswers = JSON.parse(rawAnswerCookie);
      const qUserAnswers = {};
      cookieAnswers.forEach((answer) => {
        qUserAnswers[answer.questionId] = answer.text;
      });
      setUserAnswers(qUserAnswers);
    } else {
      vfetch(API_URL + "/answers/flow/" + flowShortenKey + "/me", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("vqa_token"),
        },
      })
        .then((response) => response.json())
        .then((data) => {
          // convert data array into object where the key is quesiton_id and value is the text property:
          const qUserAnswers = {};
          data.forEach((answer) => {
            qUserAnswers[answer.question_id] = answer.text;
          });
          setUserAnswers(qUserAnswers);
        })
        .catch((error) =>
          console.error("Error fetching answer for user:", error)
        );
    }
  };
  const handleShare = async () => {
    const newFile = await htmlToImage.toBlob(imageRef.current);
    const data = {
      files: [
        new File([newFile], "wannaknow.png", {
          type: newFile.type,
        }),
      ],
    };
    try {
      if (!navigator.canShare(data)) {
        console.error("Can't share");
      }
      await navigator.share(data);
    } catch (err) {
      console.error(err);
    }
  };

  const fetchFlowAnswerStats = (questionsMap) => {
    var headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("vqa_token"),
    };

    fetch(API_URL + "/stats/f/" + flowShortenKey, {
      method: "GET",
      headers: headers,
    })
      .then((response) => response.json())
      .then((data) => {
        const perQuestionStatus = {};
        data.forEach((choiceStats) => {
          // console.log("Looping choiceStats", choiceStats, questionsMap);
          const { question_id, choice, percentage, count, total_count } =
            choiceStats;
          // if (!questionsMap.hasOwnProperty(question_id)) {
          // }
          if (questionsMap[question_id]?.type === "one_of_many") {
            if (!perQuestionStatus[question_id]) {
              perQuestionStatus[question_id] = { dummy_by: "dummy_by" };
            }
            perQuestionStatus[question_id][choice] = percentage;
          } else if (questionsMap[question_id].type === "number_ranged") {
            if (!perQuestionStatus[question_id]) {
              perQuestionStatus[question_id] = [];
            }
            perQuestionStatus[question_id].push({
              y: "" + round(percentage),
              x: Number(choice),
              // count: count,
            });
          } else if (questionsMap[question_id].type === "sortable") {
            if (!perQuestionStatus[question_id]) {
              perQuestionStatus[question_id] = {};
            }
            perQuestionStatus[question_id][choice] = percentage;
          } else {
            if (!perQuestionStatus[question_id]) {
              perQuestionStatus[question_id] = { dummy_by: "dummy_by" };
            }
            perQuestionStatus[question_id][choice] = percentage;
          }
        });
        console.log("perQuestionStatus", perQuestionStatus);
        setPerQuestionStatus(perQuestionStatus);
        setIsLoading(false);
      })
      .catch((error) => console.error("Error fetching question:", error));
  };
  if (isLoading) return "Loading...";

  return (
    <Container maxWidth="xs" style={{ marginTop: "8px" }}>
      <Pagination
        count={questionsArray.length}
        page={activeQuestionId + 1}
        color="primary"
        // disabled={true}
        // hidePrevButton
        // hideNextButton
        // showFirstButton
        // showLastButton
        onChange={(event, value) => {
          event.preventDefault();
          if (value - 1 > -1 && value - 1 < questionsArray.length)
            setActiveQuestionId(value - 1);
        }}
        sx={{
          marginBottom: "8px",
          background: "#ffffff",
          borderRadius: 36,
          p: 1,
        }}
      />
      <Card ref={imageRef} style={{ ...PAPER_CARD_STYLE_6, minHeight: "80vh" }}>
        <CardContent style={{}}>
          <div>
            <Grid container spacing={2} align="left">
              <Grid item xs={12} align="left">
                <Typography
                  variant="h5"
                  align="center"
                  style={{
                    marginBottom: 50,
                    marginTop: 5,
                    minHeight: 250,
                    background: "#ffffff",
                    borderRadius: 24,
                    padding: 15,
                  }}
                >
                  {questionsArray[activeQuestionId].text}
                </Typography>
                <BubbleAnswer
                  isPhoto={
                    questionsArray[activeQuestionId].metadata?.images?.length >=
                    2
                  }
                  answer={ownerAnswers[questionsArray[activeQuestionId].id]}
                  imageURL={
                    questionsArray[activeQuestionId].metadata?.images[
                      Number(ownerAnswers[questionsArray[activeQuestionId].id])
                    ]?.url
                  }
                />
                {!isOwner && (
                  <div>
                    <Typography style={{ marginBottom: 20 }}>
                      They said
                    </Typography>

                    <BubbleAnswer
                      isPhoto={
                        questionsArray[activeQuestionId].metadata?.images
                          ?.length >= 2
                      }
                      answer={userAnswers[questionsArray[activeQuestionId].id]}
                      imageURL={
                        questionsArray[activeQuestionId].metadata?.images[
                          Number(
                            userAnswers[questionsArray[activeQuestionId].id]
                          )
                        ]?.url
                      }
                      align="right"
                    />
                  </div>
                )}
                <Typography style={{ marginBottom: 15 }} align="right">
                  You said
                </Typography>
              </Grid>
            </Grid>

            {/* CARD 2 */}
          </div>
          {/* when share button click generate image from this html and open share view on phone: */}
          <Fab
            variant="contained"
            color="primary"
            fullWidth
            size="large"
            style={{
              position: "fixed",
              bottom: "20px",
              right: "20px",
            }}
            onClick={() => {
              handleShare();
              // generate image from this html and open share view on phone:
            }}
          >
            <ShareIcon />
          </Fab>
          <Fab
            variant="contained"
            color="primary"
            fullWidth
            size="large"
            style={{
              position: "fixed",
              bottom: "20px",
              left: "20px",
            }}
            disabled={activeQuestionId === 0}
            onClick={() => {
              setActiveQuestionId(activeQuestionId - 1);
            }}
          >
            <KeyboardArrowLeft />
          </Fab>
          <Fab
            variant="contained"
            color="primary"
            fullWidth
            size="large"
            style={{
              position: "fixed",
              bottom: "20px",
              left: "80px",
            }}
            disabled={activeQuestionId === questionsArray.length - 1}
            onClick={() => {
              setActiveQuestionId(activeQuestionId + 1);
            }}
          >
            <KeyboardArrowRight />
          </Fab>
        </CardContent>
      </Card>
    </Container>
  );
}

export default AnswerStatsViewChallenge;

export const BubbleAnswer = ({ isPhoto, answer, imageURL, align = "left" }) => {
  if (!isPhoto) {
    return (
      <Typography
        variant="h6"
        color="textSecondary"
        align="left"
        style={{
          background: "#ffffff",
          borderRadius: 36,
          margin: 5,
          marginTop: 10,
          padding: 12,
          marginBottom: 5,
          minHeight: 50,
        }}
      >
        {answer}
      </Typography>
    );
  } else {
    return (
      <div
        style={{
          borderRadius: 36,
          margin: 5,
          marginTop: 10,
          // padding: 12,
          // marginBottom: 5,
          minHeight: 50,
          textAlign: align,
        }}
      >
        <img
          src={imageURL}
          alt="ownerAnswer"
          height={100}
          style={{ borderRadius: 50 }}
        />
      </div>
    );
  }
};
