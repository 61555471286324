import { useEffect } from "react";
import { GetAnswerStatsForUser } from "../../Utils/fetchers";
import { useState } from "react";
import { set } from "lodash";
import { ResponsiveCalendar, ResponsiveTimeRange } from "@nivo/calendar";
import { formatNumbers } from "../../Utils/helper";
// import "./DashboardView.css";

function DashboardView() {
  const [userAnswerStats, setUserAnswerStats] = useState([]);
  const [userTotalAnswerStats, setUserTotalAnswerStats] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    getAnswerStats();
  }, []);

  const getAnswerStats = () => {
    GetAnswerStatsForUser().then((res) => {
      if (res.status === 200) {
        res.json().then((data) => {
          console.log(data);
          setUserAnswerStats(data);
          updateTotalAnswerStats();
        });
      }
    });
  };
  const updateTotalAnswerStats = () => {
    const totalStats = {};
    userAnswerStats.forEach((stat) => {
      totalStats["answers_count"] = userTotalAnswerStats["answers_count"]
        ? userTotalAnswerStats["answers_count"] + stat.Count
        : stat.Count;
      totalStats["flows_count"] = userTotalAnswerStats["flows_count"]
        ? userTotalAnswerStats["flows_count"] + stat.Flows
        : stat.Flows;
    });

    setUserTotalAnswerStats(totalStats);
    setIsLoading(false);
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }
  const today = new Date();
  const twoMonthsAgo = new Date();
  twoMonthsAgo.setMonth(today.getMonth() - 2);

  // dashboard will display cards with mock infomation. each card a label and number of item. i expected to have two card per row:
  return (
    <div>
      <h1>Dashboard</h1>
      {/* here will have a grid 2*2 for each card  */}
      <div style={{ height: "400px", width: "50vh" }}>
        <ResponsiveTimeRange
          data={userAnswerStats.map((stat) => {
            return {
              day: new Date(stat.Date).toISOString().slice(0, 10),
              value: stat.Count,
            };
          })}
          from={twoMonthsAgo.toISOString().slice(0, 10)}
          to={today.toISOString().slice(0, 10)}
          emptyColor="rgba(240, 240, 240, 0.1)"
          colors={["#61cdbb", "#97e3d5", "#e8c1a0", "#f47560"]}
          margin={{ top: 40, right: 40, bottom: 100, left: 40 }}
          dayBorderWidth={1}
          dayRadius={12}
          dayBorderColor="#ffffff"
          legends={[
            {
              anchor: "bottom-right",
              direction: "row",
              justify: false,
              itemCount: 4,
              itemWidth: 42,
              itemHeight: 36,
              itemsSpacing: 14,
              itemDirection: "right-to-left",
              translateX: -60,
              translateY: -90,
              symbolSize: 20,
            },
          ]}
          legendFormat={formatNumbers}
          tooltip={(e) => {
            // console.log(e);

            return (
              <div
                style={{
                  background: "black",
                  padding: "9px 12px",
                  border: "1px solid #ccc",
                }}
              >
                <div style={{ fontSize: "12px" }}>
                  {[e.day]}: {e.value} Answers
                </div>
              </div>
            );
          }}
        />
      </div>
    </div>
  );
}
export default DashboardView;
