import React from "react";
import "./LandingPage.css"; // Import your custom styles
import { Link } from "react-router-dom";

import flowsPhoto from "../../Static/flows.jpg";
import yesnoPhoto from "../../Static/yesno.jpg";

function LandingPage() {
  return (
    <div className="landing-page">
      <header>
        <nav>
          <ul>
            <li>
              <Link to="section1" smooth={true}>
                Section 1
              </Link>
            </li>
            <li>
              <Link to="section2" smooth={true}>
                Section 2
              </Link>
            </li>
            <li>
              <Link to="section3" smooth={true}>
                Section 3
              </Link>
            </li>
            <li>
              <Link to="section4" smooth={true}>
                Section 4
              </Link>
            </li>
          </ul>
        </nav>
      </header>

      <section id="section1" className="section">
        <div className="card">
          <img src={yesnoPhoto} alt="Screenshot 3" />
          <h2>
            Introducing WannaKnow: Unveiling a New Era of Meaningful Interaction
          </h2>
          <p>
            Yearning for a platform that bridges the gap between you and the
            people who matter most? Look no further! WannaKnow is here to
            revolutionize the way you engage with family, friends, and the
            expansive world of social media.
          </p>
        </div>
      </section>

      <section id="section2" className="section">
        <div className="card">
          <img src="screenshot2.jpg" alt="Screenshot 2" />
          <h2>Engage with Ease</h2>
          <p>
            WannaKnow makes interaction effortless. Pose captivating Yes/No
            questions, intriguing Multiple Choice queries, thought-provoking One
            Choice dilemmas, or even delve into the intriguing realm of sorting
            preferences from least to most favorite.
          </p>
        </div>
      </section>

      <section id="section3" className="section">
        <div className="card">
          <img src={flowsPhoto} alt="Screenshot 3" />
          <h2>Craft Your Flows</h2>
          <p>
            Our platform is your canvas to create dynamic question "Flows".
            Seamlessly group questions that spark curiosity and conversation.
            Whether it's a heartwarming family gathering or a lively chat with
            friends, WannaKnow helps you orchestrate the perfect interaction.
          </p>
        </div>
      </section>

      <section id="section4" className="section">
        <div className="card">
          <h2>Real-time Insights</h2>
          <p>
            WannaKnow brings your connections to life with real-time data and
            insightful statistics. Witness the impact of your Flows as colorful
            graphs reveal participation trends, answer preferences, and even
            geographical insights.
          </p>
        </div>
      </section>
    </div>
  );
}

export default LandingPage;
