// create boilerplate for ResetPasswordView:
import React, { useState } from "react";
import { Box } from "@mui/system";
import Typography from "@mui/material/Typography";
import { Snackbar, TextField } from "@mui/material";
import AuthCardContainer from "UI/AuthCardContainer";
import PurplePinkButton from "UI/PurplePinkButton";
import { passwordValidationHandler } from "Views/SignupView/SignupView";
import { useNavigate, useSearchParams } from "react-router-dom";
import { API_URL } from "Config";
import CustomCardContainer from "UI/CustomCardContainer";
// import forget password photo:
import forgetPasswordPhoto from "../../Static/pending_payment/forgot-password.png";
const ResetPasswordView = () => {
  const navigate = useNavigate();
  const [showLoginSnackbar, setShowLoginSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [resetStatusOk, setResetStatusOk] = useState(null);
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState(null);

  const handleEmailChange = (e) => {
    const email = e.target.value;
    setEmail(e.target.value);
    if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(e.target.value)) {
      setEmailError("Invalid email address");
    } else {
      setEmailError(null);
    }
  };

  const handleSubmit = async () => {
    if (emailError) {
      return;
    }

    try {
      const response = await fetch(`${API_URL}/users/reset`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email: email }),
      });

      if (response.ok) {
        setResetStatusOk(true);
        //  wait 3 seconds and then redirect to login:
      } else {
        setResetStatusOk(false);
      }
    } catch (error) {
      setResetStatusOk(false);
    }
  };

  return (
    <CustomCardContainer iconSrc={forgetPasswordPhoto} iconSize={"96px"}>
      <Box sx={{ textAlign: "center" }}>
        <Typography variant="h5" sx={{ mb: 2 }} gutterBottom>
          Forgot Password
        </Typography>

        <Box
          component="img"
          src={forgetPasswordPhoto}
          alt="WK"
          height={96}
          borderRadius="0"
          style={{ cursor: "pointer", margin: 0, marginBottom: 10 }}
        />
        <Typography variant="body1" sx={{ mb: 2 }} gutterBottom>
          Enter your email to reset your password
        </Typography>
      </Box>

      {resetStatusOk === null && (
        <form component="form" onSubmit={handleSubmit}>
          <Box
            sx={{
              display: "grid",
              gap: 2,
            }}
          >
            <TextField
              label="Email"
              type="email"
              variant="outlined"
              fullWidth
              value={email}
              onChange={handleEmailChange}
              error={emailError !== null}
              helperText={emailError}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  e.preventDefault();
                  handleSubmit();
                }
              }}
            />

            {/* helper to show password not matching : */}

            <PurplePinkButton
              fullWidth
              size="large"
              sx={{
                height: "56px",
                marginTop: "24px",
              }}
              onClick={handleSubmit}
            >
              Reset Password
            </PurplePinkButton>
          </Box>
        </form>
      )}
      {resetStatusOk === true && (
        <Box sx={{ textAlign: "center" }}>
          <Typography variant="body1" sx={{ mb: 2 }} gutterBottom>
            Reset email sent, check your email
          </Typography>
          <Typography variant="body1" sx={{ mb: 2 }} gutterBottom>
            Redirecting to login page...
          </Typography>
        </Box>
      )}
      <Snackbar
        open={resetStatusOk}
        autoHideDuration={5000}
        message={
          resetStatusOk
            ? "Reset email sent, check your email"
            : "Reset email failed, try again later"
        }
        onClose={() => {
          if (resetStatusOk) {
            navigate("/login");
          }
        }}
      />
    </CustomCardContainer>
  );
};

export default ResetPasswordView;
