import React, { useState, useEffect } from "react";
import { Modal, Box } from "@mui/material";

const PhotoViewerDialog = ({ blob, url, isOpen, handleClose }) => {
  useEffect(() => {
    if ((blob || url) && isOpen) {
      setTimeout(() => {
        const canvas = document.getElementById("preview-canvas");
        const ctx = canvas.getContext("2d");
        const image = new Image();
        // if we have url that mean the image already stored. so we can use it directly
        if (url) {
          image.src = url;
          image.alt = "preview";
        } else {
          // image is new and haven't been stored yet
          image.src = URL.createObjectURL(blob);
        }
        image.onload = () => {
          canvas.width = 400;
          canvas.height = 400;
          ctx.drawImage(image, 0, 0);
        };
      }, 100);
    }
  }, [blob, isOpen, url]);

  return (
    <Modal
      open={isOpen}
      onClose={() => {
        handleClose();
        // reset everything here:
      }}
      aria-labelledby="image-preview-modal"
      aria-describedby="image-preview-description"
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        overflowY: "scroll",
      }}
    >
      <Box sx={{ width: 400, minHeight: 500, p: 2 }}>
        <canvas id="preview-canvas" />
      </Box>
    </Modal>
  );
};

export default PhotoViewerDialog;
