import {
  Button,
  Card,
  CardContent,
  Container,
  Divider,
  Typography,
} from "@mui/material";
import { ViewTitle } from "Components/ViewTitle";
import { API_URL } from "Config";
import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
const FailureToVerify = () => {
  return (
    <Container maxWidth="xs">
      <ViewTitle>Verification</ViewTitle>
      <Card variant="outlined" style={{ borderRadius: "24px" }}>
        <CardContent>
          <Typography variant="body1">
            Failed to verify your account.Link Invalid or Expire. please contact
            support at support@wannaknow.link or try again later
          </Typography>
        </CardContent>
      </Card>
    </Container>
  );
};
const SuccessToVerify = () => {
  return (
    <Container maxWidth="xs">
      <ViewTitle>Verification</ViewTitle>
      <Card variant="outlined" style={{ borderRadius: "24px" }}>
        <CardContent>
          <Typography variant="body1">
            Successfully verified your account.
          </Typography>
          <Divider />
          <Button
            variant="text"
            // color="success"
            fullWidth
            size="large"
            type="submit"
            sx={{ height: "56px", textDecoration: "underline" }}
            href="/login"
          >
            Go to Login
          </Button>
        </CardContent>
      </Card>
    </Container>
  );
};
const VerificationComponent = () => {
  let isSuccess = false;
  useEffect(() => {
    isSuccess = handleVerify();
  }, []);

  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const verifiticationCode = searchParams.get("c");

  const handleVerify = async () => {
    try {
      const response = await fetch(
        `${API_URL}/users/verify?c=${verifiticationCode}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      console.log(response);
      if (response.ok) {
        navigate("/login");
      } else {
        return FailureToVerify();
      }
    } catch (error) {
      return FailureToVerify();
    }
  };

  if (!isSuccess) {
    return FailureToVerify();
  }
  return (
    //    Verification Status:
    <Container maxWidth="xs">
      <ViewTitle>Verification</ViewTitle>
      <Card variant="outlined" style={{ borderRadius: "24px" }}>
        <CardContent>
          <Typography variant="body1">Verifying your account...</Typography>
        </CardContent>
      </Card>
    </Container>
  );
};

export default VerificationComponent;
