import React, { useContext, useEffect, useState } from "react";
import {
  Avatar,
  Card,
  CardContent,
  Container,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
  Divider,
  makeStyles,
  Box,
  TextField,
  Snackbar,
} from "@mui/material";

import { HEADERS, getUserActivities } from "../../Utils/fetchers";
import { formatDistanceToNow } from "date-fns";
import { useNavigate } from "react-router-dom";
import { UserContext } from "Providers/UserContext";
import { API_URL } from "Config";
import { ViewTitle } from "Components/ViewTitle";
import { set } from "lodash";
import { VisuallyHiddenInput } from "Dialogs/QuestionEditDialog";
import { AddAPhoto } from "@mui/icons-material";
import { Button } from "Components/CustomHTMLComponent";

const ProfileView = () => {
  const { userProfile, setUserProfile } = useContext(UserContext);
  const [displayName, setDisplayName] = useState("");
  const [imageToUpload, setImageToUpload] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    setDisplayName(userProfile?.display_name);
  }, [userProfile]);

  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState("Saving...");
  if (userProfile === null) {
    return "Loading...";
  }
  const { display_name, username, email } = userProfile;
  const name = display_name ? display_name : username;

  const handleUserProfileUpdate = () => {
    setSnackBarMessage("Saving...");
    setShowSnackbar(true);
    const data = {
      display_name: displayName,
    };

    fetch(API_URL + "/user", {
      method: "PUT",
      headers: HEADERS(),
      body: JSON.stringify({ user: data }),
    })
      .then((res) => res.json())
      .then((data) => {
        setSnackBarMessage("Saved");
        if (data.user) {
          setUserProfile(data.user);
        }
        setTimeout(() => {
          setShowSnackbar(false);
        }, 2000);
      });
  };
  const onAttachProfilePhoto = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();

      reader.addEventListener("load", () => {
        // load reader.result as image and validate image size to atleast be 400x400:

        const img = new Image();
        img.src = reader.result;
        img.onload = () => {
          if (img.naturalWidth < 400 || img.naturalHeight < 400) {
            alert("Image must be atleast 400x400");
            return;
          }
        };

        setImageToUpload(reader.result);
      });
      reader.readAsDataURL(e.target.files[0]);
    }
  };
  return (
    <Container maxWidth="xs">
      <ViewTitle>Profile</ViewTitle>

      <Card variant="outlined" style={{ borderRadius: "24px" }}>
        <CardContent>
          <Box
            sx={{
              display: "grid",
              placeItems: "center",
              gap: 2,
              padding: "16px",
            }}
          >
            <Avatar
              alt="User Avatar"
              src={imageToUpload ? imageToUpload : userProfile?.image}
              sx={{ height: 80, width: 80 }}
            />

            <Typography variant="h5" style={{ marginLeft: "10px" }}>
              {userProfile.display_name
                ? userProfile.display_name
                : userProfile.username}
            </Typography>
            {/* add a cicurlar button with camera icon that trigger photo upload: */}
            {/* <Button component="label" variant="text">
              Change Profile Photo
              <VisuallyHiddenInput
                type="file"
                accept="image/*"
                onChange={onAttachProfilePhoto}
                onClick={(e) => {
                  e.target.value = "";
                }}
              />
            </Button> */}
            <TextField
              label="Display Name"
              variant="outlined"
              fullWidth
              value={displayName}
              onChange={(e) => {
                e.preventDefault();
                setDisplayName(e.target.value);
              }}
            />
            <TextField
              label="Email"
              disabled
              variant="outlined"
              fullWidth
              value={email}
            />
            <Typography
              variant="body1"
              sx={{
                textAlign: "center",
                color: "#6b6b6b",
                paddingTop: 1,
                marginTop: 24,
              }}
            >
              <a href="password/reset">Change password</a>
            </Typography>

            <Button
              variant="outlined"
              color="success"
              fullWidth
              size="large"
              sx={{ height: "56px" }}
              onClick={handleUserProfileUpdate}
            >
              Save
            </Button>
          </Box>
        </CardContent>
      </Card>
      <Snackbar
        open={showSnackbar}
        autoHideDuration={3000}
        onClose={() => setShowSnackbar(false)}
        message={snackBarMessage}
      />
    </Container>
  );
};

export default ProfileView;
