import { id } from "date-fns/locale";

export const QUESTION_TYPE_ENUM = {
  yes_no: "Yes/No",
  number_ranged: "Ranged(number)",
  one_of_many: "Choose one",
  many_of_many: "Multiple Choices",
  sortable: "Ranking",
  text: "Free Text",
  me_not_me: "Me / Not me",
  one_word: "One Word",
  emoji: "Emoji",
};

export const QUESTION_TYPE_MAP = {
  "Yes/No": "yes_no",
  "Ranged(number)": "number_ranged",
  "Choose one": "one_of_many",
  "Multiple Choices": "many_of_many",
  Ranking: "sortable",
  "Free Text": "text",
  "One Word": "one_word",
  Emoji: "emoji",
  "Me / Not me": "me_not_me",
};
export const QUESTION_TYPE_DESCRIPTION_MAP = {
  yes_no: "Simple yes or no question, ",
  number_ranged: "Select a number between given range",
  one_of_many: "Choose one and only one of the given options",
  many_of_many: "Choose at least one or more of the given options",
  sortable:
    "Rank the given options, drag and drop to reorder, i.e. most to least favorite ",
  text: "Free text.",
  me_not_me: "Simple me or not me question",
  one_word: "Free text input with limit to one word",
  emoji:
    "User will be able to select an emoji to answer the question. only one emoji can be selected",
};
export const BLUEPRINT_STATUS_ENUM = {
  1: "Draft",
  2: "Published",
  3: "Archived",
};
{
  /* <MenuItem value={1}>Questions Set</MenuItem>
<MenuItem value={2}>Challenge</MenuItem>
<MenuItem value={3}>Quick Question</MenuItem> */
}
export const BLUEPRINT_TYPES_MAP = {
  1: {
    id: 1,
    name: "Questions Set",
    short: "Set",
    description:
      "Create a group of questions share can be used/shared together",
  },
  2: {
    id: 2,
    name: "Challenge",
    short: "Challenge",
    description: "Create a challenge that can be shared with others",
  },
  3: {
    id: 3,
    name: "Quick Question",
    short: "Quick",
    description:
      "Create a quick question that can be shared with others(limited to one question)",
  },
};
export const QUESTION_TYPE_SUPPORT_PHOTOS = [
  "one_of_many",
  "sortable",
  "many_of_many",
];
export const GRAPH_COLORS = [
  "#845EC2",
  "#D65DB1",
  "#FF6F91",
  "#FF9671",
  "#FFC75F",
];

export const PAPER_CARD_STYLE_1 = {
  padding: "24px",
  height: "100%",
  minHeight: "200px",
  borderRadius: "36px",
  background: "#efeefc",
};
export const PAPER_CARD_STYLE_2 = {
  // padding: "10px",
  margin: "10px",
  height: "100%",
  // borderRadius: "16px",
  background: "#efeefc",
  padding: "24px 24px 24px",
  borderRadius: "24px",
};

export const PAPER_CARD_STYLE_3 = {
  padding: "8px",
  height: "100%",
  minHeight: "150x",
  borderRadius: "16px",
  background: "#fff",
};

export const PAPER_CARD_STYLE_4 = {
  // padding: "10px",
  // margin: "10px",
  // height: "100%",
  borderRadius: "10px",
  background: "#efeefc",
};

export const PAPER_CARD_STYLE_5 = {
  padding: "8px",
  height: "100%",
  minHeight: "150x",
  borderRadius: "16px",
};

export const PAPER_CARD_STYLE_6 = {
  // padding: "10px",
  margin: "10px",
  height: "100%",
  // borderRadius: "16px",
  background: "#efeefc",
  borderRadius: "24px",
  // justifyContent: "center",
  // display: "flex",
};
export const BUTTON_CARD_STYLE_1 = {
  // borderRadius: "26px",
  minHeight: "40px",
  // minWidth: "80px",
  // fontStyle: "bold",
  borderRadius: "8px",
  background: "#efeefc",
};
export const COLOR_PALLETTE_2 = {
  primaryColor: "#B139E3",
  secondaryColor: "#EF4ECE",
  blue: "#26547C",
  shineBlue: "#02A9EA",
  black: "#2F2F2F",
  // #084887
  lightGreen: "#9EE493",
  yellow: "#FFD166",
  green: "#06D6A0",
  offwhite: "#FFD166",
  white: "#FFFFFF",
  whitish: "#F8F1FF",
  red: "#EF476F",
  paletteColor1: "#DE9FF9",

  background: "#F5E3FC",
};
export const COLORS_PALETTE = {
  primaryColor: "#6a6ae0",
  secondaryColor: "#00c9a7",
  paletteColor0: "#6a6ae0",
  paletteColor1: "#d65db1",
  paletteColor2: "#ff6f91",
  paletteColor3: "#ff9671",
  paletteColor4: "#ffc75f",
  paletteColor5: "#f9f871",
  paletteColor6: "#a0e8af",
  paletteMatching1: "#2c73d2",
  paletteMatching2: "#0081cf",
  paletteMatching3: "#0089ba",
  paletteMatching4: "#008e9b",
  paletteMatching5: "#008f7a",
  paletteSpot1: "#b39cd0",
  paletteSpot2: "#fbeaff",
  paletteSpot3: "#00c9a7",
  paletteTwistSpot1: "#00c9a7",
  paletteTwistSpot2: "#c4fcef",
  paletteTwistSpot3: "#4d8076",
  paletteClassy1: "#4b4453",
  paletteClassy2: "#b0a8b9",
  paletteClassy3: "#c34a36",
  paletteClassy4: "#ff8066",
  paletteSwitch1: "#f3c5ff",
  paletteSwitch2: "#00c9a7",
  paletteSwitch3: "#fefedf",
  paletteSkipShade1: "#009efa",
  paletteSkipShade2: "#00d2fc",
  paletteSkipShade3: "#4ffbdf",
  cardLightColor: "#fefefe",
  cardLightPurple: "#efeefc",

  submitBtnColor: "#007bff",
  paletteTint1: "#8fb3ff",
  paletteTint2: "#c3c1e2",
  paletteTint3: "#f3c5ff",
  paletteTint4: "#f4cdf4",
  paletteTint5: "#bcf0bc",
  paletteTint6: "#ffffae",
  paletteTint7: "#ffd2d2",
  paletteTint8: "#b7b7e6",
  paletteTint9: "#b0b0f2",
  paletteTint10: "#b582e8",
  paletteText1: "#efeefc",
  paletteText2: "#d1d0e6",
  paletteText3: "#a7a5c6",
  paletteText4: "#6a6ae0",
  paletteText5: "#4b4453",
};

export const FONTS = [
  "Inter",
  "-apple-system",
  "BlinkMacSystemFont",
  '"Segoe UI"',
  "Roboto",
  '"Helvetica Neue"',
  "Arial",
  "sans-serif",
  '"Apple Color Emoji"',
  '"Segoe UI Emoji"',
  '"Segoe UI Symbol"',
];

export const EMOJI_ARRAY = [
  "😂",
  "❤️",
  "😍",
  "😊",
  "😭",
  "😘",
  "😁",
  "🙏",
  "😩",
  "🤔",
  "😔",
  "😅",
  "🎉",
  "👍",
  "🙌",
  "😏",
  "😒",
  "🤣",
  "😎",
  "😌",
  "🤗",
  "😬",
  "😢",
  "😆",
  "💕",
  "😑",
  "😪",
  "🤷",
  "😕",
  "😱",
  "😡",
  "😮",
  "👏",
  "🤦",
  "😤",
  "🤢",
  "🤩",
  "🤭",
  "🤤",
  "😞",
  "😋",
  "💔",
  "😖",
  "🥺",
  "😷",
  "🤪",
  "🤯",
  "🙄",
  "😴",
  "😵",
  "😰",
  "😦",
  "😶",
  "😇",
  "😿",
  "🙈",
  "🙉",
  "🙊",
  "🤴",
  "👸",
  "👶",
  "💯",
  "🔥",
  "🎶",
  "🎵",
  "🚀",
  "🌟",
  "🌈",
  "⭐",
  "🌺",
  "🌸",
  "🌼",
  "🌻",
  "🌷",
  "🌹",
  "🌱",
  "🌕",
  "🌙",
  "🌚",
  "🌝",
  "🌞",
  "⛅",
  "😃",
  "😄",
  "🙂",
  "🙃",
  "😉",
  "😛",
  "😜",
  "😝",
  "🤑",
  "🤓",
  "🥰",
  "🥳",
  "🤬",
  "🤒",
  "🤕",
  "🤮",
  "🤧",
  "🥴",
  "🥶",
  "😲",
  "👿",
  "💀",
  "☠️",
  "🙀",
  "👻",
  "👽",
  "🤖",
  "😺",
  "😸",
  "😹",
  "😻",
  "😼",
  "😽",
  "👹",
];

export const EMOJI_COUNTRY = [
  "🇦🇨",
  "🇦🇩",
  "🇦🇪",
  "🇦🇫",
  "🇦🇬",
  "🇦🇮",
  "🇦🇱",
  "🇦🇲",
  "🇦🇴",
  "🇦🇶",
  "🇦🇷",
  "🇦🇸",
  "🇦🇹",
  "🇦🇺",
  "🇦🇼",
  "🇦🇽",
  "🇦🇿",
  "🇧🇦",
  "🇧🇧",
  "🇧🇩",
  "🇧🇪",
  "🇧🇫",
  "🇧🇬",
  "🇧🇭",
  "🇧🇮",
  "🇧🇯",
  "🇧🇱",
  "🇧🇲",
  "🇧🇳",
  "🇧🇴",
  "🇧🇶",
  "🇧🇷",
  "🇧🇸",
  "🇧🇹",
  "🇧🇻",
  "🇧🇼",
  "🇧🇾",
  "🇧🇿",
  "🇨🇦",
  "🇨🇨",
  "🇨🇩",
  "🇨🇫",
  "🇨🇬",
  "🇨🇭",
  "🇨🇮",
  "🇨🇰",
  "🇨🇱",
  "🇨🇲",
  "🇨🇳",
  "🇨🇴",
  "🇨🇵",
  "🇨🇷",
  "🇨🇺",
  "🇨🇻",
  "🇨🇼",
  "🇨🇽",
  "🇨🇾",
  "🇨🇿",
  "🇩🇪",
  "🇩🇬",
  "🇩🇯",
  "🇩🇰",
  "🇩🇲",
  "🇩🇴",
  "🇩🇿",
  "🇪🇦",
  "🇪🇨",
  "🇪🇪",
  "🇪🇬",
  "🇪🇭",
  "🇪🇷",
  "🇪🇸",
  "🇪🇹",
  "🇪🇺",
  "🇫🇮",
  "🇫🇯",
  "🇫🇰",
  "🇫🇲",
  "🇫🇴",
  "🇫🇷",
  "🇬🇦",
  "🇬🇧",
  "🇬🇩",
  "🇬🇪",
  "🇬🇫",
  "🇬🇬",
  "🇬🇭",
  "🇬🇮",
  "🇬🇱",
  "🇬🇲",
  "🇬🇳",
  "🇬🇵",
  "🇬🇶",
  "🇬🇷",
  "🇬🇸",
  "🇬🇹",
  "🇬🇺",
  "🇬🇼",
  "🇬🇾",
  "🇭🇰",
  "🇭🇲",
  "🇭🇳",
  "🇭🇷",
  "🇭🇹",
  "🇭🇺",
  "🇮🇨",
  "🇮🇩",
  "🇮🇪",
  "🇮🇱",
  "🇮🇲",
  "🇮🇳",
  "🇮🇴",
  "🇮🇶",
  "🇮🇷",
  "🇮🇸",
  "🇮🇹",
  "🇯🇪",
  "🇯🇲",
  "🇯🇴",
  "🇯🇵",
  "🇰🇪",
  "🇰🇬",
  "🇰🇭",
  "🇰🇮",
  "🇰🇲",
  "🇰🇳",
  "🇰🇵",
  "🇰🇷",
  "🇰🇼",
  "🇰🇾",
  "🇰🇿",
  "🇱🇦",
  "🇱🇧",
  "🇱🇨",
  "🇱🇮",
  "🇱🇰",
  "🇱🇷",
  "🇱🇸",
  "🇱🇹",
  "🇱🇺",
  "🇱🇻",
  "🇱🇾",
  "🇲🇦",
  "🇲🇨",
  "🇲🇩",
  "🇲🇪",
  "🇲🇫",
  "🇲🇬",
  "🇲🇭",
  "🇲🇰",
  "🇲🇱",
  "🇲🇲",
  "🇲🇳",
  "🇲🇴",
  "🇲🇵",
  "🇲🇶",
  "🇲🇷",
  "🇲🇸",
  "🇲🇹",
  "🇲🇺",
  "🇲🇻",
  "🇲🇼",
  "🇲🇽",
  "🇲🇾",
  "🇲🇿",
  "🇳🇦",
  "🇳🇨",
  "🇳🇪",
  "🇳🇫",
  "🇳🇬",
  "🇳🇮",
  "🇳🇱",
  "🇳🇴",
  "🇳🇵",
  "🇳🇷",
  "🇳🇺",
  "🇳🇿",
  "🇴🇲",
  "🇵🇦",
  "🇵🇪",
  "🇵🇫",
  "🇵🇬",
  "🇵🇭",
  "🇵🇰",
  "🇵🇱",
  "🇵🇲",
  "🇵🇳",
  "🇵🇷",
  "🇵🇸",
  "🇵🇹",
  "🇵🇼",
  "🇵🇾",
  "🇶🇦",
  "🇷🇪",
  "🇷🇴",
  "🇷🇸",
  "🇷🇺",
  "🇷🇼",
  "🇸🇦",
  "🇸🇧",
  "🇸🇨",
  "🇸🇩",
  "🇸🇪",
  "🇸🇬",
  "🇸🇭",
  "🇸🇮",
  "🇸🇯",
  "🇸🇰",
  "🇸🇱",
  "🇸🇲",
  "🇸🇳",
  "🇸🇴",
  "🇸🇷",
  "🇸🇸",
  "🇸🇹",
  "🇸🇻",
  "🇸🇽",
  "🇸🇾",
  "🇸🇿",
  "🇹🇦",
  "🇹🇨",
  "🇹🇩",
  "🇹🇫",
  "🇹🇬",
  "🇹🇭",
  "🇹🇯",
  "🇹🇰",
  "🇹🇱",
  "🇹🇲",
  "🇹🇳",
  "🇹🇴",
  "🇹🇷",
  "🇹🇹",
  "🇹🇻",
  "🇹🇼",
  "🇹🇿",
  "🇺🇦",
  "🇺🇬",
  "🇺🇲",
  "🇺🇳",
  "🇺🇸",
  "🇺🇾",
  "🇺🇿",
  "🇻🇦",
  "🇻🇨",
  "🇻🇪",
  "🇻🇬",
  "🇻🇮",
  "🇻🇳",
  "🇻🇺",
  "🇼🇫",
  "🇼🇸",
  "🇽🇰",
  "🇾🇪",
  "🇾🇹",
  "🇿🇦",
  "🇿🇲",
  "🇿🇼",
];
