import { formatDistanceToNow } from "date-fns";
import { useNavigate } from "react-router-dom";
import { Box, Button, Paper } from "@mui/material";
import { COLORS_PALETTE } from "Utils/static";

export default function FeaturedFlowCard({
  description,
  shorten_url,
  created_at,
  ...props
}) {
  const navigate = useNavigate();
  const timeAgo = created_at ? formatDistanceToNow(new Date(created_at)) : "";
  return (
    <Box onClick={() => navigate("/preview/" + shorten_url)}>
      <Paper
        elevation={2}
        sx={{
          padding: "20px",
          borderRadius: 3,
          display: "grid",
          gap: 2,
          marginBottom: 2,
          backgroundColor: COLORS_PALETTE.cardLightPurple,
        }}
      >
        {/* {JSON.stringify(props)} */}
        {description}
        <Box
          sx={{
            // color: "#c4c4c4",
            color: COLORS_PALETTE.paletteClassy1,
            // fontSize: "18px",
          }}
        >
          {timeAgo}
        </Box>
        <Button style={{ background: "white" }}>View</Button>
      </Paper>
    </Box>
  );
}
