function calculateSortableAnswerStats(data, question_choices, question_images) {
  if (question_choices.length === 0 && question_images?.length > 0) {
    question_choices = question_images;
  }
  const choice_index_label = [
    "1st",
    "2nd",
    "3rd",
    "4th",
    "5th",
    "6th",
    "7th",
    "8th",
    "9th",
    "nth",
  ];

  // Initialize objects to store the count for each option as the first, second, and last choice
  const choicesStats = [];
  for (let x = 0; x < question_choices.length; x++) {
    choicesStats.push({ choice_index: choice_index_label[x] });
  }
  // Initialize a variable to store the total number of users
  let totalUsers = 0;

  // Loop through the data object and calculate the count for each choice
  for (let answer in data) {
    const answerArray = answer.split(",");
    const count = data[answer];
    totalUsers += count;

    for (let i = 0; i < answerArray.length; i++) {
      const choice = answerArray[i];
      choicesStats[i][choice] = (choicesStats[i][choice] || 0) + count;
    }
  }

  // Calculate the percentage for each choice in each choicesStats object
  for (let stats_index = 0; stats_index < choicesStats.length; stats_index++) {
    for (let option in choicesStats[stats_index]) {
      if (option === "choice_index") {
        continue;
      }
      const count = choicesStats[stats_index][option];
      const percentage = (count / totalUsers) * 100;
      choicesStats[stats_index][option] = percentage;
    }
  }

  return choicesStats.reverse();
}

export default calculateSortableAnswerStats;
