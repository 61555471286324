import React, { useEffect, useState } from "react";
import {
  Avatar,
  Card,
  CardContent,
  Container,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
  Divider,
  makeStyles,
} from "@mui/material";

import { getUserActivities } from "../../Utils/fetchers";
import { formatDistanceToNow } from "date-fns";
import { useNavigate } from "react-router-dom";
import { COLORS_PALETTE } from "Utils/static";
import { ViewTitle } from "Components/ViewTitle";
const ActivitiesView = () => {
  const [userActivities, setUserActivities] = useState([]);
  const navigate = useNavigate();
  useEffect(() => {
    fetchActivities();
  }, []);

  const fetchActivities = () => {
    getUserActivities()
      .then((response) => response.json())
      .then((data) => {
        // sort by created_at:
        data.sort((a, b) => {
          return new Date(b.created_at) - new Date(a.created_at);
        });

        setUserActivities(data);
      })
      .catch((error) => console.error(error));
  };

  if (userActivities === null) {
    return "Loading...";
  }

  return (
    <Container maxWidth="xs">
      <ViewTitle>Activity Timeline</ViewTitle>
      <Card variant="outlined" style={{ borderRadius: "24px" }}>
        <CardContent>
          {/* if no user activity show no recent activites: */}
          {!userActivities?.length > 0 && (
            // show a section saying no recent activiteis:
            <div> No recent activities</div>
          )}
          <List>
            {userActivities.map((activity) => (
              <ListItem
                key={activity.id}
                onClick={() => {
                  navigate("/results/f/" + activity.flow_shorten_url);
                }}
              >
                <ListItemAvatar>
                  <Avatar>{activity.description.charAt(0)}</Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={
                    <>
                      {activity.description} <strong>by</strong>{" "}
                      {activity.flow_owner_display_name}
                    </>
                  }
                  secondary={`${formatDistanceToNow(
                    new Date(activity.created_at)
                  )}`}
                />
              </ListItem>
            ))}
          </List>
        </CardContent>
      </Card>
    </Container>
  );
};

export default ActivitiesView;
