import React, { useEffect, useState } from "react";
import {
  Card,
  CardContent,
  TextField,
  IconButton,
  Typography,
  MenuItem,
  Modal,
  Box,
  Chip,
  Avatar,
  FormControlLabel,
  Checkbox,
  Switch,
  Grid,
  FormHelperText,
  FormControl,
} from "@mui/material";
import {
  AddCircleOutline as AddCircleOutlineIcon,
  Shuffle as ShuffleIcon,
  Delete as DeleteIcon,
  Autorenew as AutorenewIcon,
  ArrowUpward as ArrowUpwardIcon,
  ArrowDownward as ArrowDownwardIcon,
} from "@mui/icons-material";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import UploadPhotoDialog from "./UploadPhotoDialog";
import { set } from "lodash";
import {
  COLORS_PALETTE,
  QUESTION_TYPE_DESCRIPTION_MAP,
  QUESTION_TYPE_MAP,
  QUESTION_TYPE_SUPPORT_PHOTOS,
} from "../Utils/static";
import styled from "@emotion/styled";
import PhotoViewerDialog from "./PhotoViewerDialog";
import { clear } from "@testing-library/user-event/dist/clear";
import { Button } from "Components/CustomHTMLComponent";

export const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const QuestionEditDialog = ({
  isOpen,
  handleClose,
  question,
  modelMetadata,
}) => {
  const NO_CHOICES_REQUIRES = [
    "one_word",
    "emoji",
    "text",
    "me_not_me",
    "yes_no",
  ];
  const defaultQuestionMap = {
    yes_no: ["Yes", "No"],
    number_ranged: ["0", "100"],
    one_of_many: ["", ""],
    many_of_many: ["", ""],
    sortable: ["", "", ""],
    text: [],
    one_word: [],
    emoji: [],
    me_not_me: ["Me", "Not me"],
  };

  const [questionText, setQuestionText] = useState("");
  const [questionType, setQuestionType] = useState("");
  const [questionImages, setQuestionImages] = useState([]);
  const [modalQuestion, setModalQuestion] = useState(null); // {text: "", type: "", metadata: {choices: []}
  const [choices, setChoices] = useState(["", ""]);
  const [errors, setErrors] = useState({});
  const [isQuestionValid, setIsQuestionValid] = useState(false);
  const [isUploadDialogOpen, setIsUploadDialogOpen] = useState(false);
  const [imageToUpload, setImageToUpload] = useState(null);
  const [isImageToPreviewOpen, setIsImageToPreviewOpen] = useState(false);
  const [imageURLToPreview, setImageURLToPreview] = useState(null);
  const [imageToPreview, setImageToPreview] = useState(null);

  const [usePhotoAsChoices, setUsePhotoAsChoices] = useState(false);

  // setModalQuestion(question);
  useEffect(() => {
    if (question) {
      setQuestionText(question.text);
      setQuestionType(question.type);
      setChoices(question.metadata.choices);

      setQuestionImages(
        question.metadata.images ? question.metadata.images : []
      );
      // if the question have photos and the type is one of many or sortable, set usePhotoAsChoices to true because it's already have photos
      if (
        (QUESTION_TYPE_SUPPORT_PHOTOS.includes(question.type) &&
          question.metadata.images?.length > 0) ||
        modelMetadata?.usePhotosforChoices
      ) {
        setUsePhotoAsChoices(true);
      }
    }
  }, [question, modelMetadata]);

  const handleUploadDialog = (data) => {
    if (data !== null) {
      // add image to questionImages
      setQuestionImages([...questionImages, { blob: data.image }]);
    }
    setIsUploadDialogOpen(false);
    setImageToUpload(null);
  };

  const onAttachPhotoToQuestion = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();

      reader.addEventListener("load", () => {
        // load reader.result as image and validate image size to atleast be 400x400:

        const img = new Image();
        img.src = reader.result;
        img.onload = () => {
          if (img.naturalWidth < 400 || img.naturalHeight < 400) {
            alert("Image must be atleast 400x400");
            return;
          }
        };

        // console.log(reader.result); // Check if this logs the base64 data of the image
        setImageToUpload(reader.result);
        setIsUploadDialogOpen(true);
      });
      reader.readAsDataURL(e.target.files[0]);
    }
  };
  const handleDeletePhoto = (index) => {
    const newQuestionImages = [...questionImages];
    newQuestionImages.splice(index, 1);
    setQuestionImages(newQuestionImages);
  };
  const handleQuestionTypeChange = (e) => {
    setQuestionType(e.target.value);
    //if question type is sortable or one of many, don't change the choices the user already have
    if (
      (questionType === "sortable" ||
        questionType === "one_of_many" ||
        questionType === "many_of_many") &&
      (e.target.value === "sortable" ||
        e.target.value === "one_of_many || e.target.value === many_of_many")
    ) {
      setQuestionType(e.target.value);
    } else {
      setChoices(defaultQuestionMap[e.target.value]);
      setQuestionType(e.target.value);
    }
    if (
      QUESTION_TYPE_SUPPORT_PHOTOS.includes(e.target.value)
      // &&
      // question.metadata.images?.length > 0
    ) {
      setUsePhotoAsChoices(true);
    } else {
      setUsePhotoAsChoices(false);
    }
  };

  const handleChoiceChange = (index, value) => {
    const newChoices = [...choices];
    newChoices[index] = value;
    setChoices(newChoices);
  };

  const handleDeleteChoice = (index) => {
    const newChoices = [...choices];
    newChoices.splice(index, 1);
    setChoices(newChoices);
  };

  const handleMoveChoiceUp = (index) => {
    if (index > 0) {
      const newChoices = [...choices];
      [newChoices[index - 1], newChoices[index]] = [
        newChoices[index],
        newChoices[index - 1],
      ];
      setChoices(newChoices);
    }
  };

  const handleMoveChoiceDown = (index) => {
    if (index < choices.length - 1) {
      const newChoices = [...choices];
      [newChoices[index], newChoices[index + 1]] = [
        newChoices[index + 1],
        newChoices[index],
      ];
      setChoices(newChoices);
    }
  };

  const handleAddChoice = () => {
    if (questionType === "yes_no" || questionType === "number_ranged") {
      if (choices.length < 2) {
        setChoices([...choices, ""]);
      }
    } else if (
      questionType === "sortable" ||
      questionType === "one_of_many" ||
      questionType === "many_of_many"
    ) {
      if (choices.length < 7) {
        setChoices([...choices, ""]);
      }
    }
  };
  const handleShuffleChoices = () => {
    const newChoices = [...choices];
    for (let i = newChoices.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [newChoices[i], newChoices[j]] = [newChoices[j], newChoices[i]];
    }
    setChoices(newChoices);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    let newChoices = [];

    choices.forEach((choice, index) => {
      if (choice.length > 0) {
        newChoices.push(choice);
      }
    });

    setChoices(newChoices);

    const newErrors = {};
    if (!questionText) {
      newErrors.questionText = "Question Text is required";
    }
    if (!questionType) {
      newErrors.questionType = "Question type is required";
    }
    switch (usePhotoAsChoices) {
      case false:
        if (
          newChoices.length < 2 &&
          !NO_CHOICES_REQUIRES.includes(questionType)
        ) {
          newErrors.choices =
            "You need at least two choices for this question type";
        }
        // we need to check if we have duplicated choices:
        const uniqueChoices = [...new Set(newChoices)];
        if (uniqueChoices.length !== newChoices.length) {
          newErrors.choices = "You cannot have duplicated choices";
        }

        if (questionType === "sortable") {
          // addional validation for sortable questions
          for (let i = 0; i < newChoices.length; i++) {
            if (newChoices[i].length > 15) {
              newErrors.choices =
                "Choice cannot be longer than 15 characters when question type is sortable";
              break;
            }
          }
        } else if (questionType === "number_ranged") {
          // check first choice is smaller than second choice:
          if (Number(newChoices[0]) >= Number(newChoices[1])) {
            newErrors.choices = "Minimum must be smaller than second Maximum";
          }
        }
        break;
      case true:
        if (
          questionImages.length < 2 &&
          QUESTION_TYPE_SUPPORT_PHOTOS.includes(questionType)
        ) {
          newErrors.choices =
            "You need at least two photos for this question type";
        }
        // unset choices since user is using photos as choices
        newChoices = [];
        break;
      default:
        break;
    }
    setErrors(newErrors);
    if (Object.keys(newErrors).length === 0) {
      // form is valid
      // setIsQuestionValid(true);
      var questionTypeNormalized = questionType;
      //normalize question type
      if (questionType === "me_not_me") {
        setQuestionType("yes_no");
        questionTypeNormalized = "yes_no";
      }
      const questionBlueprint = {
        ...question,
        text: questionText,
        type: questionTypeNormalized,
        metadata: {
          choices: newChoices,
          images: questionImages,
        },
      };

      //TODO compare with the original question and see if it's changed

      setModalQuestion(questionBlueprint);
      handleClose(questionBlueprint);
    }

    // Submit form or perform further actions
  };
  if (!isOpen) {
    return null;
  }

  return (
    <Modal
      open={isOpen}
      onClose={() => {
        handleClose(null);
      }}
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        overflowY: "scroll",
      }}
    >
      <Box sx={{ width: 400, p: 2 }}>
        <Card style={{ borderRadius: 24 }}>
          <CardContent>
            <Typography variant="h6">Edit Question</Typography>
            <form onSubmit={handleSubmit}>
              <TextField
                label="Question Text"
                variant="outlined"
                fullWidth
                value={questionText}
                onChange={(e) => setQuestionText(e.target.value)}
                error={!!errors.questionText}
                helperText={errors.questionText}
                margin="normal"
              />
              <FormControl
                fullWidth
                variant="outlined"
                style={{ marginTop: "1rem" }}
              >
                <TextField
                  select
                  label="Question Type"
                  variant="outlined"
                  fullWidth
                  value={questionType}
                  onChange={(e) => handleQuestionTypeChange(e)}
                  error={!!errors.questionType}
                  helperText={errors.questionType}
                  margin="normal"
                >
                  {Object.keys(QUESTION_TYPE_MAP).map((label) => (
                    <MenuItem key={label} value={QUESTION_TYPE_MAP[label]}>
                      {label}
                    </MenuItem>
                  ))}
                </TextField>
                <FormHelperText>
                  {QUESTION_TYPE_DESCRIPTION_MAP[questionType]}
                </FormHelperText>
              </FormControl>
              {/* <Button
                component="label"
                variant="contained"
                startIcon={<AddPhotoAlternateIcon />}
                style={{ marginBottom: "8px" }}
                disabled={questionImages.length >= 4}
              >
                Add Photo
                <VisuallyHiddenInput
                  type="file"
                  accept="image/*"
                  onChange={onAttachPhotoToQuestion}
                  onClick={(e) => {
                    //hack to allow selected same file again
                    e.target.value = "";
                  }}
                />
              </Button> */}

              {QUESTION_TYPE_SUPPORT_PHOTOS.includes(questionType) && (
                <FormControlLabel
                  label="Image Choices"
                  control={
                    <Switch
                      // checked={checked[0] && checked[1]}
                      // indeterminate={checked[0] !== checked[1]}
                      checked={usePhotoAsChoices}
                      onChange={(e) => {
                        if (e.target.checked) {
                          setUsePhotoAsChoices(true);
                        } else {
                          setUsePhotoAsChoices(false);
                        }
                        if (errors?.choices) {
                          setErrors({ ...errors, choices: null });
                        }
                      }}
                    />
                  }
                />
              )}
              {!NO_CHOICES_REQUIRES.includes(questionType) && (
                <div id="choices-container">
                  <Typography variant="subtitle1">Choices:</Typography>
                  {/* show some summary of the error : */}
                  {errors.choices && (
                    <Typography variant="body2" color="error">
                      {errors.choices}
                    </Typography>
                  )}
                  {!usePhotoAsChoices && (
                    <div>
                      {choices.map((choice, index) => (
                        <div
                          key={index}
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          {questionType === "number_ranged" && (
                            <>
                              <TextField
                                label={index === 0 ? "Minimum" : "Maximum"}
                                variant="outlined"
                                fullWidth
                                value={choice}
                                onChange={(e) =>
                                  handleChoiceChange(index, e.target.value)
                                }
                                // error={!!errors.choices}
                                helperText={
                                  choice.length > 80 &&
                                  `${
                                    120 - choice.length
                                  } characters left. (Choice cannot be longer than 120 characters)`
                                }
                                inputProps={{ maxLength: 5, type: "number" }}
                                margin="normal"
                              />
                            </>
                          )}
                          {questionType !== "number_ranged" &&
                            questionType !== "sortable" && (
                              <TextField
                                label={`Choice ${index + 1}`}
                                variant="outlined"
                                fullWidth
                                value={choice}
                                onChange={(e) =>
                                  handleChoiceChange(index, e.target.value)
                                }
                                // error={!!errors.choices}
                                helperText={
                                  choice.length > 80 &&
                                  `${
                                    120 - choice.length
                                  } characters left. (Choice cannot be longer than 120 characters)`
                                }
                                inputProps={{ maxLength: 120 }}
                                margin="normal"
                              />
                            )}
                          {questionType !== "number_ranged" &&
                            questionType === "sortable" && (
                              <TextField
                                label={`Choice ${index + 1}`}
                                variant="outlined"
                                fullWidth
                                value={choice}
                                onChange={(e) =>
                                  handleChoiceChange(index, e.target.value)
                                }
                                // error={!!errors.choices}
                                helperText={
                                  choice.length > 10 &&
                                  `${
                                    15 - choice.length
                                  } characters left. (Choice cannot be longer than 15 characters)`
                                }
                                inputProps={{ maxLength: 15 }}
                                margin="normal"
                              />
                            )}
                          {questionType !== "number_ranged" && (
                            <>
                              <IconButton
                                onClick={() => handleDeleteChoice(index)}
                              >
                                <DeleteIcon />
                              </IconButton>
                              <IconButton
                                onClick={() => handleMoveChoiceUp(index)}
                              >
                                <ArrowUpwardIcon />
                              </IconButton>
                              <IconButton
                                onClick={() => handleMoveChoiceDown(index)}
                              >
                                <ArrowDownwardIcon />
                              </IconButton>
                            </>
                          )}
                        </div>
                      ))}
                    </div>
                  )}
                  {!usePhotoAsChoices && (
                    <div>
                      <IconButton
                        onClick={handleAddChoice}
                        disabled={NO_CHOICES_REQUIRES.includes(questionType)}
                      >
                        <AddCircleOutlineIcon />
                      </IconButton>
                      <IconButton
                        onClick={handleShuffleChoices}
                        disabled={NO_CHOICES_REQUIRES.includes(questionType)}
                        style={{ marginLeft: "5px" }}
                      >
                        <ShuffleIcon />
                      </IconButton>
                    </div>
                  )}
                  {usePhotoAsChoices === true && (
                    //Grid 2*2 that have photo place holder, each photo represent with suare and have a x button to remove it:
                    <Grid
                      container
                      spacing={0}
                      style={{
                        display: "flex",
                        flexWrap: "wrap",
                        justifyContent: "space-between",
                      }}
                    >
                      {[...questionImages].map((image, index) => (
                        <Grid
                          item
                          xs={6}
                          key={index}
                          style={{
                            width: "100%",
                            aspectRatio: "1/1",
                            borderRadius: "14px",
                            // backgroundColor: "#f5f5f5",
                            position: "relative",
                            marginBottom: 0,

                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            padding: 4,
                          }}
                        >
                          <IconButton
                            onClick={() => {
                              handleDeletePhoto(index);
                            }}
                            style={{
                              position: "absolute",
                              top: 2,
                              right: 2,
                              backgroundColor: "rgba(255,255,255,0.7)",
                            }}
                          >
                            {image.url && <DeleteIcon />}
                            {!image.url && <AutorenewIcon />}
                          </IconButton>
                          <img
                            src={
                              image.url
                                ? image.url
                                : URL.createObjectURL(image.blob)
                            }
                            alt="attachement"
                            style={{
                              width: "100%",
                              height: "100%",
                              // objectFit: "cover",
                              borderRadius: "14px",
                              marginBottom: 0,
                            }}
                            onClick={() => {
                              if (image.image) {
                                setImageToPreview(image.image);
                              } else {
                                setImageURLToPreview(image.url);
                              }
                              setIsImageToPreviewOpen(true);
                            }}
                          />
                        </Grid>
                      ))}
                      {/* create array of 4- len of questionImages.length */}

                      {Array.from({ length: 4 - questionImages.length }).map(
                        (_, index) => (
                          <Grid
                            // onClick={() => {
                            //   onAttachPhotoToQuestion();
                            // }}
                            key={index}
                            item
                            xs={6}
                            style={{ padding: 0 }}
                          >
                            <div
                              style={{
                                width: "100%",
                                aspectRatio: "1/1",
                                borderRadius: "14px",
                                // backgroundColor: "#f5f5f5",
                                position: "relative",
                                marginBottom: 0,

                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                padding: 4,
                              }}
                            >
                              <Button
                                component="label"
                                variant="contained"
                                startIcon={<AddPhotoAlternateIcon />}
                                style={{
                                  // remove all the style and keep the icon only:
                                  background: COLORS_PALETTE.cardLightPurple,
                                  color: COLORS_PALETTE.primaryColor,
                                  boxShadow: "none",
                                  borderRadius: "inherit",
                                  width: "100%",
                                  aspectRatio: "1/1",
                                  padding: 0,
                                }}
                              >
                                <VisuallyHiddenInput
                                  type="file"
                                  accept="image/*"
                                  onChange={onAttachPhotoToQuestion}
                                  onClick={(e) => {
                                    //hack to allow selected same file again
                                    e.target.value = "";
                                  }}
                                />
                                Add
                              </Button>
                            </div>
                          </Grid>
                        )
                      )}
                    </Grid>
                  )}
                </div>
              )}
              <div
                style={{
                  marginTop: "1rem",
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <Button
                  type="reset"
                  variant="outlined"
                  color="error"
                  onClick={() => {
                    handleClose(null);
                    isOpen = false;
                    setErrors({});
                    // Reset form values here
                  }}
                  style={{ marginRight: "0.5rem" }}
                  pill
                >
                  Discard
                </Button>
                <Button type="submit" variant="contained" color="primary" pill>
                  Save
                </Button>
              </div>
            </form>
          </CardContent>
        </Card>
        {imageToUpload && (
          // TODO : FIX I have this hack to force the dialog to re-render and show the preview of the image
          // without it it will only crop properly when initial load but if you try to reupload image it wont select
          // any crop area until user click
          <UploadPhotoDialog
            isOpen={isUploadDialogOpen}
            handleClose={handleUploadDialog}
            imageToUpload={imageToUpload}
          />
        )}
        <PhotoViewerDialog
          blob={imageToPreview}
          url={imageURLToPreview}
          isOpen={isImageToPreviewOpen}
          handleClose={() => {
            setIsImageToPreviewOpen(false);
          }}
        />
      </Box>
    </Modal>
  );
};

export default QuestionEditDialog;
